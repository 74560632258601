import React from 'react'
import Dashboard from '../features/home'
import ImprovementsListLanes from '../features/board/improvementsListLanes'


console.log("ImprovementsPage.jsx");

export default function ImprovementsListLanesPage() {
  return (
    <ImprovementsListLanes/>
  )
}
