import axios from "axios";

export const deleteLink = async (linkId) => {
    const response = await axios.delete(`api/tasklinks/${linkId}`);
    const result = response.data;
    return result;
}

export const postLink = async (data) => {
    const response = await axios.post("api/tasklinks", data);
    const result = response.data;
    return result;
}