import React from 'react'
import IncidentsCalendar from '../features/incidents/incidentsCalendar'


console.log("IncidentsCalendarPage.jsx");

export default function IncidentsCalendarPage() {
  return (
    <IncidentsCalendar/>
  )
}
