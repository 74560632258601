import React, { useState } from "react";
import { useTheme, Box, Stack, Tooltip, Checkbox, CircularProgress, Avatar, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { tokens } from "../../assets/theme";

import { getUsers, syncUsers, hideUser } from "../../fetchers/users";
import { getPersons } from "../../fetchers/person";
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Controls from "../../components/controls/Contols";
import { pushErrorMessage, pushNotification } from "../../utils/notifications";
import AxionConfirmation from '../../components/controls/AxionConfirmation';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
//Language
import messages from "./messages";
import { FormattedMessage, useIntl } from "react-intl";


export default function ShowUsers(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { formatMessage } = useIntl();

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [userToHide, setUserToHide] = useState(null);
    const [openProgress, setOpenProgress] = useState(false);

    const personQuery = useQuery(
        ["persons"],
        () => getPersons(),
        {}
    );



    // const getImage = () => {
    //     return axios.get(`api/users/${id}/image`, {
    //         responseType: "arraybuffer"
    //     })
    //         .then((res) => {
    //             const base64 = btoa(
    //                 new Uint8Array(res.data).reduce(
    //                     (data, byte) => data + String.fromCharCode(byte),
    //                     ''
    //                 )
    //             )
    //             //return base64;
    //             setImage(base64)
    //         })
    // }

    const handleMarkUser = (id) => {
        hideUser(id).then(() => {
            personQuery.refetch();
        }).catch((error) => {
            console.log(error);
            pushErrorMessage(formatMessage(messages.could_not_hide_user));

        });
    }

    const doSync = () => {
        setOpenConfirmation(false);
        setOpenProgress(true);
        syncUsers().then(() => {
            personQuery.refetch();
            console.log(formatMessage(messages.users_are_synchronized));
            setOpenProgress(false);
            pushNotification(formatMessage(messages.users_are_synchronized));
        });

    }

    const cancelSync = () => {
        setOpenConfirmation(false);
    }

    function getImgSrc(imageBase64) {
        //var result = "../../../assets/dot_transparent.png";
        var result = "";
        if (imageBase64 !== null && imageBase64 !== undefined && imageBase64 !== "") {
            result = `data:;base64,${imageBase64}`
        }
        return result;
    }

    function sortByFirstName(objects) {
        if (objects === undefined) {
            return;
        }
        return objects.sort((a, b) => a.firstName.localeCompare(b.firstName));
    }

    function getTooltipText(id, aadId) {
        return "Id:" + id + ", AAD Id: " + aadId;
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name, boxSize) {
        if (name === undefined || name === null || name === "" || name.split(' ').length < 2) {
            return ["N", "N"];
        }
        return {
            sx: {
                bgcolor: stringToColor(name),
                border: 1,
                borderColor: '#000000',
                height: boxSize,
                width: boxSize,
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    let data = sortByFirstName(personQuery.data);

    let spinner = <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            //height: "100vh",
        }}
    >
        <CircularProgress color="secondary" />
    </div>

    if (personQuery.isLoading) {
        return spinner
    }

    return (
        <Box marginLeft="15px" marginTop="30px">
            <Box>
                <Stack direction="row" spacing="auto" width="100%" marginBottom="5px" alignItems="center">
                    <Box width="5%"><FormattedMessage {...messages.hide} /></Box>
                    <Box width="10%"><FormattedMessage {...messages.image} /></Box>
                    <Box width="15%"><FormattedMessage {...messages.display_name} /></Box>
                    {/* <Box width="15%">Family name</Box> */}
                    <Box width="25%"><FormattedMessage {...messages.email} /></Box>
                    <Box width="30%"><FormattedMessage {...messages.info} /></Box>
                    {/* <Box>{p.officeLocation || "missing"}</Box> */}
                </Stack>
                <Box width="100%" backgroundColor={colors.primary[400]} height="1px"></Box>
                {data?.filter(f => f.hide !== true).map((p) => (
                    <>
                        <Stack direction="row" spacing="auto" width="100%" padding="3px" alignItems="center">
                            <Box width="5%"><Box sx={{ backgroundColor: colors.primary[400], width: '37px' }}><Checkbox onClick={() => { handleMarkUser(p.id) }} checked={p.hide} /></Box></Box>
                            <Box width="10%"><Avatar alt="value" src={getImgSrc(p.imageBase64)} {...stringAvatar(p.firstName, 40)}></Avatar></Box>
                            {/* <Box width="10%"><img src={getImgSrc(p.imageBase64)} alt="" width="40px" height="40px" /></Box> */}
                            <Box width="15%">{p.firstName}</Box>
                            {/* <Box width="15%">{p.lastName}</Box> */}
                            <Box width="25%">{p.email}</Box>
                            <Box width="30%">
                                <Tooltip title={<Typography fontSize="12px">{getTooltipText(p.id, p.aadId)}</Typography>} placement="right" arrow >
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            </Box>
                            {/* <Box>{p.officeLocation || "missing"}</Box> */}
                        </Stack>
                        <Box width="100%" backgroundColor={colors.primary[400]} height="1px"></Box>
                    </>
                ))}

                <Box width="100%" backgroundColor={colors.primary[400]} padding="5px" paddingLeft="13px" marginTop="20px"><FormattedMessage {...messages.hidden_users} /></Box>
                {data?.filter(f => f.hide === true).map((p) => (
                    <>
                        <Stack direction="row" spacing="auto" width="100%" padding="3px" alignItems="center">
                            <Box width="5%"><Box sx={{ backgroundColor: colors.primary[400], width: '37px' }}><Checkbox onClick={() => { handleMarkUser(p.id) }} checked={p.hide} /></Box></Box>
                            <Box width="10%">
                                <Avatar alt="value" src={getImgSrc(p.imageBase64)} {...stringAvatar(p.firstName, 40)} />
                            </Box>
                            <Box width="15%">{p.firstName}</Box>
                            {/* <Box width="15%">{p.lastName}</Box> */}
                            <Box width="25%">{p.email}</Box>
                            <Box width="30%">
                                <Tooltip title={getTooltipText(p.id, p.aadId)} placement="right" arrow>
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            </Box>
                            {/* <Box>{p.officeLocation || "missing"}</Box> */}
                        </Stack>
                        <Box width="100%" backgroundColor={colors.primary[400]} height="1px"></Box>
                    </>
                ))}
            </Box>

            <Box marginTop="20px">
                <Controls.Button
                    text={formatMessage(messages.sync_users_from_aad)}
                    onClick={() => { setOpenConfirmation(true) }}
                    startIcon={<SyncOutlinedIcon />}
                    sx={{
                        "&:hover": {
                            backgroundColor: colors.dashbordGreen[700],
                        },
                        backgroundColor: colors.dashbordGreen[500],
                        color: "#000000",
                    }}
                />
            </Box>
            <AxionConfirmation open={openConfirmation} title={formatMessage(messages.sync_users)} message={formatMessage(messages.sync_users_from_aad)} onConfirm={doSync} onCancel={cancelSync} cancelText={formatMessage(messages.no)} confirmText={formatMessage(messages.yes)} />
            <Dialog open={openProgress}>
                <Box m={0} sx={{ backgroundColor: colors.primary[400], borderRadius: "4px" }}>
                    <DialogTitle><FormattedMessage {...messages.syncing_users} /></DialogTitle>
                    <DialogContent>
                        {spinner}
                    </DialogContent>
                </Box>
            </Dialog>
        </Box>
    )
}