import React, { useState, useEffect } from "react";
import { Box, TextField,useTheme } from '@mui/material';
// import { tokens } from "../../../assets/theme";
import Stack from '@mui/material/Stack'
import dayjs from "dayjs";
import 'dayjs/locale/sv';
import 'dayjs/locale/en';
import { DatePicker} from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';




//const GridDatePicker = ({ locales, label, maxDate , minDate, onChange, date }) => {
const GridDatePicker = (props) => {
    //const theme = useTheme();
    //const colors = tokens(theme.palette.mode);
    const [locale, setLocale] = useState(props.locales); //"sv"
    //const [datePickerValue, setDatePickerValue] = React.useState(dayjs());
   function onChangeHandler(date) {
        props.onChange(date);
    }
    return (
        <Box         sx={{
            "& .MuiInputBase-input": {
              //backgroundColor: "#fff",
              color: "secondary",
              height: "0.4375em",
              marginTo:"5px",
              
            },
            "& .MuiTextField-root": {
                marginTop:"10px",
                marginBottom:"10px",
            },
            "& .MuiPaper-root": {
               backgroundColor: "#fff"
            },
        }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
        <DatePicker
        label={props.label}
        value={props.date}
        //onChange={(newValue) => setDatePickerValue(newValue)}
        renderInput={(params) => <TextField {...params} />}
        maxDate= {dayjs(props.maxDate)}
        minDate= {dayjs(props.minDate)}
        onChange={onChangeHandler}
        //sx= {{backgroundColor: "#FFF"}}
        

        
      />
      </LocalizationProvider>
      </Box>

    )

}
export default GridDatePicker;