import { defineMessages } from 'react-intl';

export const scope = 'axionimprovements.features.new';

export default defineMessages({

    title_is_required: {
        id: `${scope}.labels.title_is_required`,
        defaultMessage: 'Title is required',
    },
    description_is_required: {
        id: `${scope}.labels.description_is_required`,
        defaultMessage: 'Description is required',
    },
    department_is_required: {
        id: `${scope}.labels.department_is_required`,
        defaultMessage: 'Department is required',
    },
    type_is_required: {
        id: `${scope}.labels.type_is_required`,
        defaultMessage: 'Type is required',
    },
    responsible_is_required: {
        id: `${scope}.labels.responsible_is_required`,
        defaultMessage: 'Responsible is required',
    },
    category_is_required: {
        id: `${scope}.labels.category_is_required`,
        defaultMessage: 'Category is required',
    },
    could_not_create_the_task: {
        id: `${scope}.labels.could_not_create_the_task`,
        defaultMessage: 'Could not create the task',
    },
    could_not_update_the_task: {
        id: `${scope}.labels.could_not_update_the_task`,
        defaultMessage: 'Could not update the task',
    },
    could_not_create_the_tag: {
        id: `${scope}.labels.could_not_create_the_tag`,
        defaultMessage: 'Could not create the tag',
    },
    delete_task: {
        id: `${scope}.labels.delete_task`,
        defaultMessage: 'Delete task',
    },
    sure_delete_task: {
        id: `${scope}.labels.sure_delete_task`,
        defaultMessage: 'Are you sure you want to delete this task?',
    },
    could_not_delete_the_task: {
        id: `${scope}.labels.could_not_delete_the_task`,
        defaultMessage: 'Could not delete the task',
    },
    could_not_create_the_comment : {
        id: `${scope}.labels.could_not_create_the_comment`,
        defaultMessage: 'Could not create the comment',
    },
    this_is_a_sub_improvement_to : {
        id: `${scope}.labels.this_is_a_sub_improvement_to`,
        defaultMessage: 'This is a sub improvement to',
    },
    this_improvement_is_created_from : {
        id: `${scope}.labels.this_improvement_is_created_from`,
        defaultMessage: 'This improvement is created from incident',
    },
    title : {
        id: `${scope}.labels.title`,
        defaultMessage: 'Title',
    },
    description : {
        id: `${scope}.labels.description`,
        defaultMessage: 'Description',
    },
    savings_value_sek : {
        id: `${scope}.labels.savings_value_sek`,
        defaultMessage: 'Savings value SEK',
    },
    savings_time : {
        id: `${scope}.labels.savings_time`,
        defaultMessage: 'Savings time',
    },
    estimated_effort : {
        id: `${scope}.labels.estimated_effort`,
        defaultMessage: 'Estimated effort (h)',
    },
    actual_effort : {
        id: `${scope}.labels.actual_effort`,
        defaultMessage: 'Actual effort (h)',
    },
    report_time : {
        id: `${scope}.labels.report_time`,
        defaultMessage: 'Report time',
    },
    incident_date : {
        id: `${scope}.labels.incident_date`,
        defaultMessage: 'Incident date',
    },
    target_date : {
        id: `${scope}.labels.target_date`,
        defaultMessage: 'Target date',
    },
    start_date : {
        id: `${scope}.labels.start_date`,
        defaultMessage: 'Start date',
    },
    end_date : {
        id: `${scope}.labels.end_date`,
        defaultMessage: 'End date',
    },
    comment : {
        id: `${scope}.labels.comment`,
        defaultMessage: 'Comment',
    },
    add_comment : {
        id: `${scope}.labels.add_comment`,
        defaultMessage: 'Add comment',
    },
    type : {
        id: `${scope}.labels.type`,
        defaultMessage: 'Type',
    },
    category : {
        id: `${scope}.labels.category`,
        defaultMessage: 'Incident category',
    },
    quickfix : {
        id: `${scope}.labels.quickfix`,
        defaultMessage: 'Quickfix',
    },
    responsible : {
        id: `${scope}.labels.responsible`,
        defaultMessage: 'Responsible',
    },
    department : {
        id: `${scope}.labels.department`,
        defaultMessage: 'Department',
    },
    participants : {
        id: `${scope}.labels.participants`,
        defaultMessage: 'Participants',
    },
    select_participants : {
        id: `${scope}.labels.select_participants`,
        defaultMessage: 'Select participants',
    },
    tags : {
        id: `${scope}.labels.tags`,
        defaultMessage: 'Tags',
    },
    add_a_new_tag : {
        id: `${scope}.labels.add_a_new_tag`,
        defaultMessage: 'Add a new tag',
    },
    tag_title : {
        id: `${scope}.labels.tag_title`,
        defaultMessage: 'Tag title',
    },
    cancel_x : {
        id: `${scope}.labels.cancel_x`,
        defaultMessage: 'Cancel',
    },
    add_tag : {
        id: `${scope}.labels.add_tag`,
        defaultMessage: 'Add tag',
    },
    dimension : {
        id: `${scope}.labels.dimension`,
        defaultMessage: 'Dimension',
    },
    subtasks : {
        id: `${scope}.labels.subtasks`,
        defaultMessage: 'Subtasks',
    },
    incidentLink : {
        id: `${scope}.labels.incidentLink`,
        defaultMessage: 'Linked incident',
    },
    improvementLink : {
        id: `${scope}.labels.improvementLink`,
        defaultMessage: 'Linked improvement',
    },
    attachments : {
        id: `${scope}.labels.attachments`,
        defaultMessage: 'Attachments',
    },
    add_delete_attachments : {
        id: `${scope}.labels.add_delete_attachments`,
        defaultMessage: 'Add / Delete attachments',
    },
    close : {
        id: `${scope}.labels.close`,
        defaultMessage: 'Close',
    },
    links : {
        id: `${scope}.labels.links`,
        defaultMessage: 'Links',
    },
    add_delete_links : {
        id: `${scope}.labels.add_delete_links`,
        defaultMessage: 'Add / Delete links',
    },
    save : {
        id: `${scope}.labels.save`,
        defaultMessage: 'Save',
    },
    delete : {
        id: `${scope}.labels.delete`,
        defaultMessage: 'Delete',
    },

    link_description : {
        id: `${scope}.labels.link_description`,
        defaultMessage: 'Link description',
    },
    link_url : {
        id: `${scope}.labels.link_url`,
        defaultMessage: 'Link url',
    },
    add_link : {
        id: `${scope}.labels.add_link`,
        defaultMessage: 'Add link',
    },
    current_links : {
        id: `${scope}.labels.current_links`,
        defaultMessage: 'Current links',
    },
    delete_link : {
        id: `${scope}.labels.delete_link`,
        defaultMessage: 'Delete link',
    },
    add_links : {
        id: `${scope}.labels.add_links`,
        defaultMessage: 'Add links',
    },
    add_attachments : {
        id: `${scope}.labels.add_attachments`,
        defaultMessage: 'Add attachments',
    },
    add_subtasks : {
        id: `${scope}.labels.add_subtasks`,
        defaultMessage: 'Add subtasks',
    },
    add_linked_improvement : {
        id: `${scope}.labels.add_linked_improvement`,
        defaultMessage: 'Add linked improvement',
    },

    reported_time : {
        id: `${scope}.labels.reported_time`,
        defaultMessage: 'Reported time',
    },
    could_not_report_time : {
        id: `${scope}.labels.could_not_report_time`,
        defaultMessage: 'Could not report time',
    },









});