import axios from "axios";

// export const deleteReportedTime= async (reportedTimeId) => {
//     const response = await axios.delete(`api/taskreportedimes/${reportedTimeId}`);
//     const result = response.data;
//     return result;
// }

export const postReportedTime = async (data) => {
    const response = await axios.post("api/taskreportedtimes", data);
    const result = response.data;
    return result;
}

export const getAllReportedTime = async () => {
    const response = await axios.get("api/taskreportedtimes/");
    const result = response.data;
    return result;
}