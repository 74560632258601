import { defineMessages } from 'react-intl';

export const scope = 'axionimprovements.features.list';

export default defineMessages({

    all: {
        id: `${scope}.filter.labels.all`,
        defaultMessage: 'All',
    },
    department: {
        id: `${scope}.filter.labels.department`,
        defaultMessage: 'Department',
    },
    type: {
        id: `${scope}.filter.labels.type`,
        defaultMessage: 'Type',
    },
    quick_search: {
        id: `${scope}.filter.labels.quick_search`,
        defaultMessage: 'Quick search',
    },
    my_tasks: {
        id: `${scope}.filter.labels.my_tasks`,
        defaultMessage: 'Show my tasks',
    },
    clear_all_filters: {
        id: `${scope}.filter.labels.clear_all_filters`,
        defaultMessage: 'Clear allfilters',
    },

    sub: {
        id: `${scope}.title.labels.sub`,
        defaultMessage: 'Sub',
    },
    title: {
        id: `${scope}.title.labels.title`,
        defaultMessage: 'Title',
    },
    description: {
        id: `${scope}.title.labels.description`,
        defaultMessage: 'Description',
    },
    quickfix: {
        id: `${scope}.title.labels.quickfix`,
        defaultMessage: 'Quickfix',
    },
    effort: {
        id: `${scope}.title.labels.effort`,
        defaultMessage: 'Effort',
    },
    target: {
        id: `${scope}.title.labels.target`,
        defaultMessage: 'Target',
    },
    started: {
        id: `${scope}.title.labels.started`,
        defaultMessage: 'Started',
    },
    ended: {
        id: `${scope}.title.labels.ended`,
        defaultMessage: 'Ended',
    },
    progress: {
        id: `${scope}.title.labels.progress`,
        defaultMessage: 'Progress',
    },
    responsible: {
        id: `${scope}.title.labels.responsible`,
        defaultMessage: 'Responsible',
    },
    participants: {
        id: `${scope}.title.labels.participants`,
        defaultMessage: 'Participants',
    },
    tags: {
        id: `${scope}.title.labels.tags`,
        defaultMessage: 'Tags',
    },
    comments: {
        id: `${scope}.title.labels.comments`,
        defaultMessage: 'Comments',
    },
    edit: {
        id: `${scope}.title.labels.edit`,
        defaultMessage: 'Edit',
    },
    incident: {
        id: `${scope}.title.labels.incident`,
        defaultMessage: 'Incident',
    },
    improvement: {
        id: `${scope}.title.labels.improvement`,
        defaultMessage: 'Improvement',
    },
    status_new: {
        id: `${scope}.title.status.new`,
        defaultMessage: 'New',
    },
    status_Plan: {
        id: `${scope}.title.status.plan`,
        defaultMessage: 'Plan',
    },
    status_do: {
        id: `${scope}.title.status.do`,
        defaultMessage: 'Do',
    },
    status_check: {
        id: `${scope}.title.status.check`,
        defaultMessage: 'Check',
    },
    status_act: {
        id: `${scope}.title.status.act`,
        defaultMessage: 'Act',
    },
    status_archived: {
        id: `${scope}.title.status.archived`,
        defaultMessage: 'Archived',
    },
    filter_showing_improvements_closed_this_year: {
        id: `${scope}.filter.message.filter_showing_improvements_closed_this_year`,
        defaultMessage: 'Showing Improvements closed this year',
    },
    filter_showing_improvements_closed_on_budget: {
        id: `${scope}.filter.message.filter_showing_improvements_closed_on_budget`,
        defaultMessage: 'Showing Improvements closed on budget',
    },
    filter_showing_improvements_closed_on_time: {
        id: `${scope}.filter.message.filter_showing_improvements_closed_on_time`,
        defaultMessage: 'Showing Improvements closed on time',
    },
    filter_showing_improvements_over_due: {
        id: `${scope}.filter.message.filter_showing_improvements_over_due`,
        defaultMessage: 'Showing Improvements over due...',
    },
    filter_showing_improvements_over_budget: {
        id: `${scope}.filter.message.filter_showing_improvements_over_budget`,
        defaultMessage: 'Showing Improvements over budget',
    },
    filter_department_and_type_filter_active: {
        id: `${scope}.filter.message.filter_department_and_type_filter_active`,
        defaultMessage: 'Department and Type filter active',
    },



 


});