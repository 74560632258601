import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Button, useTheme, Box, IconButton, Stack, Divider, Typography, Link, TableContainer, TableHead, TableBody, TableCell, Table, TableRow } from "@mui/material";
import { tokens } from "../../assets/theme";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditNotificationsOutlinedIcon from '@mui/icons-material/EditNotificationsOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TimerIcon from "@mui/icons-material/Timer";
import CelebrationIcon from '@mui/icons-material/Celebration';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AxionControls from "../../components/controls/AxionControls";
import { deleteLink, postLink } from "../../fetchers/taskLink";
import Controls from "../../components/controls/Contols";
import SaveIcon from '@mui/icons-material/Save';
import StatBox from "../../components/controls/StatBox";
import Paper from '@mui/material/Paper';
import { useLocalStorage } from "../../hooks/useLocalStorage";

import { getAllTasks, } from "../../fetchers/task";
import { getStatuses } from "../../fetchers/taskStatuses";
import { getDepartments } from "../../fetchers/departments";
import { getAllReportedTime } from "../../fetchers/reportedTime";
import { getPersons } from "../../fetchers/person";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

//Language
import messages from "./messages";
import { FormattedMessage, useIntl } from "react-intl";



export default function ImprovementsDashboard(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);

    let topCounter = 0;
    let topCounterBW = 0;

    //API FETCHERS 
    const taskQuery = useQuery(
        ["task"],
        () => getAllTasks(companyKey),
        {
        }
    );

    const statusesQuery = useQuery(
        ["statuses"],
        () => getStatuses(),
        {}
    );

    const departmentsQuery = useQuery(
        ["departments"],
        () => getDepartments(companyKey),
        {}
    );

    const personQuery = useQuery(
        ["persons"],
        () => getPersons(),
        {}
    );

    const reportedTimeQuery = useQuery(
        ["reportedTimes"],
        () => getAllReportedTime(companyKey),
        {}
    );


    // console.log("taskQuery?.data", taskQuery?.data)
    // console.log("statusesQuery?.data", statusesQuery?.data)

    let spinner = <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        }}
    ></div>

    if (taskQuery.isFetching) {
        //return <Box color="white">Loading Tasks...</Box>;
        return spinner;
    }
    if (departmentsQuery.isLoading) {
        //return <Box color="white">Loading Departments...</Box>;
        return spinner;
    }
    if (statusesQuery.isLoading) {
        //return <Box color="white">Loading Statuses...</Box>;
        return spinner;
    }
    if (personQuery.isLoading) {
        //return <Box color="white">Loading Persons...</Box>;
        return spinner;
    }
    // if (reportedTimeQuery.isLoading) {
    //     //return <Box color="white">Loading Reported Times...</Box>;
    //     return spinner;
    // }

    function getTasksForeachStatus() {
        let result = [];

        statusesQuery.data.forEach((a) => {
            result.push({ description: a.description, taskCount: taskQuery.data.filter(t => t.statusId === a.id && t.taskId === null).length, subtaskCount: taskQuery.data.filter(t => t.statusId === a.id && t.taskId !== null).length });
        })
        return result;
    }

    function getTasksForeachDepartment() {
        let result = [];

        departmentsQuery.data.forEach((d) => {
            result.push({ description: d.name, taskCount: taskQuery.data.filter(t => t.departmentId === d.id && t.taskId === null).length, subtaskCount: taskQuery.data.filter(t => t.departmentId === d.id && t.taskId !== null).length });
        })
        return result;
    }

    //Hidden. Dont want to show data grouped by persons
    // function getTopTotalImprovers() {
    //     let result = [];

    //     personQuery.data.forEach((p) => {

    //         let reportedTime = reportedTimeQuery.data.filter(t => t.personId === p.id);

    //         let totalTime = 0;

    //         reportedTime.forEach((t) => {
    //             totalTime += t.reportedTime;
    //         });
    //         result.push({ description: p.firstName, totalTime: totalTime });
    //     })

    //     result.sort((a, b) => {
    //         return b.totalTime - a.totalTime;
    //     })
    //     return result;
    // }

    function getTopMostOverBudget() {

        let result = [];
        let task = taskQuery.data.filter(t => t.taskId === null);
        let subtask = taskQuery.data.filter(t => t.taskId !== null);

        task.forEach((t) => {
            let totalTime = 0;

            t.reportedTimes.forEach((rt) => {
                totalTime += rt.reportedTime;
            });
            let diff = totalTime - t.estimatedEffort;
            if (diff > 0) {
                result.push({ id: t.id, description: t.title, totalTime: totalTime, estimatedTime: t.estimatedEffort, diff: diff });
            }
        }
        )
        subtask.forEach((t) => {
            let totalTime = 0;
            t.reportedTimes.forEach((rt) => {
                totalTime += rt.reportedTime;
            });
            let diff = totalTime - t.estimatedEffort;
            if (diff > 0) {
                result.push({ id: t.id, description: t.title, totalTime: totalTime, estimatedTime: t.estimatedEffort, diff: diff });
            }
        }
        )
        result.sort((a, b) => {
            return b.totalTime - a.totalTime;
        })
        return result;
    }

    function getTopMostOverTime() {

        let result = [];
        let task = taskQuery.data.filter(t => t.taskId === null && t.endDate === null);
        let subtask = taskQuery.data.filter(t => t.taskId !== null && t.endDate === null);

        task.forEach((t) => {
            let diff = getDaysDue(t.targetDate);
            if (diff < 0) {
                result.push({ id: t.id, description: t.title, targetDate: t.targetDate, diff: diff });
            }
        }
        )
        subtask.forEach((t) => {
            let diff = getDaysDue(t.targetDate);
            if (diff < 0) {
                result.push({ id: t.id, description: t.title, targetDate: t.targetDate, diff: diff });
            }
        }
        )
        result.sort((a, b) => {
            return a.diff - b.diff;
        })
        return result;
    }

    function getStatusesPerPerson() {
        let result = [];
        personQuery.data.forEach((p) => {

            let ownedImprovements = taskQuery.data.filter(t => t.responsibleId === p.id);

            let dataRow = '{"personId": ' + p.id + ', "person": "' + p.firstName + '"';
            statusesQuery.data.forEach((s) => {
                let count = ownedImprovements.filter(t => t.statusId === s.id).length;
                dataRow += ', "' + s.description + '": ' + count;
                //result.push({ personId: p.id, person: p.firstName + " " + p.lastName, statusId: s.id, status: s.description, count: count });

            })
            dataRow += ', "total": ' + ownedImprovements.length;
            dataRow += "}";
            result.push(JSON.parse(dataRow));
        })
        //console.log("result", result);
        return result;
    }

    function getStatusesPerDepartment() {
        let result = [];
        //console.log ("departmentsQuery", departmentsQuery.data);
        departmentsQuery.data?.forEach((d) => {

            let ownedImprovements = taskQuery.data?.filter(t => t.departmentId === d.id);
            //console.log ("ownedImprovements", ownedImprovements);
            ownedImprovements = ownedImprovements?.filter(t => t.typeId === 2);
            //console.log ("ownedImprovements2", ownedImprovements);

            let dataRow = '{"departmentId": ' + d.id + ', "department": "' + d.name + '"';
            statusesQuery.data.forEach((s) => {
                let count = ownedImprovements.filter(t => t.statusId === s.id).length;
                dataRow += ', "' + s.description + '": ' + count;
            })
            dataRow += ', "total": ' + ownedImprovements.length;
            dataRow += "}";
            result.push(JSON.parse(dataRow));
        })
        //console.log("result", result);
        return result;
    }

    function getIncidentsPerDepartment() {
        let result = [];

        let dataRow = "";

        departmentsQuery.data.forEach((d) => {

            let ownedIncidents = taskQuery.data.filter(t => t.departmentId === d.id && t.type?.id === 1);

            dataRow = '{"departmentId": ' + d.id + ', "department": "' + d.name + '"';
            // statusesQuery.data.forEach((s) => {
            //     let count = ownedImprovements.filter(t => t.statusId === s.id).length;
            //     dataRow += ', "' + s.description + '": ' + count;
            // })
            dataRow += ', "total": ' + ownedIncidents.length;
            dataRow += "}";
            result.push(JSON.parse(dataRow));
        })

        let unownedIncidents = taskQuery.data.filter(t => t.departmentId === null && t.type?.id === 1);
        if (unownedIncidents.length > 0) {
            dataRow = '{"departmentId": ' + 0 + ', "department": "Not specified", "total": ' + unownedIncidents.length + '}';
            result.push(JSON.parse(dataRow));
        }

        //console.log("result", result);
        return result;
    }

    function getOpen() {
        return taskQuery.data.filter(t => t.statusId > 0 && t.statusId < 5).length;
    }

    function getDaysDue(date) {
        let d = dayjs(date);
        let diff = d.diff(dayjs(), "day");
        return isNaN(diff) ? 0 : diff;
    }

    function getOverdue() {
        let result = taskQuery.data.filter(t => getDaysDue(t.targetDate) < 0 && t.typeId === 2).length;
        return result;
    }

    function getOverBudget() {
        let task = taskQuery.data.filter(t => t.taskId === null && t.typeId === 2);
        //let subtask = taskQuery.data.filter(t => t.taskId !== null);
        let counter = 0;

        task.forEach((t) => {
            let totalTime = 0;
            t.reportedTimes.forEach((rt) => {
                totalTime += rt.reportedTime;
            });
            let diff = totalTime - t.estimatedEffort;
            if (diff > 0) {
                counter++;
            }
            //result.push({ description: t.description, totalTime: totalTime, estimatedTime: t.estimatedEffort, diff: diff });
        })

        return counter;
    }

    function getClosedOnBudget() {
        let task = taskQuery.data.filter(t => t.taskId === null && t.endDate !== null && t.typeId === 2);
        //let subtask = taskQuery.data.filter(t => t.taskId !== null);
        let counter = 0;

        task.forEach((t) => {
            //let reportedTime = reportedTimeQuery.data.filter(rt => rt.taskId === t.id);
            let totalTime = 0;
            t.reportedTimes.forEach((rt) => {
                totalTime += rt.reportedTime;
            });
            let diff = totalTime - t.estimatedEffort;
            if (diff <= 0) {
                if (t.targetDate >= t.endDate) {
                    counter++;
                }
            }
        })
        return counter;
    }

    function getClosedOnTime() {
        let task = taskQuery.data.filter(t => t.taskId === null && t.endDate !== null && t.typeId === 2);
        let counter = 0;

        task.forEach((t) => {
            if (t.targetDate >= t.endDate) {
                counter++;
            }
        })
        return counter;
    }

    function getClosedThisYear() {

        //console.log("dayjs().startOf('year')", dayjs().startOf('year'));
        let task = taskQuery.data.filter(t => t.taskId === null && t.endDate !== null);
        let counter = 0;
        let startOfYear = formatFullTime(dayjs().startOf('year'));

        task.forEach((t) => {
            if (t.endDate >= startOfYear) {
                counter++;
            }
        })
        return counter;
    }

    function getDaysSinceLastIncident() {
        let task = taskQuery.data?.filter(t => t.type?.id === 1);
        if (task.length === 0) {
            return "-";
        }
        let lastIncident = task?.reduce((prev, current) => (prev.incidentDate > current.incidentDate) ? prev : current);
        let diff = dayjs().diff(dayjs(lastIncident.incidentDate), "day");
        return isNaN(diff) ? 0 : diff;
    }

    function formatFullTime(date) {
        return date ? new Date(date).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit', second: '2-digit' }) : "";
    }

    function formatDate(date) {
        return date ? new Date(date).toLocaleDateString('sv-SE') : "";
    }

    let statusesPerDepartment = getStatusesPerDepartment();
    let IncidentPerDepartment = getIncidentsPerDepartment();


    //console.log("reportedTimeQuery",reportedTimeQuery.data);

    return (
        <Box>
            <Box margin="15px" color="#ffffff"
                // height="120px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px">

                <Box
                    gridColumn="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title={getOpen()}
                        subtitle={formatMessage(messages.open_improvements)}
                        progress="0.75"
                        increase="+14%"
                        icon={
                            <EditNotificationsOutlinedIcon sx={{ color: colors.gray[300], fontSize: "50px" }} />
                        }
                    />
                </Box>

                {/* Closed this year
                <Box
                    gridColumn="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        url="/improvementsListPage?closedthisyear=true"
                        title={getClosedThisYear()}
                        subtitle="Closed this year" //{<FormattedMessage {...messages.openActivities} />}
                        progress="0.75"
                        increase="+14%"
                        icon={
                            <CelebrationIcon sx={{ color: colors.dashbordGreen[500], fontSize: "50px" }} />
                        }
                    />
                </Box> */}

{/* Days since last incident */}
                <Box
                    gridColumn="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        url="/improvementsListPage?closedthisyear=true"
                        title={getDaysSinceLastIncident()}
                        subtitle={formatMessage(messages.days_sice_last_incident)}
                        progress="0.75"
                        increase="+14%"
                        icon={
                            <CalendarMonthOutlinedIcon sx={{ color: colors.dashbordGreen[500], fontSize: "50px" }} />
                        }
                    />
                </Box>

                <Box
                    gridColumn="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        url="/improvementsListPage?closedonbudget=true"
                        title={getClosedOnBudget()}
                        subtitle={formatMessage(messages.closed_on_budget)}
                        progress="0.75"
                        increase="+14%"
                        icon={
                            <MonetizationOnIcon sx={{ color: colors.dashbordGreen[500], fontSize: "50px" }} />
                        }
                    />
                </Box>

                <Box
                    gridColumn="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        url="/improvementsListPage?closedontime=true"
                        title={getClosedOnTime()}
                        subtitle={formatMessage(messages.closed_on_time)}
                        progress="0.75"
                        increase="+14%"
                        icon={
                            <TimerIcon sx={{ color: colors.dashbordGreen[500], fontSize: "50px" }} />
                        }
                    />
                </Box>

                <Box
                    gridColumn="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        url="/improvementsListPage?overdue=true"
                        title={getOverdue()}
                        subtitle={formatMessage(messages.over_due)}
                        progress="0.75"
                        increase="+14%"
                        icon={
                            <TimerIcon sx={{ color: colors.redAccent[500], fontSize: "50px" }} />
                        }
                    />
                </Box>

                <Box
                    gridColumn="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        url="/improvementsListPage?overestimate=true"
                        title={getOverBudget()}
                        subtitle={formatMessage(messages.over_estimate)}
                        progress="0.75"
                        increase="+14%"
                        icon={
                            <MonetizationOnIcon sx={{ color: colors.redAccent[500], fontSize: "50px" }} />
                        }
                    />
                </Box>

            </Box>
            <Box margin="15px" color="#ffffff"
                // height="120px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                //gridAutoRows="140px"
                gap="20px">


                {/* Statuses/department */}
                <Box
                    gridColumn="span 6"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="center"
                    paddingBottom="20px"
                >
                    <Box margin="5px">
                        <Typography
                            marginTop="10px"
                            marginBottom="10px"
                            variant="h5"
                            fontWeight="600"
                            color={colors.gray[100]}
                        >
                            <FormattedMessage {...messages.in_status_per_department} />
                        </Typography>
                        <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                            <Box width="30%">Department</Box>
                            {statusesQuery.data.map((a) => {
                                return (
                                    <Box width="10%" align="right">{a.description}</Box>
                                )
                            })}
                            <Box width="10%" align="right"><FormattedMessage {...messages.total} /></Box>
                        </Stack>
                        <Divider color="white" />
                        {statusesPerDepartment.map((a) => { //.slice(0, 10)
                            return ((a.total > 0 || statusesPerDepartment.length < 10) &&
                                <Box>
                                    <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                                        <Box width="30%"><Link onClick={() => navigate("../improvementsListPage?filterdepartment=" + a.departmentId + "&filtertype=2")} underline="hover" color="#ffffff">{a.department}</Link></Box>
                                        <Box width="10%" align="right">{a.New}</Box>
                                        <Box width="10%" align="right">{a.Plan}</Box>
                                        <Box width="10%" align="right">{a.Do}</Box>
                                        <Box width="10%" align="right">{a.Check}</Box>
                                        <Box width="10%" align="right">{a.Act}</Box>
                                        <Box width="10%" align="right">{a.Archive}</Box>
                                        <Box width="10%" align="right">{a.total}</Box>
                                    </Stack>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                {/* Statuses/employee */}
                {/* <Box
                    gridColumn="span 6"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="flex-star"
                    justifyContent="center"
                    paddingBottom="20px"
                >
                    <Box margin="5px">
                        <Typography
                            marginTop="10px"
                            marginBottom="10px"
                            variant="h5"
                            fontWeight="600"
                            color={colors.gray[100]}
                        >
                            In status per responsible employee
     
                        </Typography>
                        <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                            <Box width="30%">Employee</Box>
                            {statusesQuery.data.map((a) => {
                                return (
                                    <Box width="10%" align="right">{a.description}</Box>
                                )
                            })}
                            <Box width="10%" align="right">Total</Box>
                        </Stack>
                        <Divider color="white" />
                        {getStatusesPerPerson().map((a) => { //.slice(0, 10)
                            return (a.total > 0 &&
                                <Box>
                                    <Stack direction="row" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                                        <Box width="30%"><Link onClick={() => navigate("../improvementsListPage?filteruser=" + a.personId)}  underline="hover" color="#ffffff">{a.person}</Link></Box>
                                        <Box width="10%" align="right">{a.New}</Box>
                                        <Box width="10%" align="right">{a.Plan}</Box>
                                        <Box width="10%" align="right">{a.Do}</Box>
                                        <Box width="10%" align="right">{a.Check}</Box>
                                        <Box width="10%" align="right">{a.Act}</Box>
                                        <Box width="10%" align="right">{a.Archive}</Box>
                                        <Box width="10%" align="right">{a.total}</Box>
                                    </Stack>
                                </Box>
                            )
                        })}
                    </Box>
                </Box> */}

                {/* Top most overdue */}
                <Box
                    gridColumn="span 6"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="flex-star"
                    justifyContent="center"
                    paddingBottom="20px"
                >

                    <Box margin="5px" gridColumn="span 6">
                        <Typography
                            marginTop="10px"
                            marginBottom="10px"
                            variant="h5"
                            fontWeight="600"
                            color={colors.gray[100]}
                        >
                            <FormattedMessage {...messages.top_most_over_estimated_effort} /> 
                        </Typography>
                        <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                            {/* <Box width="10%">Nr</Box> */}
                            <Box width="60%"><FormattedMessage {...messages.improvement} /></Box>
                            <Box width="15%" align="right"><FormattedMessage {...messages.time} /></Box>
                            <Box width="15%" align="right"><FormattedMessage {...messages.estimated} /></Box>
                            <Box width="10%" align="right"><FormattedMessage {...messages.diff} /></Box>
                        </Stack>
                        <Divider color="white" />
                        {getTopMostOverBudget().slice(0, 5).map((a) => { //.slice(0, 10)
                            //topCounterBW = topCounterBW + 1;
                            return (
                                <Box>
                                    <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                                        {/* <Box width="10%">{topCounterBW}</Box> */}
                                        <Box width="60%"><Link onClick={() => navigate("../improvementFullPage/" + a.id)} underline="hover" color="#ffffff">{a.description}</Link></Box>
                                        <Box width="15%" align="right">{Math.round(a.totalTime)}</Box>
                                        <Box width="15%" align="right">{a.estimatedTime || '-'}</Box>
                                        <Box width="10%" align="right">{Math.round(a.diff)}</Box>
                                    </Stack>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>

                {/* Top most over time */}
                <Box
                    gridColumn="span 6"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="flex-star"
                    justifyContent="center"
                    paddingBottom="20px"
                >
                    <Box margin="5px" gridColumn="span 6">
                        <Typography
                            marginTop="10px"
                            marginBottom="10px"
                            variant="h5"
                            fontWeight="600"
                            color={colors.gray[100]}
                        >
                            <FormattedMessage {...messages.top_most_over_due} /> 
                        </Typography>
                        <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                            {/* <Box width="10%">Nr</Box> */}
                            <Box width="65%"><FormattedMessage {...messages.improvement} /></Box>
                            <Box width="15%"><FormattedMessage {...messages.target} /></Box>
                            <Box width="20%" align="right"><FormattedMessage {...messages.days_over_due} /></Box>
                        </Stack>
                        <Divider color="white" />
                        {getTopMostOverTime().slice(0, 5).map((a) => { //.slice(0, 10)
                            topCounterBW = topCounterBW + 1;
                            return (
                                <Box>
                                    <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                                        {/* <Box width="10%">{topCounterBW}</Box> */}
                                        <Box width="65%"><Link onClick={() => navigate("../improvementFullPage/" + a.id)} underline="hover" color="#ffffff">{a.description}</Link></Box>
                                        <Box width="15%">{formatDate(a.targetDate)}</Box>
                                        <Box width="20%" align="right">{Math.abs(a.diff)}</Box>
                                    </Stack>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>

                {/* Incidents per Department*/}
                <Box
                    gridColumn="span 6"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="center"
                    paddingBottom="20px"
                >
                    <Box margin="5px">
                        <Typography
                            marginTop="10px"
                            marginBottom="10px"
                            variant="h5"
                            fontWeight="600"
                            color={colors.gray[100]}
                        >
                            <FormattedMessage {...messages.incidents_per_department} /> 
                        </Typography>
                        <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                            <Box width="30%"><FormattedMessage {...messages.department} /></Box>
                            {/* {statusesQuery.data.map((a) => {
                                return (
                                    <Box width="10%" align="right">{a.description}</Box>
                                )
                            })} */}
                            <Box width="70%" align="right"><FormattedMessage {...messages.total} /></Box>
                        </Stack>
                        <Divider color="white" />
                        {IncidentPerDepartment.map((a) => { //.slice(0, 10)
                            return ((a.total > 0 || IncidentPerDepartment.length < 10) &&
                                <Box>
                                    <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                                        {a.departmentId !== 0 ?
                                            <Box width="30%"><Link onClick={() => navigate("../improvementsListPage?filterdepartment=" + a.departmentId + "&filtertype=1")} underline="hover" color="#ffffff">{a.department}</Link></Box> : <Box width="30%">{a.department}</Box>}
                                        {/* <Box width="10%" align="right">{a.New}</Box>
                                        <Box width="10%" align="right">{a.Plan}</Box>
                                        <Box width="10%" align="right">{a.Do}</Box>
                                        <Box width="10%" align="right">{a.Check}</Box>
                                        <Box width="10%" align="right">{a.Act}</Box>
                                        <Box width="10%" align="right">{a.Archive}</Box> */}
                                        <Box width="70%" align="right">{a.total}</Box>
                                    </Stack>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>

                {/* Top most over time */}
                {/* <Box
                    gridColumn="span 6"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="flex-star"
                    justifyContent="center"
                    paddingBottom="20px"
                >
                    <Box margin="5px" gridColumn="span 6">
                        <Typography
                            marginTop="10px"
                            marginBottom="10px"
                            variant="h5"
                            fontWeight="600"
                            color={colors.gray[100]}
                        >
                            Top most over time
                 
                        </Typography>


                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 300 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Improvement</TableCell>
                                        <TableCell align="left">Target</TableCell>
                                        <TableCell align="right">Days over due</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getTopMostOverTime().slice(0, 5).map((a) => { //.slice(0, 10)
                                        topCounterBW = topCounterBW + 1;
                                        return (
                                            <TableRow>
                                                <TableCell>{a.description}</TableCell>
                                                <TableCell align="right">{formatDate(a.targetDate)}</TableCell>
                                                <TableCell align="right">{Math.abs(a.diff)}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box> */}





                {/* Improvements/status */}
                {/* <Box margin="5px" gridColumn="span 6">
                    <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                        <Box width="40%">Status</Box>
                        <Box width="20%">Nr. of main improvements</Box>
                        <Box width="20%">Nr. of sub improvements</Box>
                        <Box width="20%">Total</Box>
                    </Stack>
                    {getTasksForeachStatus().map((a) => {
                        return (
                            <Box>
                                <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                                    <Box width="40%">{a.description}</Box>
                                    <Box width="20%">{a.taskCount}</Box>
                                    <Box width="20%">{a.subtaskCount}</Box>
                                    <Box width="20%">{a.taskCount + a.subtaskCount}</Box>
                                </Stack>
                            </Box>
                        )
                    })}
                </Box> */}
                {/* Improvements/department */}
                {/* <Box margin="5px" gridColumn="span 6">
                    <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                        <Box width="40%">Department</Box>
                        <Box width="20%">Nr. of main improvements</Box>
                        <Box width="20%">Nr. of sub improvements</Box>
                        <Box width="20%">Total</Box>
                    </Stack>
                    {getTasksForeachDepartment().map((a) => {
                        return (
                            <Box>
                                <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                                    <Box width="40%">{a.description}</Box>
                                    <Box width="20%">{a.taskCount}</Box>
                                    <Box width="20%">{a.subtaskCount}</Box>
                                    <Box width="20%">{a.taskCount + a.subtaskCount}</Box>
                                </Stack>
                            </Box>
                        )
                    })}
                </Box> */}

                {/* Top improvers */}
                {/* <Box margin="5px" gridColumn="span 6">
                    <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                        <Box width="10%">Nr</Box>
                        <Box width="40%">Employee</Box>
                        <Box width="50%">Improvement time</Box>
                    </Stack>
                    {getTopTotalImprovers().slice(0, 10).map((a) => {
                        topCounter = topCounter + 1;
                        return (
                            <Box>
                                <Stack direction="row" width="500px" marginTop="1px" backgroundColor={colors.primary[400]} spacing={1} padding="3px">
                                    <Box width="10%">{topCounter}</Box>
                                    <Box width="40%">{a.description}</Box>
                                    <Box width="50%">{a.totalTime}</Box>
                                </Stack>
                            </Box>
                        )
                    })}
                </Box> */}




            </Box>
        </Box>

    )

}