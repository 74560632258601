import axios from "axios";

export const getPersons = async () => {
    const response = await axios.get("api/persons/");
    const persons = response.data;
    return persons;
}

export const setLastLogin = async (email) => {
    const response = await axios.patch("api/persons?email=" + email);
    const result = response.data;
    return result;
}

export const getPersonByEmail = async (email) => {
    const response = await axios.get("api/person?email=" + email);
    const result = response.data;
    return result;
}