
import React, { useState, useEffect, act } from 'react';
import { useQuery } from "@tanstack/react-query";
import { Box, IconButton, Stack, Button, Tooltip, Typography, Grid, Paper, Link, Switch, CircularProgress, ListSubheader } from "@mui/material";
import AxionControls from "../../components/controls/AxionControls";
import GridDatePicker from "../../components/controls/GridDatePicker";
import Controls from "../../components/controls/Contols";

import { toast } from "react-toastify"
// import messages from "../components/messages";
// import { FormattedMessage } from "react-intl";

import { styled } from "@mui/system";
import FormLabel from "@mui/material/FormLabel";

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Slider from '@mui/material/Slider';
import { useTheme } from '@mui/material/styles';
import { pushErrorMessage, pushNotification } from "../../utils/notifications";
import { postAttachment, getAttachmentsForTask } from "../../fetchers/attachment";
import { getTask2, getTask, putTask, getSubtasks, postTask, deleteTask, copyTask } from "../../fetchers/task";
import { getDimensions } from "../../fetchers/taskDimension";
import { getPersons } from "../../fetchers/person";
import { getTags, postTag } from "../../fetchers/taskTags";
import { getTypes } from "../../fetchers/taskTypes";
import { getStatuses } from "../../fetchers/taskStatuses";
import { getDepartments } from "../../fetchers/departments";
import { getComments, postComment } from "../../fetchers/comments";
import { getCategories } from "../../fetchers/incidentCategory";
import { tokens } from "../../assets/theme";
import { useParams } from 'react-router-dom';
import { useLocalStorage } from "../../hooks/useLocalStorage";
//Icons
import { Create, TaskAlt } from '@mui/icons-material';
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import AnnouncementIcon from '@mui/icons-material/Announcement';
import SaveIcon from '@mui/icons-material/Save';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import StarRateOutlinedIcon from '@mui/icons-material/StarRateOutlined';
import AddTaskIcon from '@mui/icons-material/AddTask';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import LinkIcon from '@mui/icons-material/Link';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddLinkIcon from '@mui/icons-material/AddLink';

import { useNavigate } from "react-router-dom";
import { useMsal, useAccount } from "@azure/msal-react";
import Divider from '@mui/material/Divider';

import ImprovementAddTag from './components/improvementAddTag';
import ImprovementAddAttachment from './components/improvementAddAttachment';
import ImprovementAddTime from './components/improvementAddTime';
import AxionConfirmation from '../../components/controls/AxionConfirmation';

import ImprovementAddLink from './components/improvementAddLink';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
//Language
import messages from "./messages";
import { FormattedMessage, useIntl } from "react-intl";




// import { pushErrorMessage } from "../../../utils/notifications";

//import CircularProgress from '@mui/material/CircularProgress';
// import CircularProgress from '@mui/joy/CircularProgress';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions();




const ImprovementFull = () => {

    const { formatMessage } = useIntl();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const navigate = useNavigate();
    const marks = [
        {
            value: 0,
            label: 'New',
        },
        {
            value: 25,
            label: 'Plan',
        },
        {
            value: 50,
            label: 'Do',
        },
        {
            value: 75,
            label: 'Check',
        },
        {
            value: 100,
            label: 'Act',
        },
        {
            value: 125,
            label: 'Archive',
        },
    ];

    function valuetext(value) {
        return `${value}°C`;
    }


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
    const [open, toggleOpen] = React.useState(false);
    const [dialogValue, setDialogValue] = React.useState({
        title: ''
    });

    const [openAttachments, toggleOpenAttachments] = React.useState(false);
    const [openLinks, toggleOpenLinks] = React.useState(false);
    const [openTimes, toggleOpenTimes] = React.useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);

    const { id } = useParams();
    const { taskId } = useParams();
    const searchParams = new URLSearchParams(document.location.search);
    const departmentId = searchParams.get('departmentId');

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [taskObj, setTaskObj] = useState({
        id: null,
        title: "",
        description: "",
        progress: null,
        statusId: null,
        statusDescription: null,
        responsibleId: null,
        targetDate: null,
        incidentDate: null,
        startDate: null,
        endDate: null,
        companyId: companyKey || '',
        departmentId: departmentId || '',
        groupId: null,
        typeId: 2,
        priority: null,
        effort: null,
        estimatedEffort: null,
        savingsValue: null,
        savingsTime: null,
        quickFix: null,
        taskId: null,
        incidentId: null,
        incidentCategoryId: null,
        incidentCategoryValueId: null,
        createdBy: null,
        createdDate: null,
        lastUpdatedBy: null,
        lastUpdatedDate: null,
        //commentsList: [],
        tagsList: [],
        //attachmentsList: [],
        participantsList: [],
        dimensionValuesList: [],

    });
    const [newComment, setNewComment] = useState("");
    const [, updateState] = React.useState();
    const [tagsList, setTagsList] = useState([]);
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [confirmationTitle, setConfirmationTitle] = React.useState("");
    const [confirmationMessage, setConfirmationMessage] = React.useState("");
    const [parentTaskId, setparentTaskId] = React.useState(null);
    const [incidentId, setIncidentId] = React.useState(null);
    const [improvementId, setImprovementId] = React.useState(null);


    function formatDate(date) {
        return date ? new Date(date).toLocaleDateString('sv-SE') : "";
    }

    function formatTime(date) {
        return date ? new Date(date).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' }) : "";
    }

    function formatFullTime(date) {
        return date ? new Date(date).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit', second: '2-digit' }) : "";
    }
    function formatFullDateTime(date) {
        let formattedDate = formatDate(date) + "T" + formatFullTime(date);
        return formattedDate;
    }
    function formatDateLocalized(date) {
        let locale = companyKey === 2 ? 'en-US' : 'sv-SE';
        return date ? new Date(date).toLocaleDateString(locale) : "";
    }

    function presentFullDateTime(date) {
        let formattedDate = formatDateLocalized(date) + " " + formatFullTime(date);
        return formattedDate;
    }

    function getUpdatedText() {
        let result = "";
        if (taskObj.lastUpdatedBy !== null) {
            result = "Updated by: " + getPersonFullName(taskObj.lastUpdatedBy) + " on " + presentFullDateTime(taskObj.lastUpdatedDate);
        }
        return result;
    }
    function sortByTitle(objects) {
        if (objects === undefined) {
            return;
        }
        return objects.sort((a, b) => a.title.localeCompare(b.title));
    }
    function sortByName(objects) {
        if (objects === undefined) {
            return;
        }
        return objects.sort((a, b) => a.name.localeCompare(b.name));
    }
    function getTotalTime() {
        let total = 0;
        taskQuery.data?.reportedTimes?.forEach((a) => {
            total += a.reportedTime;
        });
        return total;
    }

    //API FETCHERS 
    const personQuery = useQuery(
        ["persons"],
        () => getPersons(),
        {}
    );
    const tagsQuery = useQuery(
        ["tags"],
        () => getTags(companyKey),
        {}
    );
    const typesQuery = useQuery(
        ["types"],
        () => getTypes(),
        {}
    );
    const departmentsQuery = useQuery(
        ["departments"],
        () => getDepartments(companyKey),
        {}
    );
    const commentsQuery = useQuery(
        ["comments", id],
        () => getComments(id),
        {}
    );
    const statusesQuery = useQuery(
        ["statuses"],
        () => getStatuses(),
        {}
    );
    const attachmentsQuery = useQuery(
        ["attachments", id],
        () => getAttachmentsForTask(id),
        {}
    );
    const dimensionsQuery = useQuery(
        ["dimensions"],
        () => getDimensions(companyKey),
        {}
    );
    const categoriesQuery = useQuery(
        ["categories"],
        () => getCategories(companyKey),
        {}
    );
    const taskQuery = useQuery(
        ["task", id],
        () => getTask(id, "task"),
        {
            enabled: personQuery.isSuccess && departmentsQuery.isSuccess && typesQuery.isSuccess && statusesQuery.isSuccess && dimensionsQuery.isSuccess
        }
    );
    const parentTaskQuery = useQuery(
        ["parentTask", parentTaskId],
        () => getTask(parentTaskId, "parent"),
        {
            enabled: (!(parentTaskId === null || parentTaskId === undefined || parentTaskId === 0))
        }
    );
    const subtaskQuery = useQuery(
        ["subtasks", id],
        () => getSubtasks(id),
        {
            enabled: (!(id === null || id === undefined || id === 0))
        }
    );
    const incidentQuery = useQuery(
        ["incidentQuery", incidentId],
        () => getTask(incidentId, "incident"),
        {
            enabled: (!(incidentId === null || incidentId === undefined || incidentId === 0))
        }
    );
    const improvementQuery = useQuery(
        ["improvementQuery", improvementId],
        () => getTask(improvementId, "improvement"),
        {
            enabled: (!(improvementId === null || improvementId === undefined || improvementId === 0))
        }
    );

    useEffect(() => {
        setparentTaskId(taskObj.taskId === undefined ? null : taskObj.taskId);
    }, [taskObj.taskId]);

    useEffect(() => {
        setIncidentId(taskObj.incidentId === undefined ? null : taskObj.incidentId);
    }, [taskObj.incidentId]);

    useEffect(() => {
        setImprovementId(taskObj.improvementId === undefined ? null : taskObj.improvementId);
    }, [taskObj.improvementId]);

    function getPersonImg(personId) {
        //console.log("getPersonImg:" + personId);
        let person = personQuery.data.find(p => p.id === personId);
        return `data:;base64,${person?.imageBase64}`;
    }
    function isNullOrUndefined(value) {
        return value === null || value === undefined;
    }

    function getPersonFullName(personId) {
        let person = personQuery.data.find(p => p.id === personId);
        if (person === undefined) {
            return "";
        }
        return person?.firstName;// + " " + person?.lastName;
    }
    function getPersonId(email) {
        let person = personQuery.data.find(p => p.email.toLowerCase() === email.toLowerCase());
        return person?.id;
    }
    function getTagTitle(tagId) {
        let tag = tagsQuery.data.find(t => t.id === tagId);
        return tag?.title;
    }
    function getDimensionValueName(valueId) {
        let rows = [];
        dimensionsQuery.data.map(d => d.values.map(r => rows.push(r)));

        let name = rows.filter(v => v.id === valueId);
        return name[0]?.name || "Missing?";
    }

    function getDimensionValueDimensionId(valueId) {
        let rows = [];
        dimensionsQuery.data.map(d => d.values.map(r => rows.push(r)));

        let name = rows.filter(v => v.id === valueId);
        return name[0]?.taskDimensionId || "Missing?";
    }

    function getTypeTitle(typeId) {
        let type = typesQuery.data.find(t => t.id === typeId);
        return type?.title;
    }
    function getTypePriority(typeId) {
        let type = typesQuery.data.find(t => t.id === typeId);
        return type?.priority;
    }
    function getStatusByValue(statusValue) {
        let status = statusesQuery.data.find(s => s.value === statusValue);
        return status;
    }
    function isDateValid(dateStr) {
        return !isNaN(new Date(dateStr));
    }
    function isImageType(fileType) {
        return fileType.startsWith("image/png") || fileType.startsWith("image/gif") || fileType.startsWith("image/jpeg");
    }
    function getDocIcon(fileName) {
        fileName = fileName.toLowerCase();
        if (fileName.substr(fileName.length - 3) === 'pdf') {
            return '../../assets/pdf.png';
        }
        if (fileName.substr(fileName.length - 3) === 'doc') {
            return '../../assets/doc.png';
        }
        if (fileName.substr(fileName.length - 4) === 'docx') {
            return '../../assets/doc.png';
        }
        if (fileName.substr(fileName.length - 4) === 'xlsx') {
            return '../../assets/excel.png';
        }
        if (fileName.substr(fileName.length - 3) === 'xls') {
            return '../../assets/excel.png';
        }
        if (fileName.substr(fileName.length - 3) === 'ppt') {
            return '../../assets/ppt.png';
        }
        if (fileName.substr(fileName.length - 4) === 'pptx') {
            return '../../assets/ppt.png';
        }
        return '../../assets/unknown.png'
    }
    function getReadableFileName(fileName) {
        return fileName.slice(fileName.indexOf('_') + 1)
    }
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name, boxSize) {
        if (name === undefined || name === null || name === "" || name.split(' ').length < 2) {
            return ["N", "N"];
        }
        return {
            sx: {
                bgcolor: stringToColor(name),
                border: 1,
                borderColor: '#000000',
                height: boxSize,
                width: boxSize,
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    useEffect(() => {
        if (!(id === undefined || id === null || id === 0 || id === '0')) {
            let tags = sortByTitle(taskQuery.data?.tags)

            let task = { ...taskObj };
            task.id = id;

            task.description = taskQuery.data?.description;
            task.title = taskQuery.data?.title;
            task.typeId = taskQuery.data?.typeId;
            task.responsibleId = taskQuery.data?.responsibleId;
            task.departmentId = taskQuery.data?.departmentId;
            task.statusId = taskQuery.data?.statusId;
            task.statusDescription = taskQuery.data?.status?.Description;
            task.taskId = taskQuery.data?.taskId;
            task.participantsList = taskQuery.data?.participants?.map(p => p.id);
            task.tagsList = tags?.map(t => t.id);
            //task.dimensionValuesIdList = taskQuery.data?.dimensionValues?.map(p => p.id)//.map((p) => { return { id: p.id, taskDimensionId: p.taskDimensionId, name: p.name } });
            task.dimensionValuesList = taskQuery.data?.dimensionValues?.map((p) => { return { id: p.id, taskDimensionId: p.taskDimensionId, name: p.name } });
            task.status = taskQuery.data?.status;
            task.effort = taskQuery.data?.effort;
            task.estimatedEffort = taskQuery.data?.estimatedEffort;
            task.savingsValue = taskQuery.data?.savingsValue;
            task.savingsTime = taskQuery.data?.savingsTime;
            task.quickFix = taskQuery.data?.quickFix;
            task.typeId = taskQuery.data?.typeId
            task.incidentCategoryId = taskQuery.data?.incidentCategoryId;
            task.incidentCategoryValueId = taskQuery.data?.incidentCategoryValueId;
            task.incidentDate = taskQuery.data?.incidentDate;
            task.incidentId = taskQuery.data?.incidentId;
            task.improvementId = taskQuery.data?.improvementId;
            task.targetDate = taskQuery.data?.targetDate;
            task.createdBy = taskQuery.data?.createdBy;
            task.createdDate = taskQuery.data?.createdDate;
            task.lastUpdatedBy = taskQuery.data?.lastUpdatedBy;
            task.lastUpdatedDate = taskQuery.data?.lastUpdatedDate;
            task.startDate = taskQuery.data?.startDate;
            task.endDate = taskQuery.data?.endDate;

            if (task.participantsList === undefined) { task.participantsList = [] };
            if (task.tagsList === undefined) { task.tagsList = [] };
            if (task.taskId === undefined) { task.taskId = null };
            if (task.dimensionValuesList === undefined) { task.dimensionValuesList = [] };

            setTaskObj(task);
        }
    }, [taskQuery.data]);

    const handleCloseTagDialog = () => {
        setDialogValue({
            title: '',
            year: '',
        });
        toggleOpen(false);
    };

    const handleSubmitTag = (event) => {
        event.preventDefault();

        let tag = { Title: dialogValue.title, CompanyId: companyKey }

        postTag(tag).then(() => {
            tagsQuery.refetch();
        })
            .catch((error) => {
                console.log(error);
                //props.setShowBackdrop(false);
                pushErrorMessage(formatMessage(messages.could_not_create_the_tag));
            });
        handleCloseTagDialog();
    };

    const handleCloseAttachmentsDialog = () => {
        toggleOpenAttachments(false);
    };

    const handleCloseLinksDialog = () => {
        toggleOpenLinks(false);
    };

    const handleCloseTimesDialog = () => {
        toggleOpenTimes(false);
    };

    // const handleSubmitAttachments = (event) => {
    //     event.preventDefault();

    //     let tag = { Title: dialogValue.title }

    //     postTag(tag).then(() => {
    //         console.log("Tag created");
    //         tagsQuery.refetch();
    //     })
    //         .catch((error) => {
    //             console.log(error);
    //             //props.setShowBackdrop(false);
    //             //pushErrorMessage(`Could not update the task.`);
    //         });
    //     handleCloseTagDialog();
    // };

    const handleSaveClick = () => {

        let userId = getPersonId(account.username);
        taskObj.companyId = companyKey;
        taskObj.dimensionValuesList = taskObj.dimensionValuesList.map(x => x.id);
        //Remaove!
        taskObj.priority = 1;

        //Validation
        if (taskObj.title === "" || taskObj.title === null || taskObj.title === undefined) {
            pushErrorMessage(formatMessage(messages.title_is_required));
            return;
        }
        if (taskObj.description === "" || taskObj.description === null || taskObj.description === undefined) {
            pushErrorMessage(formatMessage(messages.description_is_required));
            return;
        }
        if (taskObj.typeId === null || taskObj.typeId === undefined) {
            pushErrorMessage(formatMessage(messages.type_is_required));
            return;
        }
        if (taskObj.typeId === 1) {
            if (taskObj.incidentCategoryValueId === null || taskObj.incidentCategoryValueId === undefined) {
                pushErrorMessage(formatMessage(messages.category_is_required));
                return;
            }
        }
        if (taskObj.departmentId === null || taskObj.departmentId === undefined || taskObj.departmentId === "") {
            pushErrorMessage(formatMessage(messages.department_is_required));
            return;
        }
        if (taskObj.responsibleId === null || taskObj.responsibleId === undefined) {
            pushErrorMessage(formatMessage(messages.responsible_is_required));
            return;
        }


       //console.log("Save taskObj", taskObj);

        if (id === 0 || id === null || id === '0') {
            console.log("Create new task");
            taskObj.createdBy = userId;
            taskObj.taskId = taskId;

            
            if (taskObj.typeId === 1) {
                taskObj.targetDate = (taskObj.targetDate === undefined || taskObj.targetDate === undefined ? taskObj.targetDate = formatDate(new Date()) : taskObj.targetDate);
                taskObj.incidentDate = (taskObj.incidentDate === undefined || taskObj.incidentDate === null ? taskObj.incidentDate = formatDate(new Date()) : taskObj.incidentDate);
            }

            taskObj.statusId = 0;

            postTask(taskObj)
                .then(response => {
                    navigate('/improvementFullPage/' + response);
                })
                .then(() => {
                    taskQuery.refetch();

                })
                .catch((error) => {
                    console.log(error);
                    //props.setShowBackdrop(false);
                    pushErrorMessage(formatMessage(messages.could_not_create_the_task));
                });
        } else {
            console.log("Update task");
            taskObj.lastUpdatedBy = userId;
            putTask(taskObj).then(() => {
                taskQuery.refetch();

            })
                .catch((error) => {
                    console.log(error);
                    //props.setShowBackdrop(false);
                    pushErrorMessage(formatMessage(messages.could_not_update_the_task));
                });

        }
    }

    const handleDeleteClick = () => {
        setConfirmationTitle(formatMessage(messages.delete_task));
        setConfirmationMessage(formatMessage(messages.sure_delete_task));
        setOpenConfirmation(true);
    }

    const doDeleteTask = () => {

        setOpenConfirmation(false);
        deleteTask(id).then(() => {
            navigate(-1);

        })
            .catch((error) => {
                console.log(error);
                //props.setShowBackdrop(false);
                pushErrorMessage(formatMessage(messages.could_not_delete_the_task));
            });
    }

    const cancelDeleteTask = () => {
        setConfirmationTitle("");
        setConfirmationMessage("");
        setOpenConfirmation(false);
    }

    const handleCommentAdd = () => {
        if ((newComment === "") || (newComment === null)) {
            return;
        }

        let userId = getPersonId(account.username);
        let comment = {
            taskId: id,
            commentText: newComment,
            createdBy: userId,
            createdDate: new Date()
        };
        postComment(comment).then(() => {
            setNewComment("");
            commentsQuery.refetch();
        })
            .catch((error) => {
                console.log(error);
                //props.setShowBackdrop(false);
                pushErrorMessage(formatMessage(messages.could_not_create_the_comment));
            });
    }
    const handleGotoTaskClick = (taskId) => {
        //console.log("handleGotoTaskClick", taskId);
        navigate("/improvementFullPage/" + taskId);
    }

    const handleTagsChange = (event) => {
        if (event.target.value.find(x => x === "9999999")) {
            toggleOpen(true);
            return;
        }

        let task = { ...taskObj };
        const {
            target: { value },
        } = event;
        let tags = typeof value === 'string' ? value.split(',') : value
        task.tagsList = tags;
        setTaskObj(task);

    };

    const handleDimChange = (event) => {

        let task = { ...taskObj };
        const {
            target: { value },
        } = event;

        let taskDimensionId = event.target.name;

        let values = typeof value === 'string' ? value.split(',') : value
        let currentDim = values.map(x => { return { id: x, taskDimensionId: getDimensionValueDimensionId(x) } })

        let filtered = task.dimensionValuesList.filter(x => x.taskDimensionId !== taskDimensionId);


        currentDim.map(x => filtered.push(x));
        task.dimensionValuesList = filtered;

        setTaskObj(task);
    };

    const handleResponsibleChange = (event) => {
        const {
            target: { value },
        } = event;
        let task = { ...taskObj };
        task.responsibleId = value;
        setTaskObj(task);
    };

    const handleParticipantsChange = (event) => {
        console.info('handleParticipantsChange:');
        const {
            target: { value },
        } = event;
        let task = { ...taskObj };
        let participants = typeof value === 'string' ? value.split(',') : value
        task.participantsList = participants;
        setTaskObj(task);
    };

    const handleDescriptionChange = (event) => {
        let task = { ...taskObj };
        task.description = event.target.value;
        setTaskObj(task);
    };

    const handleTitleChange = (event) => {
        let task = { ...taskObj };
        task.title = event.target.value;
        setTaskObj(task);
    };

    const handleTypeChange = (event) => {
        let task = { ...taskObj };
        task.typeId = event.target.value;
        setTaskObj(task);
    };

    const handleQuickFixChange = (event) => {
        let task = { ...taskObj };
        task.quickFix = event.target.checked;
        setTaskObj(task);
    };

    const handleTargetDateChange = (event) => {
        let task = { ...taskObj };
        if (isDateValid(event)) {
            task.targetDate = formatFullDateTime(event);
            setTaskObj(task);
        }
    };

    const handleIncidentDateChange = (event) => {
        let task = { ...taskObj };
        if (isDateValid(event)) {
            task.incidentDate = formatFullDateTime(event);
            setTaskObj(task);
        }
    };

    const handleEstimatedEffortChange = (event) => {
        let task = { ...taskObj };
        task.estimatedEffort = event.target.value;
        setTaskObj(task);
    };

    const handleSavingsValueChange = (event) => {
        let task = { ...taskObj };
        task.savingsValue = event.target.value;
        setTaskObj(task);
    };

    const handleSavingsTimeChange = (event) => {
        let task = { ...taskObj };
        task.savingsTime = event.target.value;
        setTaskObj(task);
    };

    const handleEffortChange = (event) => {
        let task = { ...taskObj };
        task.effort = event.target.value;
        setTaskObj(task);
    };

    const handleDepartmentChange = (event) => {
        let task = { ...taskObj };

        task.departmentId = event.target.value;
        setTaskObj(task);
    };

    const handleIncidentCategoryChange = (event) => {

        let task = { ...taskObj };

        let values = categoriesQuery.data.map(c => c.values).flat();
        let value = values.find(c => c.id === event.target.value);

        task.incidentCategoryId = value?.taskIncidentCategoryId;
        task.incidentCategoryValueId = event.target.value;

        setTaskObj(task);
    };
    const handleCopyTask = (taskId) => {
        copyTask(taskId).then((result) => {
            console.log("copyTask", result);
            navigate("../improvementFullPage/" + result)
            ///taskQuery.refetch();
        })
            .catch((error) => {
                console.log(error);
                pushErrorMessage(formatMessage(messages.error_copy_message));
            });
    }

    const handleNewCommentChange = (event) => {
        setNewComment(event.target.value);
    };

    const handleStatusChange = (event, newValue) => {
        let task = { ...taskObj };

        task.status = getStatusByValue(event.target.value);
        task.statusId = task.status.id;
        task.statusDescription = task.status.description;
        setTaskObj(task);
    }

    let spinner = <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        }}
    >
        <CircularProgress color="secondary" />
    </div>

    if (taskQuery.isLoading) {
        //return <Box color="white">Loading Task...</Box>;
        return (spinner);
    }
    if (incidentQuery.isLoading && incidentId !== null) {
        //console.log ("incidentQuery.isLoading?", incidentQuery.isLoading, incidentId);   
        //return <Box color="white">Loading incidentQuery...</Box>;
        return (spinner);
    }
    if (improvementQuery.isLoading && improvementId !== null) {
        //console.log ("improvementQuery.isLoading?", improvementQuery.isLoading, improvementId);   
        //return <Box color="white">Loading improvementQuery...</Box>;
        return (spinner);
    }
    if (parentTaskQuery.isLoading && parentTaskId !== null) {
        //console.log ("parentTaskQuery.isLoading?", parentTaskQuery.isLoading, parentTaskId); 
        //return <Box color="white">Loading parentTaskQuery...</Box>;
        return (spinner);
    }
    if (subtaskQuery.isLoading) {
        //return <Box color="white">Loading Subtasks...</Box>;
        return (spinner);
    }
    if (personQuery.isLoading) {
        //return <Box color="white">Loading Persons...</Box>;
        return (spinner);
    }
    if (tagsQuery.isLoading) {
        //return <Box color="white">Loading Tags...</Box>;
        return (spinner);
    }
    if (typesQuery.isLoading) {
        //return <Box color="white">Loading Types...</Box>;
        return (spinner);
    }
    if (departmentsQuery.isLoading) {
        //return <Box color="white">Loading Departments...</Box>;
        return (spinner);
    }
    if (commentsQuery.isLoading) {
        //return <Box color="white">Loading Comments...</Box>;
        return (spinner);
    }
    if (statusesQuery.isLoading) {
        //return <Box color="white">Loading Statuses...</Box>;
        return (spinner);
    }
    if (dimensionsQuery.isLoading) {
        //return <Box color="white">Loading Dimensions...</Box>;
        return (spinner);
    }
    if (categoriesQuery.isLoading) {
        //return <Box color="white">Loading Dimensions...</Box>;
        return (spinner);
    }


    if (id === '0' && taskId !== '0' && taskObj.departmentId === undefined) {
        let task = { ...taskObj };
        task.departmentId = departmentId;
        setTaskObj(task);
    }


    //console.log("taskObj:", taskObj);
    // console.log("Persons:", personQuery.data);
    // console.log("Subtasks:", subtaskQuery.data);
    // console.log("dims", dimensionsQuery.data);
    // console.log("hallo", taskObj.dimensionValuesList?.filter(x => x.taskDimensionId === 1))
    // console.log("taskid:", taskObj.taskId);

    // console.log("typeid:", taskObj.typeId);
    //console.log("taskobj:", taskObj);
    //console.log("categoriesQuery:", categoriesQuery.data);
    //console.log("improvementQuery:", improvementQuery.data);
    //console.log("incidentQuery:", incidentQuery.data);
    //console.log ("companyKey", companyKey);
    //console.log ("categoriesQuery", categoriesQuery.data);

    if (taskObj.typeId === null || taskObj.typeId === undefined) {
        let task = { ...taskObj };
        task.typeId = 2;
        setTaskObj(task);
    }

    let content = <>
        <Box m="20px"
            // height="120px"
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            // gridAutoRows="100px"
            gap="20px"
            color="#ffffff"
        >

            {(!isNullOrUndefined(taskObj.taskId)) &&
                <Box sx={{ backgroundColor: colors.primary[200] }} gridColumn="span 8" padding="5px" paddingLeft="10px" spacing="10px">
                    <AnnouncementIcon />
                    <Link marginLeft="10px" href={"/improvementFullPage/" + taskObj.taskId} underline="hover" color={colors.primary[800]}><FormattedMessage {...messages.this_is_a_sub_improvement_to} />: {parentTaskQuery.data?.title} </Link>
                </Box>
            }

            {(!isNullOrUndefined(taskObj.incidentId)) &&
                <Box sx={{ backgroundColor: colors.primary[200] }} gridColumn="span 8" padding="5px" paddingLeft="10px" spacing="10px">
                    <AnnouncementIcon />
                    <Link marginLeft="10px" href={"/improvementFullPage/" + taskObj.incidentId} underline="hover" color={colors.primary[800]}><FormattedMessage {...messages.this_improvement_is_created_from} />: {incidentQuery.data?.title}</Link>
                </Box>
            }

            <Box gridColumn="span 6">

                <Box>
                    <AxionControls.AxionTextBox
                        label={<FormattedMessage {...messages.title} />}
                        value={taskObj.title || ''}
                        onChange={handleTitleChange}
                    />
                </Box>

                <Box>
                    <AxionControls.AxionTextBoxMultiline
                        name="Description"
                        label={<FormattedMessage {...messages.description} />}
                        value={taskObj?.description || ''}
                        onChange={handleDescriptionChange}
                    />
                </Box>

                <Stack direction="row" spacing={"auto"} alignItems="center" width="100%" marginTop="20px" display="flex">
                    {id !== '0' && taskObj.typeId === 2 &&
                        <Box>
                            <AxionControls.AxionTextBox
                                label={<FormattedMessage {...messages.savings_value_sek} />}
                                value={taskObj?.savingsValue || ''}
                                onChange={handleSavingsValueChange}
                            />
                        </Box>}

                    {id !== '0' && taskObj.typeId === 2 &&
                        <Box>
                            <AxionControls.AxionTextBox
                                label={<FormattedMessage {...messages.savings_time} />}
                                value={taskObj?.savingsTime || ''}
                                onChange={handleSavingsTimeChange}
                            />
                        </Box>}
                    {id !== '0' && taskObj.typeId === 2 &&
                        <Box>
                            <AxionControls.AxionTextBox
                                label={<FormattedMessage {...messages.estimated_effort} />}
                                value={taskObj?.estimatedEffort || ''}
                                onChange={handleEstimatedEffortChange}
                            />
                        </Box>
                    }


                    {/* ReportedTimes */}
                    {id !== '0' && taskObj.typeId === 2 &&
                        <>
                            <Stack direction="row" display="flex" justifyContent="flex-end">
                                <Box>
                                    <AxionControls.AxionTextBox
                                        label={<FormattedMessage {...messages.actual_effort} />}
                                        value={getTotalTime()}
                                        disabled={true}
                                    //onChange={handleEffortChange}
                                    />
                                </Box>
                                {/* <Box width="70%" paddingTop="10px" paddingLeft="10px" >{getTotalTime()} h</Box> */}
                                <Box display="flex" justifyContent="flex-end" width="40px" >
                                    <IconButton color="#ff00ff" onClick={() => { toggleOpenTimes(true) }}> <MoreTimeIcon /> </IconButton>
                                </Box>
                            </Stack>
                            <Dialog open={openTimes} onClose={handleCloseTimesDialog} maxWidth="md" >
                                <Box m={0} sx={{ backgroundColor: colors.primary[400], borderRadius: "4px" }} width="700px" >

                                    <DialogTitle>Report time</DialogTitle>
                                    <DialogContent>
                                        <ImprovementAddTime taskId={id} reportedTimes={taskQuery.data?.reportedTimes} taskQuery={taskQuery} personQuery={personQuery} />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseTimesDialog}
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor: "#535560",
                                                },
                                                backgroundColor: colors.primary[300],
                                                color: "#000000",
                                                marginBottom: "15px",
                                                marginRight: "15px"
                                            }}><FormattedMessage {...messages.close} /></Button>
                                    </DialogActions>
                                </Box>
                            </Dialog></>}

                    {/* <Box width="150px"></Box> */}
                </Stack>

                <Stack direction="row" spacing={"auto"} alignItems="center" width="100%" marginTop="20px">
                    {taskObj.typeId === 1 &&
                        <Box width="180px">
                            <GridDatePicker
                                label={<FormattedMessage {...messages.incident_date} />}
                                locale="sv"
                                maxDate=""
                                minDate=""//{Date().toLocaleString()}
                                onChange={handleIncidentDateChange}
                                date={taskObj.incidentDate}

                            />
                        </Box>
                    }

                    <Box width="180px">
                        <GridDatePicker
                            label={<FormattedMessage {...messages.target_date} />}
                            locale="sv"
                            maxDate=""
                            minDate={Date().toLocaleString()}
                            onChange={handleTargetDateChange}
                            date={taskObj.targetDate}
                        />
                    </Box>

                    {id !== '0' && taskObj.typeId === 2 && <><Box>
                        {/* Start date: {formatDate(taskObj.startDate)} */}
                        <Controls.Input
                            name="startDate"
                            label={<FormattedMessage {...messages.start_date} />}
                            value={formatDateLocalized(taskObj.startDate)}
                            onChange=""//{handleInputChange}
                            error=""//{errors.name}
                            sx={{ mb: 1 }}
                            // fullWidth={true}
                            disabled={true}
                        />
                        {/* </Box>
                        <Box> */}
                        {/* End date: {formatDate(taskObj.endDate)} */}
                        <Controls.Input
                            name="endDate"
                            label={<FormattedMessage {...messages.end_date} />}
                            value={formatDateLocalized(taskObj.endDate)}
                            onChange=""//{handleInputChange}
                            error=""//{errors.name}
                            sx={{ mb: 1 }}
                            // fullWidth={true}
                            disabled={true}
                        />
                    </Box></>}
                </Stack>

                {id !== '0' && taskObj.typeId === 2 && <Box marginTop="10px">Status</Box>}
                {id !== '0' && taskObj.typeId === 2 && <Box padding="10px" backgroundColor={colors.primary[400]} justifyContent="center">
                    <Box sx={{ width: '80%' }} marginLeft="10px">

                        <Slider
                            aria-label="Always visible"
                            defaultValue={taskObj.status?.value}
                            value={taskObj.status?.value || ''}
                            getAriaValueText={valuetext}
                            step={25}
                            marks={marks}
                            valueLabelDisplay="auto"//="on"
                            min={0}
                            max={125}
                            onChange={handleStatusChange}

                        />
                    </Box>
                </Box>}
                {id !== '0' &&
                    <Box marginTop="30px"
                        //m="20px"
                        // height="120px"
                        display="grid"
                        gridTemplateColumns="repeat(2, 1fr)"
                        // gridAutoRows="100px"
                        gap="5px"
                        color="#ffffff"
                    >

                        <Box gridColumn="span 2">
                            <AxionControls.AxionTextBoxMultiline
                                name="txtComment"
                                label={<FormattedMessage {...messages.comment} />}
                                value={taskObj.commentText || ''}
                                onChange={handleNewCommentChange}
                            />
                        </Box>

                        <Box gridColumn="span 2"
                            width="100%"
                            display="flex"
                            justifyContent="right"
                        >
                            <Controls.Button
                                type="submit"
                                text={<FormattedMessage {...messages.add_comment} />}
                                onClick={handleCommentAdd}
                                startIcon={<SaveIcon />}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: colors.primary[400],
                                    },
                                    backgroundColor: colors.primary[300],
                                    color: "#000000",

                                }}
                            />
                        </Box>
                        <Box gridColumn="span 2" backgroundColor={colors.primary[400]} height="1px"></Box>
                        {
                            commentsQuery.data
                                .sort(function (a, b) { return new Date(b.createdDate) - new Date(a.createdDate) })
                                .map((c) => (
                                    <Box backgroundColor={colors.primary[400]} padding="10px" margin="2px" gridColumn="span 2">
                                        <Stack direction="row" spacing={"10px"}>
                                            <Avatar alt="value" src={getPersonImg(c.createdBy)} {...stringAvatar(getPersonFullName(c.createdBy), 25)} />
                                            <Box color={colors.primary[800]}>{getPersonFullName(c.createdBy)} on {presentFullDateTime(c.createdDate)}</Box>
                                        </Stack>
                                        <Box padding="0px" marginTop="5px">{c.commentText}</Box>
                                    </Box>
                                ))}
                    </Box>
                }
                {/* <Divider orientation="vertical"  padding={1} color="white" sx={{color: '#ffffff'}} /> */}

            </Box>

            {/* Column 2 */}

            <Box gridColumn="span 2">
                <Box>
                    <FormControl sx={{ mt: 2, width: '100%' }}>
                        <InputLabel id="department-label"><FormattedMessage {...messages.type} /></InputLabel>
                        <Select
                            labelId="type-label"
                            id="type-select"
                            value={taskObj?.typeId || ''}
                            label={<FormattedMessage {...messages.type} />}
                            onChange={handleTypeChange}
                        >
                            {typesQuery?.data?.map((t) => (
                                <MenuItem key={t.id} value={t.id} >
                                    {(t.id === 1) ? <BoltOutlinedIcon fontSize="small" /> : <StarRateOutlinedIcon fontSize="small" />}
                                    {t.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                {taskObj.typeId === 1 &&
                    <Box>
                        <FormControl sx={{ mt: '12px', width: '100%' }}>
                            <InputLabel id="category-label"><FormattedMessage {...messages.category} /></InputLabel>
                            <Select
                                labelId="category-label"
                                id="category-select"
                                value={taskObj?.incidentCategoryValueId}
                                label={formatMessage(messages.category)}
                                onChange={handleIncidentCategoryChange}
                            // MenuProps={MenuProps}
                            >
                                {categoriesQuery?.data?.map((x) => {
                                    if (x.name === "zzzTillbud") { return []; }
                                    let category = [];
                                    category.push(<ListSubheader>{x.name}</ListSubheader>);
                                    var cat = x.values.map((y) => (
                                        <MenuItem key={y.id} value={y.id}>{y.name}</MenuItem>
                                    ));
                                    category.push(cat);
                                    return category;
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                }
                <Box>
                    <FormControl sx={{ mt: 2, width: '100%' }}>
                        <InputLabel id="department-label"><FormattedMessage {...messages.department} /></InputLabel>
                        <Select
                            labelId="department-label"
                            id="department-select"
                            value={taskObj?.departmentId || ''}
                            label={<FormattedMessage {...messages.department} />}
                            onChange={handleDepartmentChange}
                        // MenuProps={MenuProps}
                        >
                            {departmentsQuery?.data?.map((d) => (
                                <MenuItem
                                    key={d.id}
                                    value={d.id}
                                >
                                    {d.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {taskObj.typeId === 2 &&
                    <Box marginTop="15px" display="flex" alignItems="center" >
                        <Switch
                            checked={taskObj.quickFix || ''}
                            onChange={handleQuickFixChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="secondary"
                            label="hello"
                        /><Typography color={colors.primary[100]}><FormattedMessage {...messages.quickfix} /></Typography>
                    </Box>
                }
                <Box>
                    <FormControl sx={{ mt: 2, width: '100%' }}>
                        <InputLabel id="responsible-multiple-chip-label"><FormattedMessage {...messages.responsible} /></InputLabel>
                        <Select
                            labelId="responsible-multiple-chip-label"
                            id="responsible-multiple-chip"
                            value={taskObj?.responsibleId || ''}
                            onChange={handleResponsibleChange}
                            input={<OutlinedInput id="responsible-select-multiple-chip" label="Select responsible" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    <Chip key={taskObj.responsibleId} label={getPersonFullName(taskObj.responsibleId)}
                                        //avatar={<Avatar alt="value" src={"../../assets/" + getPersonImg(taskObj.responsibleId)} />}
                                        avatar={<Avatar alt="value" src={getPersonImg(taskObj.responsibleId)}  {...stringAvatar(getPersonFullName(taskObj.responsibleId), 30)} />}
                                        variant="outlined"
                                    //onDelete={handleParticipantsDelete} 
                                    />
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {personQuery?.data?.filter(f => f.hide !== true).map((p) => (
                                <MenuItem
                                    key={p.id}
                                    value={p.id}
                                //   style={getStyles(name, personName, theme)}
                                >
                                    <Avatar alt="value" src={`data:;base64,${p.imageBase64}`}  {...stringAvatar(p.firstName, 30)} /><Box paddingLeft="7px">{p.firstName}</Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                {taskObj.typeId === 2 &&
                    <Box>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <InputLabel id="participants-multiple-chip-label"><FormattedMessage {...messages.participants} /></InputLabel>
                            <Select
                                labelId="participants-multiple-chip-label"
                                id="participants-multiple-chip"
                                multiple
                                value={taskObj.participantsList || []}
                                onChange={handleParticipantsChange}
                                input={<OutlinedInput id="participants-select-multiple-chip" label={<FormattedMessage {...messages.participants} />} />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((item) => (
                                            <Chip key={item} label={getPersonFullName(item)}
                                                avatar={<Avatar alt="value" src={getPersonImg(item)} {...stringAvatar(getPersonFullName(item), 30)} />}
                                                variant="outlined"
                                            //onDelete={handleParticipantsDelete} 
                                            />

                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {personQuery?.data?.filter(f => f.hide !== true).map((p) => (
                                    <MenuItem
                                        key={p.id}
                                        value={p.id}
                                    //   style={getStyles(name, personName, theme)}
                                    >
                                        <Avatar alt="value" src={`data:;base64,${p.imageBase64}`}  {...stringAvatar(p.firstName, 30)} /><Box paddingLeft="7px">{p.firstName}</Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                }
                {taskObj.typeId === 2 &&
                    <Box>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <InputLabel id="tags-multiple-chip-label"><FormattedMessage {...messages.tags} /></InputLabel>
                            <Select
                                labelId="tags-multiple-chip-label"
                                id="tags-multiple-chip"
                                multiple
                                value={taskObj.tagsList || []}
                                onChange={handleTagsChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                autoComplete="off"
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={getTagTitle(value)} size='small' /> //onDelete={handleTagsDelete} 
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="9999999" value="9999999" sx={{ fontStyle: 'oblique', fontWeight: '100' }}><FormattedMessage {...messages.add_a_new_tag} /></MenuItem>

                                {sortByTitle(tagsQuery?.data)?.map((t) => (
                                    <MenuItem
                                        key={t.id}
                                        value={t.id}
                                    //   style={getStyles(name, personName, theme)}
                                    >
                                        {t.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Dialog open={open} onClose={handleCloseTagDialog} m={0} width="400px">
                            <Box m={0} sx={{ backgroundColor: colors.primary[400], borderRadius: "4px" }} width="400px" >
                                <form onSubmit={handleSubmitTag}>
                                    <DialogTitle><FormattedMessage {...messages.add_a_new_tag} /></DialogTitle>
                                    <DialogContent>
                                        {/* <DialogContentText>
                                    Add a new Tag of your choice
                                </DialogContentText> */}
                                        <AxionControls.AxionTextBox
                                            label={<FormattedMessage {...messages.add_tag} />}
                                            value={dialogValue.title || ''}
                                            autoComplete="new-password"
                                            inputProps={{ autoComplete: 'new-password' }}
                                            onChange={(event) =>
                                                setDialogValue({
                                                    ...dialogValue,
                                                    title: event.target.value,
                                                })
                                            }
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseTagDialog}
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor: "#535560",
                                                },
                                                backgroundColor: colors.primary[300],
                                                color: "#000000",
                                                marginBottom: "15px",
                                            }}><FormattedMessage {...messages.cancel_x} /></Button>
                                        <Button type="submit"
                                            sx={{
                                                backgroundColor: "#799c4a",
                                                "&:hover": {
                                                    backgroundColor: "#5e7939",
                                                    borderColor: "#5e7939",
                                                    boxShadow: "none",
                                                },
                                                marginRight: "15px",
                                                marginBottom: "15px",
                                            }}><FormattedMessage {...messages.add_tag} /></Button>
                                    </DialogActions>
                                </form>
                            </Box>
                        </Dialog>
                    </Box>
                }
                {/* Dimension 1 */}
                {id !== '0' && taskObj.typeId === 2 &&
                    <Box>
                        {dimensionsQuery.data.map((dim) => (
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <InputLabel id={"dim-multiple-chip-label" + dim.id}><FormattedMessage {...messages.dimension} /> {dim.name}</InputLabel>
                                <Select
                                    labelId={"dim-multiple-chip-label" + dim.id}
                                    id={"dim-multiple-chip" + dim.id}
                                    multiple
                                    value={taskObj.dimensionValuesList?.filter(x => x.taskDimensionId === dim.id).map(y => { return y.id }) || []}
                                    onChange={handleDimChange}
                                    name={dim.id}
                                    input={<OutlinedInput id={"select-multiple-chip-dim" + dim.id} label="Chip" />}
                                    autoComplete="off"
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={getDimensionValueName(value)} size='small' />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {/* {sortByName(dimensionsQuery.data.filter(x => x.Id === dim.id).values)?.map((t) => ( */}
                                    {dim.values?.map((t) => (
                                        <MenuItem
                                            key={t.id}
                                            value={t.id}
                                        >
                                            {t.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ))}
                    </Box>
                }
                {/* Linked Improvement/Incident */}
                {(!isNullOrUndefined(taskObj.incidentId)) &&
                    <Box margin="2px" backgroundColor={colors.primary[400]} marginTop="10px">
                        <Stack direction="row" display="flex" width="100%">
                            <Box width="100%" paddingTop="10px" paddingLeft="10px" ><FormattedMessage {...messages.incidentLink} /></Box>
                        </Stack>

                        <Stack spacing={{ xs: 0.5 }} direction="column" width="100%" paddingLeft="15px" paddingRight="15px" paddingBottom="10px">
                            <Box marginTop="2px">
                                <Stack direction="row" spacing={{ xs: 1 }} display="flex" width="100%">
                                    <Box width="80%">
                                        <Link onClick={() => { handleGotoTaskClick(taskObj.incidentId) }} underline="hover" target="_blank" rel="noopener noreferrer" color={colors.primary[100]}>
                                            {incidentQuery.data?.title}
                                        </Link>
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                }
                {/* Linked Improvement */}
                {taskObj.typeId === 1 &&
                    <Box margin="2px" backgroundColor={colors.primary[400]} marginTop="10px">
                        <Stack direction="row" display="flex" width="100%">
                            <Box width="70%" paddingTop="10px" paddingLeft="10px" ><FormattedMessage {...messages.improvementLink} /></Box>
                            {(taskObj.improvementId === null) &&
                                <Box display="flex" justifyContent="flex-end" width="30%">
                                    <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.add_linked_improvement} /></Typography>}>
                                        <IconButton onClick={() => handleCopyTask(taskObj.id)}> <AddLinkIcon /></IconButton>
                                    </Tooltip>
                                </Box>}
                        </Stack>

                        <Stack spacing={{ xs: 0.5 }} direction="column" width="100%" paddingLeft="15px" paddingRight="15px" paddingBottom="10px">
                            <Box marginTop="2px">
                                <Stack direction="row" spacing={{ xs: 1 }} display="flex" width="100%">
                                    <Box width="80%">
                                        <Link onClick={() => { handleGotoTaskClick(taskObj.improvementId) }} underline="hover" target="_blank" rel="noopener noreferrer" color={colors.primary[100]}>
                                            {improvementQuery.data?.title}
                                        </Link>
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                }
                {/* Subtasks */}
                {!(!isNullOrUndefined(taskObj.taskId) || id === '0') && taskObj.typeId === 2 &&
                    <Box margin="2px" backgroundColor={colors.primary[400]} marginTop="10px">
                        <Stack direction="row" display="flex" width="100%">
                            <Box width="70%" paddingTop="10px" paddingLeft="10px" ><FormattedMessage {...messages.subtasks} /></Box>
                            <Box display="flex" justifyContent="flex-end" width="30%">
                                {/* <IconButton color="#ff00ff" onClick={() => { navigate('/improvementFullPage/0/' + id) }}> <AddCircleOutlineOutlinedIcon /> </IconButton> */}
                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.add_subtasks} /></Typography>}>
                                    <IconButton onClick={() => navigate("/improvementFullPage/0/" + id + "?departmentId=" + taskObj.departmentId)}> <AddTaskIcon /></IconButton>
                                </Tooltip>
                            </Box>
                        </Stack>

                        <Stack spacing={{ xs: 0.5 }} direction="column" width="100%" paddingLeft="15px" paddingRight="15px" paddingBottom="10px">
                            {
                                subtaskQuery?.data?.map((s) => (
                                    <Box marginTop="2px">
                                        <Stack direction="row" spacing={{ xs: 1 }} display="flex" width="100%">
                                            <Box width="80%">
                                                <Link onClick={() => { handleGotoTaskClick(s.id) }} underline="hover" target="_blank" rel="noopener noreferrer" color={colors.primary[100]}>
                                                    {s.title}
                                                </Link>
                                            </Box>
                                            <Box justifyContent="flex-end" alignContent="center" display="flex" width="20%" backgroundColor={colors.primary[400]}>
                                                <Tooltip title={<Typography fontSize="12px">{getPersonFullName(s.responsibleId)}</Typography>}>
                                                    <Avatar alt="value" src={getPersonImg(s.responsibleId)} {...stringAvatar(getPersonFullName(s.responsibleId), 25)} />
                                                    {/* <Tooltip title={<Typography fontSize="12px">{params.row.responsible.firstName + " " + params.row.responsible.lastName}</Typography>}></Tooltip> */}
                                                </Tooltip>
                                            </Box>
                                            {/* <Box color={colors.primary[800]}>{getPersonFullName(s.responsibleId)}</Box> */}
                                        </Stack>
                                    </Box>
                                ))
                            }
                        </Stack>

                    </Box>}
                {id !== '0' &&
                    <Box margin="2px" backgroundColor={colors.primary[400]} marginTop="10px" >
                        <Stack direction="row" display="flex" width="100%">
                            <Box width="70%" paddingTop="10px" paddingLeft="10px" ><FormattedMessage {...messages.attachments} /></Box>
                            <Box display="flex" justifyContent="flex-end" width="30%">
                                {/* <IconButton color="#ff00ff" href={'./improvementAddAttachmentPage/' + id}> <EditOutlinedIcon /> </IconButton> */}
                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.add_attachments} /></Typography>}>
                                    <IconButton color="#ff00ff" onClick={() => { toggleOpenAttachments(true) }}> <AttachFileOutlinedIcon /> </IconButton>
                                </Tooltip>
                            </Box>
                        </Stack>

                        {/* <Grid container width="100%" spacing={0.5}> */}
                        <Stack spacing={{ xs: 0.5 }} direction="row" useFlexGap flexWrap="wrap" width="100%" paddingLeft="10px" paddingBottom="10px">
                            {attachmentsQuery?.data?.map((a) => {
                                return (
                                    // <Grid item xs={3} backgroundColor={colors.primary[600]} height="50px" marginRight="1px" marginTop="1px" marginLeft="1px" marginBottom="1px" justifyContent="center" alignItems="center" display="flex">
                                    <Box backgroundColor={colors.primary[600]} width="44px" height="44px" justifyContent="center" alignItems="center" display="flex">
                                        <Tooltip title={<Typography fontSize="12px">{getReadableFileName(a.fileName)}</Typography>}>
                                            <a href={"/api/taskattachments/opendocument?imageurl=" + encodeURIComponent(a.fileName) + "&type=" + a.fileType} target="_blank" rel="noopener noreferrer">
                                                {/* <RemoveRedEyeOutlinedIcon/> */}

                                                <img
                                                    src={isImageType(a.fileType) ? a.fileName : getDocIcon(a.fileName)}
                                                    alt={`Attached document`}
                                                    // height="40px"
                                                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                                                />
                                            </a>
                                        </Tooltip>
                                    </Box>
                                    // </Grid>
                                )
                            })}
                        </Stack>
                        {/* </Grid> */}
                    </Box>}
                {id !== '0' &&
                    <Dialog open={openAttachments} onClose={handleCloseAttachmentsDialog} maxWidth="md" >
                        <Box m={0} sx={{ backgroundColor: colors.primary[400], borderRadius: "4px" }} width="500px" >

                            <DialogTitle><FormattedMessage {...messages.add_delete_attachments} /></DialogTitle>
                            <DialogContent>
                                <ImprovementAddAttachment taskId={id} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseAttachmentsDialog}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#535560",
                                        },
                                        backgroundColor: colors.primary[300],
                                        color: "#000000",
                                        marginBottom: "15px",
                                    }}><FormattedMessage {...messages.close} /></Button>
                            </DialogActions>
                        </Box>
                    </Dialog>}
                {/* Links */}
                {id !== '0' &&
                    <Box margin="2px" backgroundColor={colors.primary[400]} marginTop="10px" >
                        <Stack direction="row" display="flex" width="100%">
                            <Box width="70%" paddingTop="10px" paddingLeft="10px" ><FormattedMessage {...messages.links} /></Box>
                            <Box display="flex" justifyContent="flex-end" width="30%" >
                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.add_links} /></Typography>}>
                                    <IconButton color="#ff00ff" onClick={() => { toggleOpenLinks(true) }}> <LinkIcon /> </IconButton>
                                </Tooltip>
                            </Box>
                        </Stack>

                        <Stack spacing={{ xs: 0.5 }} direction="column" width="100%" paddingLeft="15px" paddingBottom="10px">
                            {taskQuery?.data?.links?.map((a) => {
                                return (
                                    <Box backgroundColor={colors.primary[400]} width="100%" justifyContent="flex-start" alignItems="center" display="flex">
                                        <Link href={a.linkUrl} underline="hover" target="_blank" rel="noopener noreferrer" color={colors.primary[100]}>
                                            {a.description}
                                        </Link>
                                    </Box>
                                )
                            })}
                        </Stack>
                    </Box>}
                {id !== '0' &&
                    <Dialog open={openLinks} onClose={handleCloseLinksDialog} maxWidth="md" >
                        <Box m={0} sx={{ backgroundColor: colors.primary[400], borderRadius: "4px" }} width="500px" >

                            <DialogTitle><FormattedMessage {...messages.add_delete_links} /></DialogTitle>
                            <DialogContent>
                                <ImprovementAddLink taskId={id} links={taskQuery?.data?.links} task={taskQuery} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseLinksDialog}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#535560",
                                        },
                                        backgroundColor: colors.primary[300],
                                        color: "#000000",
                                        marginBottom: "15px",
                                        marginRight: "15px"
                                    }}><FormattedMessage {...messages.close} /></Button>
                            </DialogActions>
                        </Box>
                    </Dialog>}
            </Box>

            <Box gridColumn="span 4"></Box>
            <Box gridColumn="span 8">
                <Box width="100%" backgroundColor={colors.primary[400]} height="1px"></Box>
            </Box>
            <Box gridColumn="span 4"></Box>
            <Box gridColumn="span 6">
                <Stack direction="row" spacing={"auto"} alignItems="center" width="100%" marginTop="20px">
                    <Box>
                        {id !== '0' && <Box color={colors.primary[300]}>Created by: {getPersonFullName(taskObj.createdBy)} on {presentFullDateTime(taskObj.createdDate)}</Box>}
                        {id !== '0' && <Box color={colors.primary[300]}>{getUpdatedText()}</Box>}
                    </Box>
                    <Stack direction={"row"} spacing="10px">
                        <Button button onClick={() => navigate(-1)} sx={{
                            "&:hover": {
                                backgroundColor: colors.primary[400],
                            },
                            backgroundColor: colors.primary[300],
                            color: "#000000",
                        }}><FormattedMessage {...messages.close} /></Button>
                        <Box marginTop="20px">
                            <Controls.Button
                                type="submit"
                                text={<FormattedMessage {...messages.save} />}
                                onClick={handleSaveClick}
                                startIcon={<SaveIcon />}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: colors.dashbordGreen[700],
                                    },
                                    backgroundColor: colors.dashbordGreen[500],
                                    color: "#000000",
                                }}
                            />
                        </Box>
                        {id !== '0' &&
                            <Box marginTop="20px">
                                <Controls.Button
                                    type="submit"
                                    text={<FormattedMessage {...messages.delete} />}
                                    onClick={handleDeleteClick}
                                    startIcon={<SaveIcon />}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: colors.redAccent[700],
                                        },
                                        backgroundColor: colors.redAccent[500],
                                        color: "#000000",
                                    }}
                                />
                            </Box>}
                    </Stack>
                </Stack>

            </Box>
        </Box>
        <AxionConfirmation open={openConfirmation} title={confirmationTitle} message={confirmationMessage} onConfirm={doDeleteTask} onCancel={cancelDeleteTask} cancelText="No" confirmText="Yes" />
    </>;


    return (
        <>
            {content}

        </>
    )

}


export default ImprovementFull