import React from 'react'
import ImprovementAddDimensions from '../features/dimensions/improvementAddDimensions'


console.log("improvementAddDimensionsPage.jsx");

export default function improvementAddDimensionsPage() {
  return (
    <ImprovementAddDimensions/>
  )
}
