import React from 'react'
import ImprovementFull from '../features/ImprovementsNew/improvementFull'


console.log("ImprovementFullPage.jsx");

export default function ImprovementsPage() {
  return (
    <ImprovementFull/>
  )
}
