import React from 'react'
import ImprovementAddAttachment from '../features/ImprovementsNew/components/improvementAddAttachment'


console.log("ImprovementAddAttachmentPage.jsx");

export default function ImprovementAddAttachmentPage() {
  return (
    <ImprovementAddAttachment/>
  )
}
