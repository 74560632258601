import { defineMessages } from 'react-intl';

export const scope = 'axionimprovements.features.board';

export default defineMessages({

    all: {
        id: `${scope}.filter.labels.all`,
        defaultMessage: 'All',
    },
    department: {
        id: `${scope}.filter.labels.department`,
        defaultMessage: 'Department',
    },
    type: {
        id: `${scope}.filter.labels.type`,
        defaultMessage: 'Type',
    },
    quick_search: {
        id: `${scope}.filter.labels.quick_search`,
        defaultMessage: 'Quick search',
    },
    my_tasks: {
        id: `${scope}.filter.labels.my_tasks`,
        defaultMessage: 'Show my tasks',
    },
    clear_all_filters: {
        id: `${scope}.filter.labels.clear_all_filters`,
        defaultMessage: 'Clear allfilters',
    },
    target: {
        id: `${scope}.card.labels.target`,
        defaultMessage: 'Target',
    },
    days_due: {
        id: `${scope}.card.labels.days_due`,
        defaultMessage: 'Days due',
    },
    effort: {
        id: `${scope}.card.labels.effort`,
        defaultMessage: 'Effort',
    },
    edit: {
        id: `${scope}.card.labels.edit`,
        defaultMessage: 'Edit',
    },
    report_time: {
        id: `${scope}.card.labels.report_time`,
        defaultMessage: 'Report time',
    },
    nr_of_subtasks: {
        id: `${scope}.card.labels.nr_of_subtasks`,
        defaultMessage: 'Number of subtasks',
    },
    parent_task_is: {
        id: `${scope}.card.labels.parent_task_is`,
        defaultMessage: 'Parent task is',
    },
    close_and_archive: {
        id: `${scope}.card.labels.close_and_archive`,
        defaultMessage: 'Close and archive this incident',
    },
    status_new: {
        id: `${scope}.card.status.new`,
        defaultMessage: 'New',
    },
    status_Plan: {
        id: `${scope}.card.status.plan`,
        defaultMessage: 'Plan',
    },
    status_do: {
        id: `${scope}.card.status.do`,
        defaultMessage: 'Do',
    },
    status_check: {
        id: `${scope}.card.status.check`,
        defaultMessage: 'Check',
    },
    status_act: {
        id: `${scope}.card.status.act`,
        defaultMessage: 'Act',
    },
    status_archived: {
        id: `${scope}.card.status.archived`,
        defaultMessage: 'Archived',
    },
    close: {
        id: `${scope}.card.status.close`,
        defaultMessage: 'Close',
    },











 


});