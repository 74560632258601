import axios from "axios";

export const fetchCompanies = async () => {
    console.log("fetchCompanies");
    const response = await axios.get("api/companies");
    const companies = response.data;
    return companies;
}

export const fetchCompany = async (id) => {
    const response = await axios.get(`api/companies/${id}`);
    const company = response.data;
    return company;
}

export const updateCompany = async (company) => {
    const response = await axios.put(`api/companies/`, company);
    return response.data;
}