import axios from "axios";

export const getCategories = async (companyId) => {
    const response = await axios.get(`api/taskincidentcategories?companyId=${companyId}`);
    const result = response.data;
    return result;
}


// export const deleteLink = async (linkId) => {
//     const response = await axios.delete(`api/tasklinks/${linkId}`);
//     const result = response.data;
//     return result;
// }

// export const postLink = async (data) => {
//     const response = await axios.post("api/tasklinks", data);
//     const result = response.data;
//     return result;
// }