import React, { useState, useEffect, useMemo } from "react";
import { Button, useTheme, Box, IconButton, Stack, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, Typography } from "@mui/material";
import { tokens } from "../../assets/theme";
import { useQuery } from "@tanstack/react-query";
import { useLocalStorage } from "../../hooks/useLocalStorage";
//Components
import AxionControls from "../../components/controls/AxionControls";
import { pushErrorMessage } from "../../utils/notifications";
import AxionConfirmation from '../../components/controls/AxionConfirmation';
import Controls from "../../components/controls/Contols";
//Hooks
import { getDimensions, postDimension, patchDimension, deleteDimension, postDimensionValue, deleteDimensionValue, patchDimensionValue } from "../../fetchers/taskDimension";
//Icons
import SaveIcon from '@mui/icons-material/Save';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
//Language
import messages from "./messages";
import { FormattedMessage, useIntl } from "react-intl";


export default function ImprovementAddLink(props) {

    const { formatMessage } = useIntl();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
    const [newDimension, setNewDimension] = useState({ id: null, name: "", code: "", companyId: companyKey });
    const [newDimensionValue, setNewDimensionValue] = useState({ id: null, taskDimensionId: null, name: "", code: "", value: "" });

    const [showAddDimension, setShowAddDimension] = useState(true);
    const [showAddValue, setShowAddValue] = useState(false);

    const [openDimension, toggleOpenDimension] = React.useState(false);
    const [openValue, toggleOpenValue] = React.useState(false);

    const [openConfirmationValue, setOpenConfirmationValue] = useState(false);
    const [openConfirmationDimension, setOpenConfirmationDimension] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [valueId, setValueId] = useState(null);
    const [dimensionId, setDimensionId] = useState(null);


    const dimensionsQuery = useQuery(
        ["dimensions"],
        () => getDimensions(companyKey),
        {}
    );

    const handleCloseDimensionDialog = () => {
        setNewDimension({ id: null, name: "", code: "", companyId: companyKey });
        toggleOpenDimension(false);
    };

    const handleCloseValueDialog = () => {
        setNewDimensionValue({ id: null, taskDimensionId: null, name: "", code: "", value: "" });
        toggleOpenValue(false);
    };


    // const handleSubmitDimension = () => {
    //     //setNewDimensionValue({  taskDimensionId: null, name: "", code: "", value: ""  });
    // };

    // const handleSubmitValue = () => {
    //     //setNewDimensionValue({  taskDimensionId: null, name: "", code: "", value: ""  });
    // };


    const handleDeleteDimension = (id) => {
        setDimensionId(id);
        setConfirmationTitle(formatMessage(messages.delete_dimension));
        setConfirmationMessage(formatMessage(messages.sure_delete_dimension));
        setOpenConfirmationDimension(true);
    }

    const doDeleteDimension = () => {
        setConfirmationTitle("");
        setConfirmationMessage("");
        setOpenConfirmationDimension(false);
        deleteDimension(dimensionId).then(() => {
            dimensionsQuery.refetch();
        }).catch((error) => {
            console.log(error);
            //setShowBackdrop(false);
            pushErrorMessage(formatMessage(messages.could_not_delete_dim));
        })
    }

    const cancelDeleteDimension = () => {
        setDimensionId(null);
        setConfirmationTitle("");
        setConfirmationMessage("");
        setOpenConfirmationDimension(false);
    }

    const handleAddDimension = () => {

        if (newDimension.id !== null) {
            patchDimension(newDimension).then(() => {
                dimensionsQuery.refetch();
                handleCloseDimensionDialog();
            }).catch((error) => {
                console.log(error);
                //setShowBackdrop(false);
                pushErrorMessage(formatMessage(messages.could_not_update_dim));
            });
        } else {

            postDimension(newDimension).then(() => {
                dimensionsQuery.refetch();
                handleCloseDimensionDialog();
            }).catch((error) => {
                console.log(error);
                //setShowBackdrop(false);
                pushErrorMessage(formatMessage(messages.could_not_create_dim));
            });
        }
    }
    const handleDeleteDimensionValue = (id) => {
        setValueId(id);
        setConfirmationTitle(formatMessage(messages.delete_value));
        setConfirmationMessage(formatMessage(messages.sure_delete_value));
        setOpenConfirmationValue(true);
    }

    const doDeleteDimensionValue = () => {
        setOpenConfirmationValue(false);
        setConfirmationTitle("");
        setConfirmationMessage("");
        deleteDimensionValue(valueId).then(() => {
            dimensionsQuery.refetch();
            toggleOpenValue(false);
        }).catch((error) => {
            console.log(error);
            //setShowBackdrop(false);
            pushErrorMessage(formatMessage(messages.could_not_delete_value));
        })
    }

    const cancelDeleteDimensionValue = () => {
        setValueId(null);
        setConfirmationTitle("");
        setConfirmationMessage("");
        setOpenConfirmationValue(false);
    }

    const handleAddDimensionValue = () => {
        if (newDimensionValue.name === "") {
            pushErrorMessage(formatMessage(messages.name_must_contain_value) );
            return;
        }
        if (newDimensionValue.code === "") {
            pushErrorMessage(formatMessage(messages.code_must_contain_value) );
            return;
        }
        if (newDimensionValue.id !== null) {
            patchDimensionValue(newDimensionValue).then(() => {
                dimensionsQuery.refetch();
            }).catch((error) => {
                console.log(error);
                //setShowBackdrop(false);
                pushErrorMessage(formatMessage(messages.could_not_update_value));
            });
        } else {
            postDimensionValue(newDimensionValue).then(() => {
                dimensionsQuery.refetch();
            }).catch((error) => {
                console.log(error);
                //setShowBackdrop(false);
                pushErrorMessage(formatMessage(messages.could_not_create_value));   
            });
        }
    }

    const handlePrepareAddDimension = () => {

        toggleOpenDimension(true);
        setShowAddDimension(true);
        setShowAddValue(false);
    }

    const handleDimensionNameChange = (event) => {
        let dim = { ...newDimension };
        dim.name = event.target.value;

        setNewDimension(dim);
    }

    const handleDimensionCodeChange = (event) => {
        let dim = { ...newDimension };
        dim.code = event.target.value;

        setNewDimension(dim);
    }

    const handlePrepareAddValue = (dimensionId) => {
        toggleOpenValue(true);
        let dim = { ...newDimensionValue }
        dim.taskDimensionId = dimensionId;
        setNewDimensionValue(dim);

        setShowAddDimension(false);
        setShowAddValue(true);
    }

    const handleDimensionValueCodeChange = (event) => {
        let dim = { ...newDimensionValue };
        dim.code = event.target.value;

        setNewDimensionValue(dim);
    }

    const handleDimensionValueNameChange = (event) => {
        let dim = { ...newDimensionValue };
        dim.name = event.target.value;

        setNewDimensionValue(dim);
    }

    const handleDimensionValueValueChange = (event) => {
        let dim = { ...newDimensionValue };
        dim.value = event.target.value;

        setNewDimensionValue(dim);
    }

    const handleEditDimClick = (id) => {
        var dim = dimensionsQuery.data?.filter(d => d.id === id);

        var tempDim = { ...newDimension };
        tempDim.id = id;
        tempDim.name = dim[0].name;
        tempDim.code = dim[0].code;
        tempDim.companyId = dim[0].companyId;
        setNewDimension(tempDim);
        //setEditDim([{id: id, name: dim.name, code: dim.code}]);
        toggleOpenDimension(true);
    }
    const handleEditValueClick = (valueId, dimId) => {

        console.log("handleEditValueClick", valueId, dimId)
        console.log("dimensionsQuery", dimensionsQuery.data)
        var dim = dimensionsQuery.data?.filter(d => d.id === dimId);
        console.log("dim", dim);

        var val = dim[0].values?.filter(v => v.id === valueId);

        console.log("dim", dim);
        var tempValue = { ...newDimensionValue };
        tempValue.id = valueId;
        tempValue.taskDimensionId = dimId;
        tempValue.name = val[0].name;
        tempValue.code = val[0].code;
        tempValue.value = val[0].value;
        setNewDimensionValue(tempValue);

        toggleOpenValue(true);

    }

    function getDimensionName(dimensionId) {
        let data = dimensionsQuery.data?.filter(d => d.id === dimensionId);

        let result = data?.length > 0 ? data[0].name : '';
        return "Dimension: " + result;
    }

    //console.log("dimensions", dimensionsQuery.data)

    return (
        <>
            <Box padding="30px"> </Box>
            <Box width="50%">
                <Box width="100%" justifyContent="flex-start" alignItems="center" display="flex" marginTop="10px" marginBottom="20px" marginLeft="15px">
                    {dimensionsQuery.data?.length >= 4 && <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.maximum} /></Typography>}>
                        <span>
                            <Controls.Button

                                disabled
                                text={formatMessage(messages.add_dimension)}
                                startIcon={<AddCircleOutlinedIcon />}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: colors.dashbordGreen[700],
                                    },
                                    backgroundColor: colors.dashbordGreen[500],
                                    color: "#000000",
                                }}
                            />
                        </span>
                    </Tooltip>}
                    {dimensionsQuery.data?.length < 4 &&
                        <Controls.Button
                            disabled={dimensionsQuery.data?.length >= 4}
                            onClick={handlePrepareAddDimension}
                            text={formatMessage(messages.add_dimension)}
                            startIcon={<AddCircleOutlinedIcon />}
                            sx={{
                                "&:hover": {
                                    backgroundColor: colors.dashbordGreen[700],
                                },
                                backgroundColor: colors.dashbordGreen[500],
                                color: "#000000",
                            }}
                        />}
                </Box>
                {/* {(dimensionsQuery?.data?.length > 0) && <Box marginTop="20px" color="#ffffff" marginLeft="10px">Dimensions and values</Box>} */}
                <Stack direction="column" width="100%" overflow="hidden" backgroundColor={colors.primary[300]} marginTop="5px" color="#ffffff" spacing="1px" marginLeft="15px">
                    {dimensionsQuery?.data?.map((a) => {
                        return (
                            <>
                                <Stack direction="row" width="100%" backgroundColor={colors.primary[400]}>
                                    <Box width="70%" alignItems="center" justifyContent="flex-start" display="flex" marginLeft="10px" onClick={() => handleEditDimClick(a.id)}>{a.name} ({a.code})</Box>
                                    {/* <Box width="20%" alignItems="center" justifyContent="flex-start"display="flex" marginLeft="10px">{a.name}</Box> */}
                                    <Box width="30%" justifyContent="flex-end" alignItems="center" display="flex">
                                        <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.add_new_value} /> {a.name}</Typography>}>
                                            <IconButton onClick={() => { handlePrepareAddValue(a.id) }}><AddCircleOutlinedIcon /></IconButton>
                                        </Tooltip>
                                        <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.edit_dimension} /> {a.name}</Typography>}>
                                            <IconButton onClick={() => { handleEditDimClick(a.id) }}><EditOutlinedIcon /></IconButton>
                                        </Tooltip>
                                        <Tooltip title={<Typography fontSize="12px">{a.values.length > 0 ? <FormattedMessage {...messages.cannot_remove_dim} /> : <FormattedMessage {...messages.remove_dim} />}</Typography>}>
                                            <span>
                                                <IconButton onClick={() => { handleDeleteDimension(a.id) }} disabled={a.values.length > 0}><DeleteOutlinedIcon /></IconButton>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                </Stack>
                                {/* <Stack direction="row" backgroundColor={colors.primary[500]}><Box width="30%">Code</Box><Box width="30%">Name</Box><Box width="30%">Value</Box></Stack> */}


                                {a.values?.map((v) => {
                                    return (
                                        <Stack direction="row" backgroundColor={colors.primary[500]} width="100%" marginTop="5px">
                                            {/* <Box width="30%" display="flex" alignItems="center"></Box> */}
                                            <Box width="50%" display="flex" alignItems="center" marginLeft="25px" onClick={() => handleEditValueClick(v.id, v.taskDimensionId)}>{v.name} ({v.code})</Box>
                                            <Box width="40%" display="flex" alignItems="center">{v.value}</Box>
                                            <Box width="10%" display="flex" justifyContent="flex-end" alignItems="center">
                                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.edit_value} /> {v.name}</Typography>}>
                                                    <IconButton onClick={() => { handleEditValueClick(v.id, v.taskDimensionId) }}><EditOutlinedIcon /></IconButton>
                                                </Tooltip>
                                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.remove_value} /> {v.name}</Typography>}>
                                                    <IconButton onClick={() => { handleDeleteDimensionValue(v.id) }} ><DeleteOutlinedIcon /></IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Stack>
                                    )
                                })}
                            </>
                        )
                    })}
                </Stack>
            </Box>

            <Dialog open={openDimension} onClose={handleCloseDimensionDialog} m={0} width="400px">
                <Box m={0} sx={{ backgroundColor: colors.primary[400], borderRadius: "4px" }} width="400px" >
                    {/* <form onSubmit={handleAddDimension}> */}
                    <DialogTitle>{(newDimension.id === null) ? <FormattedMessage {...messages.add_a_new_dim} /> : <FormattedMessage {...messages.update_a_dim} />}</DialogTitle>
                    <DialogContent>

                        <Stack direction="column" width="100%" overflow="hidden" >
                            <Box><AxionControls.AxionTextBox
                                label="Name" //{<FormattedMessage {...messages.timezone} />}
                                value={newDimension.name}
                                autoComplete="new-password"
                                inputProps={{ autoComplete: 'new-password' }}
                                onChange={(event) =>
                                    handleDimensionNameChange(event)
                                }
                            /></Box>
                            <Box><AxionControls.AxionTextBox
                                label="Code" //{<FormattedMessage {...messages.timezone} />}
                                value={newDimension.code}
                                autoComplete="new-password"
                                inputProps={{ autoComplete: 'new-password' }}
                                onChange={(event) =>
                                    handleDimensionCodeChange(event)
                                }
                            /></Box>
                        </Stack>
                        <Stack direction="row" width="100%" overflow="hidden" marginTop="15px" display="flex" justifyContent="flex-end">
                            <Controls.Button
                                text="Close"
                                onClick={handleCloseDimensionDialog}
                                // startIcon={<SaveIcon />}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "#535560",
                                    },
                                    backgroundColor: colors.primary[300],
                                    color: "#000000",

                                }}
                            />
                            <Controls.Button
                                marginLeft="10px"
                                text={(newDimension.id === null) ? <FormattedMessage {...messages.add_dimension} /> : <FormattedMessage {...messages.update_dimension} />}
                                onClick={handleAddDimension}
                                startIcon={<SaveIcon />}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: colors.dashbordGreen[700],
                                    },
                                    backgroundColor: colors.dashbordGreen[500],
                                    color: "#000000",
                                    marginLeft: "10px"
                                }}
                            />
                        </Stack>
                    </DialogContent>
                </Box>
            </Dialog>

            <Dialog open={openValue} onClose={handleCloseValueDialog} m={0} width="400px">
                <Box m={0} sx={{ backgroundColor: colors.primary[400], borderRadius: "4px" }} width="400px" >
                    <DialogTitle>{(newDimensionValue.id === null) ? <FormattedMessage {...messages.add_a_new_dim_value} /> : <FormattedMessage {...messages.update_a_dim_value} />}</DialogTitle>
                    <DialogContent>

                        <Stack direction="column" width="100%" overflow="hidden" >
                            <Box color="#ffffff" backgroundColor={colors.primary[400]} padding="10px">{getDimensionName(newDimensionValue.taskDimensionId)}</Box>
                            <Box><AxionControls.AxionTextBox
                                label={formatMessage(messages.name)} 
                                value={newDimensionValue.name}
                                autoComplete="new-password"
                                inputProps={{ autoComplete: 'new-password' }}
                                onChange={(event) =>
                                    handleDimensionValueNameChange(event)
                                }
                            /></Box>
                            <Box><AxionControls.AxionTextBox
                                label={formatMessage(messages.code)}
                                value={newDimensionValue.code}
                                autoComplete="new-password"
                                inputProps={{ autoComplete: 'new-password' }}
                                onChange={(event) =>
                                    handleDimensionValueCodeChange(event)
                                }
                            /></Box>
                            <Box><AxionControls.AxionTextBox
                                label={formatMessage(messages.value)}
                                value={newDimensionValue.value}
                                autoComplete="new-password"
                                inputProps={{ autoComplete: 'new-password' }}
                                onChange={(event) =>
                                    handleDimensionValueValueChange(event)
                                }
                            /></Box>
                        </Stack>
                        <Stack direction="row" width="100%" overflow="hidden" marginTop="15px" display="flex" justifyContent="flex-end">
                            <Controls.Button
                                text={formatMessage(messages.close)}
                                onClick={handleCloseValueDialog}
                                // startIcon={<SaveIcon />}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "#535560",
                                    },
                                    backgroundColor: colors.primary[300],
                                    color: "#000000",

                                }}
                            />
                            <Controls.Button
                                marginLeft="10px"
                                text={(newDimensionValue.id === null) ? <FormattedMessage {...messages.add_value} /> : <FormattedMessage {...messages.update_value} />}
                                onClick={handleAddDimensionValue}
                                startIcon={<SaveIcon />}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: colors.dashbordGreen[700],
                                    },
                                    backgroundColor: colors.dashbordGreen[500],
                                    color: "#000000",
                                    marginLeft: "10px"
                                }}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>

                    </DialogActions>
                </Box>
            </Dialog>
            <AxionConfirmation open={openConfirmationDimension} title={confirmationTitle} message={confirmationMessage} onConfirm={doDeleteDimension} onCancel={cancelDeleteDimension} cancelText={formatMessage(messages.no)} confirmText={formatMessage(messages.yes)} />
            <AxionConfirmation open={openConfirmationValue} title={confirmationTitle} message={confirmationMessage} onConfirm={doDeleteDimensionValue} onCancel={cancelDeleteDimensionValue} cancelText={formatMessage(messages.no)} confirmText={formatMessage(messages.yes)} />



        </>

    )

}