import React from "react";
import { useState, useEffect } from "react";

import { useQuery } from "@tanstack/react-query";
import { Box, Paper, useTheme, Stack, Chip, IconButton, Tooltip, Button, Typography, Icon, Grid, Link, CircularProgress } from '@mui/material';
import { tokens } from "../../assets/theme";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import messages from "../components/messages";
import AxionControls from "../../components/controls/AxionControls";
import { pushErrorMessage } from "../../utils/notifications";

import Avatar from '@mui/material/Avatar';
import Controls from "../../components/controls/Contols";
import { useMsal, useAccount } from "@azure/msal-react";
import dayjs from "dayjs";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { blue } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import ImprovementAddTimeWithoutData from '../ImprovementsNew/components/improvementAddTimeWithoutData';
// Hooks
import { getAllTasks, patchTask } from "../../fetchers/task";
import { getPersons } from "../../fetchers/person";
import { getDepartments } from "../../fetchers/departments";
import { getTags } from "../../fetchers/taskTags";
import { getDimensions } from "../../fetchers/taskDimension";
//Icons
//import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import StarRateOutlinedIcon from '@mui/icons-material/StarRateOutlined';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
//import CircleIcon from '@mui/icons-material/Circle';
//import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
//import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
//import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
//import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { ModeCommentOutlined } from "@mui/icons-material";
//Language
import messages from "./messages";
import { FormattedMessage, useIntl } from "react-intl";






const ImprovementsListLanes = () => {

    console.log("ImprovementsListLanes.jsx");

    const { formatMessage } = useIntl();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [departmentId, setDepartmentId] = useLocalStorage("ImprovementsDepartmentId", 0);
    const [tagId, setTagId] = useLocalStorage("ImprovementsTagId", 0);
    const [personId, setPersonId] = useLocalStorage("ImprovementsPersonId", 0);
    const [typeId, setTypeId] = useLocalStorage("ImprovementsTypeId", 0);
    const [dimensionIds, setDimensionIds] = useLocalStorage("ImprovementsDimensionIds", null);

    const [openNew, toggleOpenNew] = useLocalStorage("ImprovementsOpenNew", false);
    const [openArchived, toggleOpenArchived] = useLocalStorage("ImprovementsOpenArchived", false);
    const [openTimes, toggleOpenTimes] = React.useState(false);
    const [openTimesForId, setOpenTimesForId] = React.useState(0);
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);

    const personQuery = useQuery(
        ["persons"],
        () => getPersons(),
        {}
    );
    // const personData = personQuery?.data;
    const departmentsQuery = useQuery(
        ["departments"],
        () => getDepartments(companyKey),
        {}
    );
    //const departmentData = departmentsQuery?.data;
    const tagsQuery = useQuery(
        ["tags"],
        () => getTags(companyKey),
        {}
    );
    const taskQuery = useQuery(
        ["task"],
        () => getAllTasks(companyKey),
        {
            // staleTime: 60000,
            // refetchInterval: 60000
            enabled: !!personQuery.data && !!departmentsQuery.data && !!tagsQuery.data
        }
    );
    const dimensionsQuery = useQuery(
        ["dimensions"],
        () => getDimensions(companyKey),
        {}
    );

    const handleCloseTimesDialog = () => {
        taskQuery.refetch();
        toggleOpenTimes(false);
    };
    const handleOpenTimesDialog = (id) => {
        setOpenTimesForId(id);
        toggleOpenTimes(true);
    };

    const handleMoveForwardClick = (taskId, statusId) => {

        //!!!!!!!!!!!!!!!
        let newStatusId = 0;
        if (statusId === 0) {
            newStatusId = 1;
        }
        if (statusId === 1) {
            newStatusId = 2;
        }
        if (statusId === 2) {
            newStatusId = 3;
        }
        if (statusId === 3) {
            newStatusId = 4;
        }
        if (statusId === 4) {
            newStatusId = 5;
        }

        const moveObj = { taskId: taskId, statusId: newStatusId };

        patchTask(moveObj).then(() => {
            taskQuery.refetch();
            //doSearch(searchValue);
        })
            .catch((error) => {
                console.log(error);
                //props.setShowBackdrop(false);
                // pushErrorMessage(`Could not save the task.`);
            });

    }

    const handleMoveBackClick = (taskId, statusId) => {

        //!!!!!!!!!!!!!!!
        let newStatusId = 0;
        if (statusId === 1) {
            newStatusId = 0;
        }
        if (statusId === 2) {
            newStatusId = 1;
        }
        if (statusId === 3) {
            newStatusId = 2;
        }
        if (statusId === 4) {
            newStatusId = 3;
        }
        if (statusId === 5) {
            newStatusId = 4;
        }

        const moveObj = { taskId: taskId, statusId: newStatusId };

        patchTask(moveObj).then(() => {
            taskQuery.refetch();
            //doSearch(searchValue);
        })
            .catch((error) => {
                console.log(error);
                //props.setShowBackdrop(false);
                // pushErrorMessage(`Could not save the task.`);
            });
    }

    const handleTagChange = (event) => {
        setTagId(event.target.value);
        doFilterAll(departmentId, event.target.value, personId, typeId, searchValue, dimensionIds);
    };
    const handleDimensionChange = (event) => {

        let valueId = event.target.value;
        let dimensionId = event.target.name;

        // Build current selected value obj
        let dimValue = { id: valueId, dimensionId: dimensionId };

        //Remove search value for current dimension
        let valFiltered = dimensionIds?.filter(x => x.dimensionId !== dimensionId)

        valFiltered.push(dimValue);

        setDimensionIds(valFiltered);
        doFilterAll(departmentId, tagId, personId, typeId, searchValue, valFiltered);
    };
    const handleDepartmentChange = (event) => {
        setDepartmentId(event.target.value);
        doFilterAll(event.target.value, tagId, personId, typeId, searchValue, dimensionIds);
    };

    const handleTypeChange = (event) => {
        setTypeId(event.target.value);
        doFilterAll(departmentId, tagId, personId, event.target.value, searchValue, dimensionIds);
    };

    const handlePersonChange = (event) => {
        setPersonId(event.target.value);
        doFilterAll(departmentId, tagId, event.target.value, typeId, searchValue, dimensionIds);
    };

    function getColumnsSize() {
        if (openNew && openArchived) { return "repeat(12, 1fr)"; }
        if (openNew || openArchived) { return "repeat(10, 1fr)"; }
        return "repeat(8, 1fr)";
    }

    function getDimensionInitFilter() {
        return dimensionsQuery.data.map(x => { return { id: 0, dimensionId: x.id } });
    }

    function clearAllFilters() {
        setDepartmentId(0);
        setTagId(0);
        setPersonId(0);
        setSearchValue("");
        setTypeId(0);
        //setShowChildrenFor(0);
        setDimensionIds(getDimensionInitFilter());
    }

    function handleResetFiltering() {
        clearAllFilters();
        let dimensionInitFilter = getDimensionInitFilter()
        doFilterAll(0, 0, 0, 0, '', dimensionInitFilter);
    }

    function doFilterAll(dId, tId, pId, typeId, sValue, dimIds) {

        //let result = taskQuery.data.filter(dataRow => dataRow.taskId === null);
        let result = taskQuery.data;

        if (dId !== 0) {
            result = result.filter(dataRow => {
                return dataRow.departmentId === dId;
            });
        }
        if (pId !== 0) {
            result = result.filter(dataRow => {
                //missing participants
                return dataRow.participants.some(p => p.id === pId) || dataRow.responsibleId === pId;

            });
        }
        if (tId !== 0) {
            result = result.filter(dataRow => {
                return dataRow.tags.some(tag => tag.id === tId);
            });
        }
        if (typeId !== 0) {
            result = result.filter(dataRow => {
                return (dataRow.type?.id === typeId);
            });
        }
        //check if any dimension selected
        if (dimIds.some(x => x.id !== 0)) {
            let valueIds = dimIds.map(x => x.id);

            result = result.filter(dataRow => {
                return dataRow.dimensionValues.some(x => valueIds.includes(x.id));// valueIds.includes(x.id)
            });
        }
        if (sValue?.length > 2) {
            result = result.filter(dataRow => {
                return searchObjectsWithValue(dataRow, sValue);
            });
        }

        setFilteredData(result);
    }

    const handleSearchChange = (event) => {
        doSearch(event.target.value);
    }

    const handleSearchMe = () => {
        //doSearch(account.username);
        let id = 0;
        if (personId === 0) {
            id = getPersonIdByEmail(account.username);
        }
        setPersonId(id);
        doFilterAll(departmentId, tagId, id, typeId, searchValue, dimensionIds);
    }

    const clearSearchValue = () => {
        //doSearch("");
        setSearchValue("");
        doFilterAll(departmentId, tagId, personId, typeId, "", dimensionIds);
    }

    const doSearch = (value) => {
        setSearchValue(value);
        if (value?.length > 2) {
            doFilterAll(departmentId, tagId, personId, typeId, value, dimensionIds);
        } else {
            doFilterAll(departmentId, tagId, personId, typeId, "", dimensionIds);
        }
    }

    function searchObjectsWithValue(dataRow, value) {

        if (dataRow === null || dataRow === undefined) {
            return false;
        }

        let done = false;
        value = value.toLowerCase();

        if (dataRow.title?.toLowerCase().includes(value)) { return true; }
        if (dataRow.description?.toLowerCase().includes(value)) { return true; }
        if (formatDate(dataRow.targetDate).toLowerCase().includes(value)) { return true; }
        if (formatDate(dataRow.startDate).toLowerCase().includes(value)) { return true; }
        if (formatDate(dataRow.endDate).toLowerCase().includes(value)) { return true; }
        if (formatDate(dataRow.effort).toLowerCase().includes(value)) { return true; }
        if (dataRow.responsible?.firstName?.toLowerCase().includes(value)) { return true; }
        //if (dataRow.responsible?.lastName?.toLowerCase().includes(value)) { return true; }
        if (dataRow.responsible?.email?.toLowerCase().includes(value)) { return true; }
        // if (dataRow.responsible?.capacity.toLowerCase().includes(value)) { return true; }
        if (dataRow.department?.name?.toLowerCase().includes(value)) { return true; }
        if (dataRow.group?.title?.toLowerCase().includes(value)) { return true; }
        if (dataRow.type?.title?.toLowerCase().includes(value)) { return true; }
        if (dataRow.status?.description?.toLowerCase().includes(value)) { return true; }
        // if (dataRow.status?.color.includes(value)) { return dataRow; }
        dataRow.comments?.forEach(comment => {
            if (comment.commentText?.toLowerCase().includes(value)) { done = true; }
        });
        if (done === true) { return true; }
        dataRow.tags?.forEach(tag => {
            if (tag.title?.toLowerCase().includes(value)) { done = true; }
        });
        if (done === true) { return true; }
        dataRow.participants?.forEach(p => {
            if (p.employeeNumber?.toLowerCase().includes(value)) { done = true; }
            if (p.firstName?.toLowerCase().includes(value)) { done = true; }
            //if (p.lastName?.toLowerCase().includes(value)) { done = true; }
            if (p.email?.toLowerCase().includes(value)) { done = true; }
        });
        if (done === true) { return true; }

        return false;
    }

    function formatDate(date) {
        return date ? new Date(date).toLocaleDateString('sv-SE') : "";
    }
    function formatDateLocalized(date) {
        let locale = companyKey === 2 ? 'en-US' : 'sv-SE';
        return date ? new Date(date).toLocaleDateString(locale) : "";
    }
    function getPersonImageByEmail(email) {
        let person = personQuery.data?.find(p => p.email.toLowerCase() === email.toLowerCase());
        return person?.image;
    }
    function getPersonIdByEmail(email) {
        let person = personQuery.data.find(p => p.email.toLowerCase() === email.toLowerCase());
        return person?.id;
    }
    function getPersonFullNameByEmail(email) {
        let person = personQuery.data.find(p => p.email.toLowerCase() === email.toLowerCase());
        return person?.firstName;
    }
    function getPersonImg(personId) {
        //console.log("getPersonImg:" + personId);
        let person = personQuery.data.find(p => p.id === personId);
        let img = person?.imageBase64;
        if (img === null || img === undefined || img === "") {
            return "";
        }
        return `data:;base64,${img}`;
    }
    function getPersonFullName(personId) {
        let person = personQuery.data.find(p => p.id === personId);
        if (person === undefined) {
            return "";
        }
        return person?.firstName;// + " " + person?.lastName;
    }
    function getDaysDue(date, message) {
        let d = dayjs(date);
        let diff = d.diff(dayjs(), "day");
        return isNaN(diff) ? "" : " - " + formatMessage(messages.days_due) + ": " + diff;
    }
    function getDepartmentText(dep) {
        if (dep !== "" && dep !== null && dep !== undefined) {
            return formatMessage(messages.department) + ": " + dep;
        }
    }
    function getTotalTime(obj) {
        let total = 0;
        obj.reportedTimes?.forEach((a) => {
            total += a.reportedTime;
        });
        return Math.round(total);
    }
    function isNull0(value) {
        return value === null ? 0 : value;
    }
    function getEffortColor(effort, estimated) {
        return isNull0(effort) > isNull0(estimated) ? '#e8032e' : ''
    }

    function getSubtasksCount(taskId) {
        let count = taskQuery.data?.filter((s) => s.taskId === taskId).length;
        return count;
    }

    function getParentName(parentId) {
        return taskQuery.data?.find((s) => s.id === parentId)?.title;
    }

    function getTargetDateColor(targetDate) {
        let target = formatDate(targetDate);
        let now = formatDate(Date.now());

        let d = dayjs(targetDate);
        let diff = d.diff(dayjs(), "day");

        let resultColor = target > now ? "#47aa29" : "#e8032e";
        if (diff < 7 && diff > 0) {
            resultColor = "#ff9800";
        }

        return resultColor;
    }

    function getCardColor(parent) {
        if (parent === null) {
            return `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})`;
        } else {
            return `linear-gradient(to right bottom, ${colors.primary[500]}, ${colors.primary[600]})`;
        }
    }

    function trimRight2Chars(str) {
        var result = "";
        str.map((s, index) => {
            if (index < str.length - 1) {
                result += s + ", ";
            } else {
                result += s;
            }

        });
        return result;
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name, boxSize) {
        if (name === undefined || name === null || name === "" || name.split(' ').length < 2) {
            name = "N N";
        }
        return {
            sx: {
                bgcolor: stringToColor(name),
                border: 1, 
                height: boxSize,
                width: boxSize,
                borderColor: "#000000"            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    function stringAvatarBorder(name, boxSize) {
        if (name === undefined || name === null || name === "" || name.split(' ').length < 2) {
            name = "N N";
        }
        return {
            sx: {
                bgcolor: stringToColor(name),
                border: 1,                 
                height: boxSize,
                width: boxSize,
                borderColor: (personId === 0) ? "#000000" : "#aaaaaa" 
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    

    useEffect(() => {
        if (taskQuery.data) {
            doFilterAll(departmentId, tagId, personId, typeId, searchValue, dimensionIds);
        }
    }, [taskQuery.data]);

    useEffect(() => {
        if (departmentsQuery.data) {
            departmentsQuery.data.sort((a, b) => a.name.localeCompare(b.name));
        }
    }, [departmentsQuery.data]);

    useEffect(() => {
        if (tagsQuery.data) {
            tagsQuery.data.sort((a, b) => a.title.localeCompare(b.title));
        }
    }, [tagsQuery.data]);

    useEffect(() => {
        if (personQuery.data) {
            personQuery.data.sort((a, b) => a.firstName.localeCompare(b.firstName));
        }
    }, [personQuery.data]);

    useEffect(() => {
        if (dimensionsQuery.data) {
            if (dimensionIds === null) {
                let init = dimensionsQuery.data.map(x => { return { id: 0, dimensionId: x.id } });
                setDimensionIds(init);
            }
        }
    }, [dimensionsQuery.data]);

    let spinner = <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        }}
    >
        <CircularProgress color="secondary" />
    </div>

    if (taskQuery.isFetching) {
        // return <Box color="white">Loading Tasks...</Box>;  
        return spinner;
    }
    if (departmentsQuery.isFetching) {
        // return <Box color="white">Loading Departments...</Box>;
        return spinner;
    }
    if (personQuery.isFetching) {
        // return <Box color="white">Loading Persons...</Box>;
        return spinner;
    }
    if (tagsQuery.isFetching) {
        // return <Box color="white">Loading Tags...</Box>;
        return spinner;
    }
    if (dimensionsQuery.isFetching) {
        // return <Box color="white">Loading Dimensions...</Box>;
        return spinner;
    }

    //setFilteredData(taskQuery.data)

    //console.log("taskQuery", taskQuery?.data);

    let redGradient = `linear-gradient(to right bottom, ${colors.redAccent[500]}, ${colors.redAccent[700]})`;
    let yellowGradient = `linear-gradient(to right bottom, ${colors.yellow[500]}, ${colors.yellow[700]})`;
    let greenGradient = `linear-gradient(to right bottom, ${colors.dashbordGreen[600]}, ${colors.dashbordGreen[800]})`;
    let blueAccentGradient = `linear-gradient(to right bottom, ${colors.blueAccent[600]}, ${colors.blueAccent[800]})`;
    let blueGradient = `linear-gradient(to right bottom, ${blue[700]}, ${blue[900]})`;

    let greenGradientDown = `linear-gradient(to bottom, ${colors.dashbordGreen[600]}, ${colors.primary[700]})`;

    let status0Filtered = filteredData?.filter((s) => s.status.value === 0);
    let status25Filtered = filteredData?.filter((s) => s.status.value === 25);
    let status50Filtered = filteredData?.filter((s) => s.status.value === 50);
    let status75Filtered = filteredData?.filter((s) => s.status.value === 75);
    let status100Filtered = filteredData?.filter((s) => s.status.value === 100);
    let status125Filtered = filteredData?.filter((s) => s.status.value === 125);

    let status0Total = taskQuery.data?.filter((s) => s.status.value === 0);
    let status25Total = taskQuery.data?.filter((s) => s.status.value === 25);
    let status50Total = taskQuery.data?.filter((s) => s.status.value === 50);
    let status75Total = taskQuery.data?.filter((s) => s.status.value === 75);
    let status100Total = taskQuery.data?.filter((s) => s.status.value === 100);
    let status125Total = taskQuery.data?.filter((s) => s.status.value === 125);

    // Now showing all tasks, subtasks included
    // let status0Total = taskQuery.data?.filter((s) => s.status.value === 0 && s.taskId === null);
    // let status25Total = taskQuery.data?.filter((s) => s.status.value === 25 && s.taskId === null);
    // let status50Total = taskQuery.data?.filter((s) => s.status.value === 50 && s.taskId === null);
    // let status75Total = taskQuery.data?.filter((s) => s.status.value === 75 && s.taskId === null);
    // let status100Total = taskQuery.data?.filter((s) => s.status.value === 100 && s.taskId === null);
    // let status125Total = taskQuery.data?.filter((s) => s.status.value === 125 && s.taskId === null);




    ///####
    const styles = {
        typography: {
            color: "#ffffff",
            fontSize: 13,
            position: "relative",
            top: "0px",
            left: "18px"
        }
    };


    //console.log("taskQuery", taskQuery.data);

    let content = <>
        <Stack direction="row" paddingRight={2} spacing={"auto"} alignItems="center" width="100%">
            <Stack direction="row" paddingTop={0} spacing={"auto"} alignItems="center" justifyItems="center">
                <Box width="50px" p={1}>
                    <IconButton onClick={() => navigate("/improvementFullPage/0")}> <AddCircleOutlinedIcon /></IconButton>
                </Box>

                <Box p={1}>
                    <FormControl sx={{ minWidth: '100px' }}>
                        <InputLabel id="department-label"><FormattedMessage {...messages.department} /></InputLabel>
                        <Select
                            labelId="department-label"
                            id="department-select"
                            value={departmentId}
                            label="olle"
                            onChange={handleDepartmentChange}
                        // MenuProps={MenuProps}
                        >
                            {/* <MenuItem value={1}>Department 1</MenuItem>
                            <MenuItem value={2}>Department 2</MenuItem> */}
                            <MenuItem key={0} value={0}><FormattedMessage {...messages.all} /></MenuItem>
                            {departmentsQuery.data.map((d) => (
                                <MenuItem
                                    key={d.id}
                                    value={d.id}
                                //   style={getStyles(name, personName, theme)}
                                >
                                    {d.name}
                                    {/* <Avatar alt="value" src={"../../assets/" + p.image} /><Box paddingLeft="7px">{p.firstName + " " + p.lastName}</Box> */}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl sx={{ minWidth: '100px' }}>
                        <InputLabel id="type-label"><FormattedMessage {...messages.type} /></InputLabel>
                        <Select
                            labelId="type-label"
                            id="type-select"
                            value={typeId}
                            label="Type"
                            onChange={handleTypeChange}
                        // MenuProps={MenuProps}
                        >
                            {/* <MenuItem value={1}>Department 1</MenuItem>
                            <MenuItem value={2}>Department 2</MenuItem> */}
                            <MenuItem key={0} value={0}><FormattedMessage {...messages.all} /></MenuItem>
                            <MenuItem key={1} value={1}><BoltOutlinedIcon fontSize="small" /> Incident</MenuItem>
                            <MenuItem key={2} value={2}><StarRateOutlinedIcon fontSize="small" />Improvement</MenuItem>
                            {/* {typesQuery.data.map((d) => (
                                    <MenuItem
                                        key={d.id}
                                        value={d.id}
                                    >
                                        {d.name}
                                    </MenuItem>
                                ))} */}
                        </Select>
                    </FormControl>
                </Box>
                <Box p={1}>
                    <FormControl sx={{ minWidth: '100px' }}>
                        <InputLabel id="tags-label">Tag</InputLabel>
                        <Select
                            labelId="tags-label"
                            id="tags-select"
                            value={tagId}
                            label="olle"
                            onChange={handleTagChange}
                        // MenuProps={MenuProps}
                        >
                            {/* <MenuItem value={1}>Department 1</MenuItem>
                            <MenuItem value={2}>Department 2</MenuItem> */}
                            <MenuItem key={0} value={0}><FormattedMessage {...messages.all} /></MenuItem>
                            {tagsQuery.data.map((d) => (
                                <MenuItem
                                    key={d.id}
                                    value={d.id}
                                //   style={getStyles(name, personName, theme)}
                                >
                                    {d.title}
                                    {/* <Avatar alt="value" src={"../../assets/" + p.image} /><Box paddingLeft="7px">{p.firstName + " " + p.lastName}</Box> */}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box p={0}>
                    <FormControl sx={{ minWidth: '100px' }}>
                        <InputLabel id="person-label">Person</InputLabel>
                        <Select
                            labelId="person-label"
                            id="person-select"
                            value={personId}
                            label="olle"
                            onChange={handlePersonChange}
                        // MenuProps={MenuProps}
                        >
                            {/* <MenuItem value={1}>Department 1</MenuItem>
                            <MenuItem value={2}>Department 2</MenuItem> */}
                            <MenuItem key={0} value={0}><FormattedMessage {...messages.all} /></MenuItem>
                            {personQuery.data.filter(p => p.hide !== true).map((d) => (
                                <MenuItem
                                    key={d.id}
                                    value={d.id}
                                //   style={getStyles(name, personName, theme)}
                                >
                                    {d.firstName}
                                    {/* <Avatar alt="value" src={"../../assets/" + p.image} /><Box paddingLeft="7px">{p.firstName + " " + p.lastName}</Box> */}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                {dimensionsQuery.data.map((dim) => (
                    <Box p={1}>
                        <FormControl sx={{ minWidth: '100px' }}>
                            <InputLabel id="tags-label">{dim.name}</InputLabel>
                            <Select
                                labelId="tags-label"
                                id="tags-select"
                                value={dimensionIds.filter(x => x.dimensionId === dim.id).map(y => { return y.id }) || ''}
                                label="Dimension"
                                name={dim.id}
                                onChange={handleDimensionChange}
                            // MenuProps={MenuProps}
                            >
                                <MenuItem key={0} value={0}><FormattedMessage {...messages.all} /></MenuItem>
                                {dim?.values.map(v => {
                                    return (
                                        <MenuItem
                                            key={v.id}
                                            value={v.id}
                                        //   style={getStyles(name, personName, theme)}
                                        >
                                            {v.name}
                                        </MenuItem>
                                    )
                                })}

                            </Select>
                        </FormControl>
                    </Box>
                ))}
                <Box>
                    <Controls.Input
                        name="searchList"
                        label={formatMessage(messages.quick_search)}
                        value={searchValue}
                        onChange={handleSearchChange}
                        //error={errors.name}
                        sx={{ marginLeft: '10px' }}
                        fullWidth={true}
                        autoComplete="new-password"
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => clearSearchValue("")}>
                                    {searchValue.length > 0 ? <ClearOutlinedIcon /> : ''}
                                </IconButton>
                            )
                        }}
                    />
                </Box>
                <Box width="70px" p={1}>
                    <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.my_tasks} /></Typography>}>
                        <Button onClick={handleSearchMe} size="sm">
                            <Avatar  alt="value" src={getPersonImg(getPersonIdByEmail(account.username))} {...stringAvatarBorder(getPersonFullNameByEmail(account.username), 30)}/>
                        </Button>
                    </Tooltip>
                </Box>
                <Box width="50px" p={1}>
                    <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.clear_all_filters} /></Typography>}>
                        <IconButton onClick={() => handleResetFiltering()}> <FilterAltOffIcon /></IconButton>
                    </Tooltip>
                </Box>
            </Stack>
            {/* ... */}
            <Stack direction="row" padding={2} spacing={"auto"} alignItems="center">
                <Box width="30px" p={1}>
                    <IconButton onClick={() => navigate("/improvementsListPage")}> <ReorderOutlinedIcon /></IconButton>
                </Box>
                <Box width="30px" p={1}>
                    <IconButton disabled onClick={() => navigate("/improvementsListLanePage")}> <AppsOutlinedIcon /></IconButton>
                </Box>
                <Box width="30px" p={1}>
                    <IconButton disabled onClick={() => navigate("/improvementsListLanePage")}> <SortOutlinedIcon /></IconButton>
                </Box>
            </Stack>
        </Stack>

        <Stack direction="row">
            {/* sx={{ background: greenGradientDown }} */}
            {!openNew && <Stack boxShadow={12} sx={{ background: `linear-gradient(to bottom, ${colors.primary[400]}, ${colors.primary[700]})` }} direction="column" display="flex" minHeight="300px" width="40px" justifyContent="flex-start" alignContent="center" marginLeft="5px">
                <IconButton color="#ff00ff" onClick={() => { toggleOpenNew(true) }}> <KeyboardDoubleArrowRightOutlinedIcon /> </IconButton>
                <Box display="flex" justifyContent="center">
                    <Typography sx={{ textOrientation: 'sideways', writingMode: 'vertical-lr' }} paddingTop="10px" color="#ffffff"><FormattedMessage {...messages.status_new} />{status0Filtered.length}/{status0Total.length}</Typography>
                </Box>
            </Stack>}

            <Box height="500px"
                width={"100%"}
                display="grid"
                gridTemplateColumns={getColumnsSize()}
                //gridAutoRows="100px"
                gap="20px"
                color="#ffffff"
                spadding={2}
                marginLeft="10px"
            >

                {openNew &&
                    <>
                        <Box gridColumn="span 2" >
                            <Stack p={1} boxShadow={12} sx={{ background: greenGradient }} direction="row" display="flex" width="100%" alignContent="center" height="37px">
                                <Box width="50%" display="flex" alignContent="center"><IconButton color="#ff00ff" onClick={() => { toggleOpenNew(false) }}> <KeyboardDoubleArrowLeftOutlinedIcon /> </IconButton> New</Box>
                                <Box width="50%" display="flex" justifyContent="flex-end" >{status0Filtered?.length || '0'}/{status0Total?.length || '0'}</Box>
                            </Stack>

                            <Stack direction="column" spacing={"10px"} paddingTop={"10px"}>
                                {/* {filteredData?.filter((s) => s.status.id === 1).map((task) => */}
                                {status0Filtered.map((task) =>
                                    <Box height="100%" boxShadow={12} p={0} sx={{ background: getCardColor(task.taskId) }}>
                                        {/* <Typography variant="h2" color="#ffffff">{task.title}hhh</Typography> */}
                                        <Grid container alignItems="flex-start" display="flex" width="100%" minHeight="175px" direction="row">
                                            <Grid item width="1%" sx={{ backgroundColor: getTargetDateColor(task.targetDate) }} minHeight="175px">
                                                {/* {formatDate(task.targetDate) > formatDate(Date.now()) ? <img src="../../assets/dot_green.gif" alt="" height="160px" width="3px" /> : <img src="../../assets/dot_red.gif" alt="" width="3px" height="160px" />} */}
                                            </Grid>
                                            <Stack item width="79%" height="100%" p={1} spacing="2px" alignItems="flex-start" justifyContent="space-between" display="flex" direction="column" >
                                                <Stack direction="row" display="flex" alignItems="flex-start" justifyContent="flex-start" marginBottom="15px">
                                                    {(task.type?.id === 1) ? <BoltOutlinedIcon fontSize="small" /> : (task.type?.id === 2) ? <StarRateOutlinedIcon fontSize="small" /> : ""}
                                                    <Box><Typography fontSize="10pt">{task.title}</Typography></Box>
                                                </Stack>

                                                <Box color={colors.primary}><Typography fontSize="9pt"><FormattedMessage {...messages.target} />: {formatDateLocalized(task.targetDate)} {getDaysDue(task.targetDate)}</Typography></Box>
                                                <Stack color={colors.primary} direction="row"><Typography fontSize="9pt" marginRight="3px"><FormattedMessage {...messages.effort} />: </Typography><Typography fontSize="9pt" color={getEffortColor(getTotalTime(task), task.estimatedEffort)}>{isNull0(getTotalTime(task))}/{isNull0(task.estimatedEffort)}</Typography></Stack>
                                                <Box color={colors.primary}><Typography fontSize="9pt"><FormattedMessage {...messages.department} />: {task.department?.name}</Typography></Box>
                                                <Box color={colors.primary}><Typography fontSize="9pt">Dim:&nbsp;{trimRight2Chars(task.dimensionValues.map((d) => d.code))}</Typography></Box>
                                                <Stack direction="row" spacing={"5px"} alignItems="baseline" hight="35px">
                                                    {task.tags.map((tag) => <Chip size="small" label={tag.title} variant="outlined" color="default" />)}
                                                    <Box width="0px" height="18px"></Box>
                                                </Stack>
                                                <Stack direction="row" spacing={"4px"} alignItems="baseline">
                                                    <Tooltip title={<Typography fontSize="12px">{getPersonFullName(task.responsibleId)}</Typography>}>
                                                        <Avatar alt="value" src={getPersonImg(task.responsibleId)} {...stringAvatar(getPersonFullName(task.responsibleId), 30)}/>
                                                    </Tooltip>
                                                    <Box width="10px" height="5px"></Box>
                                                    {task.participants.map((p) => (<Tooltip title={<Typography fontSize="12px">{getPersonFullName(p.id)}</Typography>}><Avatar alt="value" src={getPersonImg(p.id)} {...stringAvatar(getPersonFullName(p.id), 25)}/></Tooltip>))}
                                                </Stack>
                                            </Stack>

                                            <Grid item justifyItems="flex-end" height="175px" width="20%" display="flex" direction="column" container>
                                                <Box display="flex" alignItems="flex-start" justifyContent="flex-end" height="20%" direction="column">
                                                    <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.edit} /></Typography>}>
                                                        <IconButton color="#ff00ff" href={'./improvementFullPage/' + task.id}> <EditOutlinedIcon /> </IconButton>
                                                    </Tooltip>
                                                </Box>
                                                <Box display="flex" alignItems="flex-start" justifyContent="flex-end" height="20%" direction="column">
                                                    <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.report_time} /></Typography>}>
                                                        {/* <IconButton color="#ff00ff" onClick={() => navigate('../improvementFullPage/' + task.id)}> <EditOutlinedIcon /> </IconButton>*/}
                                                    </Tooltip>
                                                </Box>

                                                <Box display="flex" alignItems="center" justifyContent="flex-end" height="30%" marginRight="5px">
                                                    {task.taskId === null && <>
                                                        <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.nr_of_subtasks} /></Typography>}>
                                                            <Typography style={styles.typography}>{getSubtasksCount(task.id)}</Typography>
                                                        </Tooltip>
                                                        <CircleOutlinedIcon fontSize="large" style={{ color: colors.primary[300] }} />
                                                    </>
                                                    }
                                                    {task.taskId !== null && <>
                                                        <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.parent_task_is} />: {getParentName(task.taskId)}</Typography>}>
                                                            <Typography style={styles.typography}>S</Typography>
                                                        </Tooltip>
                                                        <Link onClick={() => navigate('/improvementFullPage/' + task.taskId)}>
                                                            <CircleOutlinedIcon fontSize="large" style={{ color: colors.primary[300] }} />
                                                        </Link>
                                                    </>
                                                    }
                                                </Box>

                                                {task.typeId !== 1 &&
                                                    <Stack direction="row" display="flex" alignItems="flex-end" justifyContent="flex-end" spacing={"-15px"} height="30%" >
                                                        {/* <IconButton color="#ff00ff" onClick={() => { handleMoveBackClick(task.id, task.statusId) }}> <KeyboardDoubleArrowLeftOutlinedIcon /> </IconButton> */}
                                                        <IconButton color="#ff00ff" onClick={() => { handleMoveForwardClick(task.id, task.statusId) }}> <KeyboardDoubleArrowRightOutlinedIcon /> </IconButton>
                                                    </Stack>
                                                }
                                                {task.typeId === 1 &&
                                                    <Stack direction="row" display="flex" alignItems="flex-end" justifyContent="flex-end" spacing={"-15px"} height="30%" >
                                                        {/* <IconButton color="#ff00ff" onClick={() => { handleMoveBackClick(task.id, task.statusId) }}> <KeyboardDoubleArrowLeftOutlinedIcon /> </IconButton> */}
                                                        <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.close_and_archive} /></Typography>}>
                                                            <IconButton color="#ff00ff" onClick={() => { handleMoveForwardClick(task.id, 4) }}> <KeyboardDoubleArrowRightOutlinedIcon sx={{ color: colors.redAccent[500] }} /> </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Box>

                                )}
                            </Stack>
                        </Box>

                    </>}

                <Box gridColumn="span 2" >
                    <Stack p={1} boxShadow={12} sx={{ background: yellowGradient }} direction="row" display="flex" width="100%">
                        <Box width="50%" color="#000000"><FormattedMessage {...messages.status_Plan} /></Box>
                        <Box width="50%" display="flex" justifyContent="flex-end" color="#000000">{status25Filtered.length}/{status25Total.length}</Box>
                    </Stack>
                    <Stack direction="column" spacing={"10px"} paddingTop={"10px"} display="flex">
                        {/* {filteredData?.filter((s) => s.status.id === 1).map((task) => */}
                        {status25Filtered.map((task) =>
                            <Box height="100%" boxShadow={12} p={0} sx={{ background: getCardColor(task.taskId) }}>
                                {/* <Typography variant="h2" color="#ffffff">{task.title}hhh</Typography> */}
                                <Grid container alignItems="flex-start" display="flex" width="100%" minHeight="175px" direction="row">
                                    <Grid item width="1%" sx={{ backgroundColor: getTargetDateColor(task.targetDate) }} minHeight="175px">
                                        {/* {formatDate(task.targetDate) > formatDate(Date.now()) ? <img src="../../assets/dot_green.gif" alt="" height="160px" width="3px" /> : <img src="../../assets/dot_red.gif" alt="" width="3px" height="160px" />} */}
                                    </Grid>
                                    <Stack item width="74%" height="100%" p={1} spacing="2px" alignItems="flex-start" justifyContent="space-between" display="flex" direction="column" >
                                        <Stack direction="row" display="flex" alignItems="flex-start" justifyContent="flex-start" marginBottom="15px">
                                            {(task.type?.id === 1) ? <BoltOutlinedIcon fontSize="small" /> : (task.type?.id === 2) ? <StarRateOutlinedIcon fontSize="small" /> : ""}
                                            <Box><Typography fontSize="10pt">{task.title}</Typography></Box>
                                        </Stack>

                                        <Box color={colors.primary}><Typography fontSize="9pt"><FormattedMessage {...messages.target} />: {formatDateLocalized(task.targetDate)} {getDaysDue(task.targetDate)}</Typography></Box>
                                        <Stack color={colors.primary} direction="row"><Typography fontSize="9pt" marginRight="3px"><FormattedMessage {...messages.effort} />: </Typography><Typography fontSize="9pt" color={getEffortColor(getTotalTime(task), task.estimatedEffort)}>{isNull0(getTotalTime(task))}/{isNull0(task.estimatedEffort)}</Typography></Stack>
                                        <Box color={colors.primary}><Typography fontSize="9pt"><FormattedMessage {...messages.department} />: {task.department?.name}</Typography></Box>
                                        <Box color={colors.primary}><Typography fontSize="9pt">Dim:&nbsp;{trimRight2Chars(task.dimensionValues.map((d) => d.code))}</Typography></Box>
                                        <Stack direction="row" spacing={"5px"} alignItems="baseline" hight="35px">
                                            {task.tags.map((tag) => <Chip size="small" label={tag.title} variant="outlined" color="default" />)}
                                            <Box width="0px" height="18px"></Box>
                                        </Stack>
                                        <Stack direction="row" spacing={"4px"} alignItems="baseline">
                                            <Tooltip title={<Typography fontSize="12px">{getPersonFullName(task.responsibleId)}</Typography>}>
                                                <Avatar alt="value" src={getPersonImg(task.responsibleId)} {...stringAvatar(getPersonFullName(task.responsibleId), 30)}/>
                                            </Tooltip>
                                            <Box width="10px" height="5px"></Box>
                                            {task.participants.map((p) => (<Tooltip title={<Typography fontSize="12px">{getPersonFullName(p.id)}</Typography>}><Avatar  alt="value" src={getPersonImg(p.id)} {...stringAvatar(getPersonFullName(p.id), 25)}/></Tooltip>))}
                                        </Stack>
                                    </Stack>

                                    <Grid item justifyItems="flex-end" height="175px" width="25%" display="flex" direction="column" container>
                                        <Box display="flex" alignItems="flex-start" justifyContent="flex-end" height="20%" direction="column">
                                            <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.edit} /></Typography>}>
                                                <IconButton color="#ff00ff" onClick={() => navigate('../improvementFullPage/' + task.id)}> <EditOutlinedIcon /> </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Box display="flex" alignItems="flex-start" justifyContent="flex-end" height="20%" direction="column">
                                            <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.report_time} /></Typography>}>
                                                <IconButton color="#ff00ff" onClick={() => { handleOpenTimesDialog(task.id) }}> <MoreTimeIcon /> </IconButton>
                                            </Tooltip>
                                        </Box>

                                        <Box display="flex" alignItems="center" justifyContent="flex-end" height="30%" marginRight="5px">
                                            {task.taskId === null && <>
                                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.nr_of_subtasks} /></Typography>}>
                                                    <Typography style={styles.typography}>{getSubtasksCount(task.id)}</Typography>
                                                </Tooltip>
                                                <CircleOutlinedIcon fontSize="large" style={{ color: colors.primary[300] }} />
                                            </>
                                            }
                                            {task.taskId !== null && <>
                                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.parent_task_is} />: {getParentName(task.taskId)}</Typography>}>
                                                    <Typography style={styles.typography}>S</Typography>
                                                </Tooltip>
                                                <Link onClick={() => navigate('/improvementFullPage/' + task.taskId)}>
                                                    <CircleOutlinedIcon fontSize="large" style={{ color: colors.primary[300] }} />
                                                </Link>
                                            </>
                                            }
                                        </Box>
                                        <Stack direction="row" display="flex" alignItems="flex-end" justifyContent="flex-end" spacing={"-15px"} height="30%" >
                                            <IconButton color="#ff00ff" onClick={() => { handleMoveBackClick(task.id, task.statusId) }}> <KeyboardDoubleArrowLeftOutlinedIcon /> </IconButton>
                                            <IconButton color="#ff00ff" onClick={() => { handleMoveForwardClick(task.id, task.statusId) }}> <KeyboardDoubleArrowRightOutlinedIcon /> </IconButton>
                                        </Stack>
                                    </Grid>
                                </Grid>

                            </Box>
                        )}
                    </Stack>
                </Box>
                <Box gridColumn="span 2">
                    <Stack p={1} boxShadow={12} sx={{ background: greenGradient }} direction="row" display="flex" width="100%">
                        <Box width="50%"><FormattedMessage {...messages.status_do} /></Box>
                        <Box width="50%" display="flex" justifyContent="flex-end" >{status50Filtered.length}/{status50Total.length}</Box>
                    </Stack>
                    <Stack direction="column" spacing={"10px"} paddingTop={"10px"}>
                        {/* {filteredData?.filter((s) => s.status.id === 2).map((task) => */}
                        {filteredData?.filter((s) => s.status.value === 50).map((task) =>
                            <Box height="100%" boxShadow={12} p={0} sx={{ background: getCardColor(task.taskId) }}>
                                {/* <Typography variant="h2" color="#ffffff">{task.title}hhh</Typography> */}
                                <Grid container alignItems="flex-start" display="flex" width="100%" minHeight="175px" direction="row">
                                    <Grid item width="1%" sx={{ backgroundColor: getTargetDateColor(task.targetDate) }} minHeight="175px">
                                        {/* {formatDate(task.targetDate) > formatDate(Date.now()) ? <img src="../../assets/dot_green.gif" alt="" height="160px" width="3px" /> : <img src="../../assets/dot_red.gif" alt="" height="160px" width="3px" />} */}
                                    </Grid>
                                    <Stack item width="74%" height="100%" p={1} spacing="2px" alignItems="flex-start" justifyContent="space-between" display="flex" direction="column" >
                                        <Stack direction="row" display="flex" alignItems="flex-start" justifyContent="flex-start" marginBottom="15px">
                                            {(task.type?.id === 1) ? <BoltOutlinedIcon fontSize="small" /> : (task.type?.id === 2) ? <StarRateOutlinedIcon fontSize="small" /> : ""}
                                            <Box><Typography fontSize="10pt">{task.title}</Typography></Box>
                                        </Stack>
                                        <Box color={colors.primary}><Typography fontSize="9pt"><FormattedMessage {...messages.target} />: {formatDateLocalized(task.targetDate)} {getDaysDue(task.targetDate)}</Typography></Box>
                                        <Stack color={colors.primary} direction="row"><Typography fontSize="9pt" marginRight="3px"><FormattedMessage {...messages.effort} />: </Typography><Typography fontSize="9pt" color={getEffortColor(getTotalTime(task), task.estimatedEffort)}>{isNull0(getTotalTime(task))}/{isNull0(task.estimatedEffort)}</Typography></Stack>
                                        <Box color={colors.primary}><Typography fontSize="9pt"><FormattedMessage {...messages.department} />: {task.department?.name}</Typography></Box>
                                        <Box color={colors.primary}><Typography fontSize="9pt">Dim:&nbsp;{trimRight2Chars(task.dimensionValues.map((d) => d.code))}</Typography></Box>
                                        <Stack direction="row" spacing={"5px"} alignItems="baseline" hight="35px">
                                            {task.tags.map((tag) => <Chip size="small" label={tag.title} variant="outlined" color="default" />)}
                                            <Box width="0px" height="18px"></Box>
                                        </Stack>
                                        <Stack direction="row" spacing={"4px"} alignItems="baseline">
                                            <Tooltip title={<Typography fontSize="12px">{getPersonFullName(task.responsibleId)}</Typography>}>
                                                <Avatar alt="value" src={getPersonImg(task.responsibleId)} {...stringAvatar(getPersonFullName(task.responsibleId), 30)}/>
                                            </Tooltip>
                                            <Box width="10px" height="5px"></Box>
                                            {task.participants.map((p) => (<Tooltip title={<Typography fontSize="12px">{getPersonFullName(p.id)}</Typography>}><Avatar alt="value" src={getPersonImg(p.id)} {...stringAvatar(getPersonFullName(p.id), 25)}/></Tooltip>))}
                                        </Stack>
                                    </Stack>
                                    <Grid item justifyItems="flex-end" height="175px" width="25%" display="flex" direction="column" container>
                                        <Box display="flex" alignItems="flex-start" justifyContent="flex-end" height="20%" direction="column">
                                            <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.edit} /></Typography>}>
                                                <IconButton color="#ff00ff" onClick={() => navigate('../improvementFullPage/' + task.id)}> <EditOutlinedIcon /> </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Box display="flex" alignItems="flex-start" justifyContent="flex-end" height="20%" direction="column">
                                            <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.report_time} /></Typography>}>
                                                <IconButton color="#ff00ff" onClick={() => { handleOpenTimesDialog(task.id) }}> <MoreTimeIcon /> </IconButton>
                                            </Tooltip>
                                        </Box>

                                        <Box display="flex" alignItems="center" justifyContent="flex-end" height="30%" marginRight="5px">
                                            {task.taskId === null && <>
                                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.nr_of_subtasks} /></Typography>}>
                                                    <Typography style={styles.typography}>{getSubtasksCount(task.id)}</Typography>
                                                </Tooltip>
                                                <CircleOutlinedIcon fontSize="large" style={{ color: colors.primary[300] }} />
                                            </>
                                            }
                                            {task.taskId !== null && <>
                                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.parent_task_is} />: {getParentName(task.taskId)}</Typography>}>
                                                    <Typography style={styles.typography}>S</Typography>
                                                </Tooltip>
                                                <Link onClick={() => navigate('/improvementFullPage/' + task.taskId)}>
                                                    <CircleOutlinedIcon fontSize="large" style={{ color: colors.primary[300] }} />
                                                </Link>
                                            </>
                                            }
                                        </Box>
                                        <Stack direction="row" display="flex" alignItems="flex-end" justifyContent="flex-end" spacing={"-15px"} height="30%" >
                                            <IconButton color="#ff00ff" onClick={() => { handleMoveBackClick(task.id, task.statusId) }}> <KeyboardDoubleArrowLeftOutlinedIcon /> </IconButton>
                                            <IconButton color="#ff00ff" onClick={() => { handleMoveForwardClick(task.id, task.statusId) }}> <KeyboardDoubleArrowRightOutlinedIcon /> </IconButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Stack>
                </Box>
                <Box gridColumn="span 2">
                    <Stack p={1} boxShadow={12} sx={{ background: blueGradient }} direction="row" display="flex" width="100%">
                        <Box width="50%"><FormattedMessage {...messages.status_check} /></Box>
                        <Box width="50%" display="flex" justifyContent="flex-end" >{status75Filtered.length}/{status75Total.length}</Box>
                    </Stack>
                    <Stack direction="column" spacing={"10px"} paddingTop={"10px"}>
                        {/* {filteredData?.filter((s) => s.status.id === 1003).map((task) => */}
                        {status75Filtered.map((task) =>
                            <Box height="100%" boxShadow={12} p={0} sx={{ background: getCardColor(task.taskId) }}>
                                {/* <Typography variant="h2" color="#ffffff">{task.title}hhh</Typography> */}
                                <Grid container alignItems="flex-start" display="flex" minHeight="175px" direction="row">
                                    <Grid item width="1%" sx={{ backgroundColor: getTargetDateColor(task.targetDate) }} minHeight="175px">
                                        {/* {formatDate(task.targetDate) > formatDate(Date.now()) ? <img src="../../assets/dot_green.gif" alt="" height="160px" width="3px" /> : <img src="../../assets/dot_red.gif" alt="" height="160px" width="3px" />} */}
                                    </Grid>
                                    <Stack item width="74%" height="100%" p={1} spacing="2px" alignItems="flex-start" justifyContent="space-between" display="flex" direction="column" >
                                        <Stack direction="row" display="flex" alignItems="flex-start" justifyContent="flex-start" marginBottom="15px">
                                            {(task.type?.id === 1) ? <BoltOutlinedIcon fontSize="small" /> : (task.type?.id === 2) ? <StarRateOutlinedIcon fontSize="small" /> : ""}
                                            <Box><Typography fontSize="10pt">{task.title}</Typography></Box>
                                        </Stack>
                                        <Box color={colors.primary}><Typography fontSize="9pt"><FormattedMessage {...messages.target} />: {formatDateLocalized(task.targetDate)} {getDaysDue(task.targetDate)}</Typography></Box>
                                        <Stack color={colors.primary} direction="row"><Typography fontSize="9pt" marginRight="3px"><FormattedMessage {...messages.effort} />: </Typography><Typography fontSize="9pt" color={getEffortColor(getTotalTime(task), task.estimatedEffort)}>{isNull0(getTotalTime(task))}/{isNull0(task.estimatedEffort)}</Typography></Stack>
                                        <Box color={colors.primary}><Typography fontSize="9pt"><FormattedMessage {...messages.department} />: {task.department?.name}</Typography></Box>
                                        <Box color={colors.primary}><Typography fontSize="9pt">Dim:&nbsp;{trimRight2Chars(task.dimensionValues.map((d) => d.code))}</Typography></Box>
                                        <Stack direction="row" spacing={"5px"} alignItems="baseline" hight="35px">
                                            {task.tags.map((tag) => <Chip size="small" label={tag.title} variant="outlined" color="default" />)}
                                            <Box width="0px" height="18px"></Box>
                                        </Stack>
                                        <Stack direction="row" spacing={"4px"} alignItems="baseline">
                                            <Tooltip title={<Typography fontSize="12px">{getPersonFullName(task.responsibleId)}</Typography>}>
                                                <Avatar alt="value" src={getPersonImg(task.responsibleId)} {...stringAvatar(getPersonFullName(task.responsibleId), 30)}/>
                                            </Tooltip>
                                            <Box width="10px" height="5px"></Box>
                                            {task.participants.map((p) => (<Tooltip title={<Typography fontSize="12px">{getPersonFullName(p.id)}</Typography>}><Avatar alt="value" src={getPersonImg(p.id)} {...stringAvatar(getPersonFullName(p.id), 25)}/></Tooltip>))}
                                        </Stack>
                                    </Stack>
                                    <Grid item justifyItems="flex-end" height="175px" width="25%" display="flex" direction="column" container>
                                        <Box display="flex" alignItems="flex-start" justifyContent="flex-end" height="20%" direction="column">
                                            <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.edit} /></Typography>}>
                                                <IconButton color="#ff00ff" onClick={() => navigate('../improvementFullPage/' + task.id)}> <EditOutlinedIcon /> </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Box display="flex" alignItems="flex-start" justifyContent="flex-end" height="20%" direction="column">
                                            <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.report_time} /></Typography>}>
                                                <IconButton color="#ff00ff" onClick={() => { handleOpenTimesDialog(task.id) }}> <MoreTimeIcon /> </IconButton>
                                            </Tooltip>
                                        </Box>

                                        <Box display="flex" alignItems="center" justifyContent="flex-end" height="30%" marginRight="5px">
                                            {task.taskId === null && <>
                                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.nr_of_subtasks} /></Typography>}>
                                                    <Typography style={styles.typography}>{getSubtasksCount(task.id)}</Typography>
                                                </Tooltip>
                                                <CircleOutlinedIcon fontSize="large" style={{ color: colors.primary[300] }} />
                                            </>
                                            }
                                            {task.taskId !== null && <>
                                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.parent_task_is} />: {getParentName(task.taskId)}</Typography>}>
                                                    <Typography style={styles.typography}>S</Typography>
                                                </Tooltip>
                                                <Link onClick={() => navigate('/improvementFullPage/' + task.taskId)}>
                                                    <CircleOutlinedIcon fontSize="large" style={{ color: colors.primary[300] }} />
                                                </Link>
                                            </>
                                            }
                                        </Box>
                                        <Stack direction="row" display="flex" alignItems="flex-end" justifyContent="flex-end" spacing={"-15px"} height="30%" >
                                            <IconButton color="#ff00ff" onClick={() => { handleMoveBackClick(task.id, task.statusId) }}> <KeyboardDoubleArrowLeftOutlinedIcon /> </IconButton>
                                            <IconButton color="#ff00ff" onClick={() => { handleMoveForwardClick(task.id, task.statusId) }}> <KeyboardDoubleArrowRightOutlinedIcon /> </IconButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Stack>
                </Box>
                <Box gridColumn="span 2">
                    <Stack p={1} boxShadow={12} sx={{ background: redGradient }} direction="row" display="flex" width="100%">
                        <Box width="50%"><FormattedMessage {...messages.status_act} /></Box>
                        <Box width="50%" display="flex" justifyContent="flex-end" >{status100Filtered.length}/{status100Total.length}</Box>
                    </Stack>
                    <Stack direction="column" spacing={"10px"} paddingTop={"10px"}>
                        {/* {filteredData?.filter((s) => s.status.id === 1004).map((task) => */}
                        {status100Filtered.map((task) =>
                            <Box height="100%" boxShadow={12} p={0} sx={{ background: getCardColor(task.taskId) }}>
                                {/* <Typography variant="h2" color="#ffffff">{task.title}hhh</Typography> */}
                                <Grid container alignItems="flex-start" display="flex" width="100%" minHeight="175px" direction="row">
                                    <Grid item width="1%" sx={{ backgroundColor: getTargetDateColor(task.targetDate) }} minHeight="175px">
                                        {/* {formatDate(task.targetDate) > formatDate(Date.now()) ? <img src="../../assets/dot_green.gif" alt="" height="160px" width="3px" /> : <img src="../../assets/dot_red.gif" alt="" height="160px" width="3px" />} */}
                                    </Grid>
                                    <Stack item width="74%" height="100%" p={1} spacing="2px" alignItems="flex-start" justifyContent="space-between" display="flex" direction="column" >
                                        <Stack direction="row" display="flex" alignItems="flex-start" justifyContent="flex-start" marginBottom="15px">
                                            {(task.type?.id === 1) ? <BoltOutlinedIcon fontSize="small" /> : (task.type?.id === 2) ? <StarRateOutlinedIcon fontSize="small" /> : ""}
                                            <Box><Typography fontSize="10pt">{task.title}</Typography></Box>
                                        </Stack>
                                        <Box color={colors.primary}><Typography fontSize="9pt"><FormattedMessage {...messages.target} />: {formatDateLocalized(task.targetDate)} {getDaysDue(task.targetDate)}</Typography></Box>
                                        <Stack color={colors.primary} direction="row"><Typography fontSize="9pt" marginRight="3px"><FormattedMessage {...messages.effort} />: </Typography><Typography fontSize="9pt" color={getEffortColor(getTotalTime(task), task.estimatedEffort)}>{isNull0(getTotalTime(task))}/{isNull0(task.estimatedEffort)}</Typography></Stack>
                                        <Box color={colors.primary}><Typography fontSize="9pt"><FormattedMessage {...messages.department} />: {task.department?.name}</Typography></Box>
                                        <Box color={colors.primary}><Typography fontSize="9pt">Dim:&nbsp;{trimRight2Chars(task.dimensionValues.map((d) => d.code))}</Typography></Box>
                                        <Stack direction="row" spacing={"5px"} alignItems="baseline" hight="35px">
                                            {task.tags.map((tag) => <Chip size="small" label={tag.title} variant="outlined" color="default" />)}
                                            <Box width="0px" height="18px"></Box>
                                        </Stack>
                                        <Stack direction="row" spacing={"4px"} alignItems="baseline">
                                            <Tooltip title={<Typography fontSize="12px">{getPersonFullName(task.responsibleId)}</Typography>}>
                                                <Avatar  alt="value" src={getPersonImg(task.responsibleId)} {...stringAvatar(getPersonFullName(task.responsibleId), 30)}/>
                                            </Tooltip>
                                            <Box width="10px" height="5px"></Box>
                                            {task.participants.map((p) => (<Tooltip title={<Typography fontSize="12px">{getPersonFullName(p.id)}</Typography>}><Avatar s alt="value" src={getPersonImg(p.id)} {...stringAvatar(getPersonFullName(p.id), 25)}/></Tooltip>))}
                                        </Stack>
                                    </Stack>
                                    <Grid item justifyItems="flex-end" height="175px" width="25%" display="flex" direction="column" container>
                                        <Box display="flex" alignItems="flex-start" justifyContent="flex-end" height="20%" direction="column">
                                            <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.edit} /></Typography>}>
                                                <IconButton color="#ff00ff" onClick={() => navigate('../improvementFullPage/' + task.id)}> <EditOutlinedIcon /> </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Box display="flex" alignItems="flex-start" justifyContent="flex-end" height="20%" direction="column">
                                            <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.report_time} /></Typography>}>
                                                <IconButton color="#ff00ff" onClick={() => { handleOpenTimesDialog(task.id) }}> <MoreTimeIcon /> </IconButton>
                                            </Tooltip>
                                        </Box>

                                        <Box display="flex" alignItems="center" justifyContent="flex-end" height="30%" marginRight="5px">
                                            {task.taskId === null && <>
                                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.nr_of_subtasks} /></Typography>}>
                                                    <Typography style={styles.typography}>{getSubtasksCount(task.id)}</Typography>
                                                </Tooltip>
                                                <CircleOutlinedIcon fontSize="large" style={{ color: colors.primary[300] }} />
                                            </>
                                            }
                                            {task.taskId !== null && <>
                                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.parent_task_is} />: {getParentName(task.taskId)}</Typography>}>
                                                    <Typography style={styles.typography}>S</Typography>
                                                </Tooltip>
                                                <Link onClick={() => navigate('/improvementFullPage/' + task.taskId)}>
                                                    <CircleOutlinedIcon fontSize="large" style={{ color: colors.primary[300] }} />
                                                </Link>
                                            </>
                                            }
                                        </Box>
                                        <Stack direction="row" display="flex" alignItems="flex-end" justifyContent="flex-end" spacing={"-15px"} height="30%" >
                                            <IconButton color="#ff00ff" onClick={() => { handleMoveBackClick(task.id, task.statusId) }}> <KeyboardDoubleArrowLeftOutlinedIcon /> </IconButton>
                                            <IconButton color="#ff00ff" onClick={() => { handleMoveForwardClick(task.id, task.statusId) }}> <KeyboardDoubleArrowRightOutlinedIcon /> </IconButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Stack>
                </Box>
                {openArchived && <Box gridColumn="span 2">
                    <Stack p={1} boxShadow={12} direction="row" display="flex" width="100%" height="37px" sx={{ background: `linear-gradient(to right bottom, ${colors.primary[400]}, ${colors.primary[500]})` }}>
                        <Box width="50%"><FormattedMessage {...messages.status_archived} /></Box>
                        <Box width="50%" display="flex" justifyContent="flex-end" >{status125Filtered.length}/{status125Total.length} <IconButton color="#ff00ff" onClick={() => { toggleOpenArchived(false) }}> <KeyboardDoubleArrowRightOutlinedIcon /> </IconButton></Box>
                    </Stack>
                    <Stack direction="column" spacing={"10px"} paddingTop={"10px"}>
                        {/* {filteredData?.filter((s) => s.status.id === 1004).map((task) => */}
                        {status125Filtered.map((task) =>
                            <Box height="100%" boxShadow={12} p={0} sx={{ background: getCardColor(task.taskId) }}>
                                {/* <Typography variant="h2" color="#ffffff">{task.title}hhh</Typography> */}
                                <Grid container alignItems="flex-start" display="flex" width="100%" minHeight="175px" direction="row">
                                    <Grid item width="1%" sx={{ backgroundColor: getTargetDateColor(task.targetDate) }} minHeight="175px">
                                        {/* {formatDate(task.targetDate) > formatDate(Date.now()) ? <img src="../../assets/dot_green.gif" alt="" height="160px" width="3px" /> : <img src="../../assets/dot_red.gif" alt="" height="160px" width="3px" />} */}
                                    </Grid>
                                    <Stack item width="74%" height="100%" p={1} spacing="2px" alignItems="flex-start" justifyContent="space-between" display="flex" direction="column" >
                                        <Stack direction="row" display="flex" alignItems="flex-start" justifyContent="flex-start" marginBottom="15px">
                                            {(task.type?.id === 1) ? <BoltOutlinedIcon fontSize="small" /> : (task.type?.id === 2) ? <StarRateOutlinedIcon fontSize="small" /> : ""}
                                            <Box><Typography fontSize="10pt">{task.title}</Typography></Box>
                                        </Stack>
                                        <Box color={colors.primary}><Typography fontSize="9pt"><FormattedMessage {...messages.target} />: {formatDateLocalized(task.targetDate)} {getDaysDue(task.targetDate)}</Typography></Box>
                                        <Stack color={colors.primary} direction="row"><Typography fontSize="9pt" marginRight="3px"><FormattedMessage {...messages.effort} />: </Typography><Typography fontSize="9pt" color={getEffortColor(getTotalTime(task), task.estimatedEffort)}>{isNull0(getTotalTime(task))}/{isNull0(task.estimatedEffort)}</Typography></Stack>
                                        <Box color={colors.primary}><Typography fontSize="9pt"><FormattedMessage {...messages.department} />: {task.department?.name}</Typography></Box>
                                        <Box color={colors.primary}><Typography fontSize="9pt">Dim:&nbsp;{trimRight2Chars(task.dimensionValues.map((d) => d.code))}</Typography></Box>
                                        <Stack direction="row" spacing={"5px"} alignItems="baseline" hight="35px">
                                            {task.tags.map((tag) => <Chip size="small" label={tag.title} variant="outlined" color="default" />)}
                                            <Box width="0px" height="18px"></Box>
                                        </Stack>
                                        <Stack direction="row" spacing={"4px"} alignItems="baseline">
                                            <Tooltip title={<Typography fontSize="12px">{getPersonFullName(task.responsibleId)}</Typography>}>
                                                <Avatar alt="value" src={getPersonImg(task.responsibleId)} {...stringAvatar(getPersonFullName(task.responsibleId), 30)} />
                                            </Tooltip>
                                            <Box width="10px" height="5px"></Box>
                                            {task.participants.map((p) => (<Tooltip title={<Typography fontSize="12px">{getPersonFullName(p.id)}</Typography>}><Avatar alt="value" src={getPersonImg(p.id)} {...stringAvatar(getPersonFullName(p.id), 25)}/></Tooltip>))}
                                        </Stack>
                                    </Stack>
                                    <Grid item justifyItems="flex-end" height="175px" width="25%" display="flex" direction="column" container>
                                        <Box display="flex" alignItems="flex-start" justifyContent="flex-end" height="20%" direction="column">
                                            <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.edit} /></Typography>}>
                                                <IconButton color="#ff00ff" href={'./improvementFullPage/' + task.id}> <EditOutlinedIcon /> </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Box display="flex" alignItems="flex-start" justifyContent="flex-end" height="20%" direction="column">
                                            <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.report_time} /></Typography>}>
                                                {/* <IconButton color="#ff00ff" onClick={() => navigate('../improvementFullPage/' + task.id)}> <EditOutlinedIcon /> </IconButton> */}
                                            </Tooltip>
                                        </Box>

                                        <Box display="flex" alignItems="center" justifyContent="flex-end" height="30%" marginRight="5px">
                                            {task.taskId === null && <>
                                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.nr_of_subtasks} /></Typography>}>
                                                    <Typography style={styles.typography}>{getSubtasksCount(task.id)}</Typography>
                                                </Tooltip>
                                                <CircleOutlinedIcon fontSize="large" style={{ color: colors.primary[300] }} />
                                            </>
                                            }
                                            {task.taskId !== null && <>
                                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.parent_task_is} />: {getParentName(task.taskId)}</Typography>}>
                                                    <Typography style={styles.typography}>S</Typography>
                                                </Tooltip>
                                                <Link onClick={() => navigate('/improvementFullPage/' + task.taskId)}>
                                                    <CircleOutlinedIcon fontSize="large" style={{ color: colors.primary[300] }} />
                                                </Link>
                                            </>
                                            }
                                        </Box>
                                        {task.typeId !== 1 &&
                                            <Stack direction="row" display="flex" alignItems="flex-end" justifyContent="flex-end" spacing={"-15px"} height="30%" >
                                                <IconButton color="#ff00ff" onClick={() => { handleMoveBackClick(task.id, task.statusId) }}> <KeyboardDoubleArrowLeftOutlinedIcon /> </IconButton>
                                                {/* <IconButton color="#ff00ff" onClick={() => { handleMoveForwardClick(task.id, task.statusId) }}> <KeyboardDoubleArrowRightOutlinedIcon /> </IconButton> */}
                                            </Stack>
                                        }
                                        {task.typeId === 1 &&
                                            <Stack direction="row" display="flex" alignItems="flex-end" justifyContent="flex-end" spacing={"-15px"} height="30%" >
                                                {/* <IconButton color="#ff00ff" onClick={() => { handleMoveBackClick(task.id, task.statusId) }}> <KeyboardDoubleArrowLeftOutlinedIcon /> </IconButton> */}
                                                <Tooltip title={<Typography fontSize="12px">Reopen this incident and move it to status new</Typography>}>
                                                    <IconButton color="#ff00ff" onClick={() => { handleMoveBackClick(task.id, 1) }}> <KeyboardDoubleArrowLeftOutlinedIcon /> </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Stack>
                </Box>}
            </Box>

            {!openArchived && <Stack boxShadow={12} sx={{ background: `linear-gradient(to bottom, ${colors.primary[400]}, ${colors.primary[700]})` }} direction="column" display="flex" minHeight="300px" width="40px" justifyContent="flex-start" alignContent="center" marginRight="5px" marginLeft="10px">
                <IconButton color="#ff00ff" onClick={() => { toggleOpenArchived(true) }}> <KeyboardDoubleArrowLeftOutlinedIcon /> </IconButton>
                <Box display="flex" justifyContent="center">
                    <Typography sx={{ textOrientation: 'sideways', writingMode: 'vertical-lr' }} paddingTop="10px" color="#ffffff">Archived {status125Filtered.length}/{status125Total.length}</Typography>
                </Box>
                {/* <Box width="50%" display="flex" justifyContent="flex-end" alignContent="flex-bottom">{status0Filtered.length}/{status0Total.length}</Box> */}
            </Stack>}
        </Stack>

        <Dialog open={openTimes} onClose={handleCloseTimesDialog} maxWidth="md" >
            <Box m={0} sx={{ backgroundColor: colors.primary[400], borderRadius: "4px" }} width="700px" >

                <DialogTitle><FormattedMessage {...messages.report_time} /></DialogTitle>
                <DialogContent>
                    <ImprovementAddTimeWithoutData taskId={openTimesForId} refetchTask={""} personQuery={personQuery} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTimesDialog}
                        sx={{
                            "&:hover": {
                                backgroundColor: "#535560",
                            },
                            backgroundColor: colors.primary[300],
                            color: "#000000",
                            marginBottom: "15px",
                            marginRight: "15px"
                        }}><FormattedMessage {...messages.close} /></Button>
                </DialogActions>
            </Box>
        </Dialog>
    </>;




    return <Box padding={0} width={"100%"}>
        {/* <Paper
      sx={{
        position: "relative",
        borderRadius: "4px",
      }} 
    >*/}
        {content}
        {/* </Paper> */}

    </Box>







    // const columns = [

    //   {
    //     field: "id",
    //     headerName: "Id",
    //     // headerName: <FormattedMessage {...messages.machineStatusColumn} />,
    //     flex: 0.5,
    //   },
    //   {
    //     field: "title",
    //     headerName: "Titel",
    //     flex: 1,
    //   },
    //   {
    //     field: "description",
    //     headerName: "Description",
    //     flex: 2,
    //   },
    //   {
    //     field: "status",
    //     headerName: "Status",
    //     flex: 1,
    //     align: "left",
    //     valueGetter: (value, row) => `${row.status.description || ''} (${row.status.color || ''})`,
    //   },
    //   {
    //     field: "targetDate",
    //     headerName: "Target",
    //     flex: 1,
    //     align: "left",
    //     valueGetter: (value, row) => `${formatDate(row.targetDate) || ''}`,
    //   },
    //   {
    //     field: "startDate",
    //     headerName: "Started",
    //     flex: 1,
    //     align: "left",
    //     valueGetter: (value, row) => `${formatDate(row.startDate) || ''}`,
    //   },
    //   {
    //     field: "department",
    //     headerName: "department",
    //     flex: 1,
    //     align: "left",
    //     valueGetter: (value, row) => `${row.department?.name || ''}`,
    //   },
    //   {
    //     field: "progress",
    //     headerName: "Progress",
    //     flex: 1,
    //     align: "left",
    //     valueGetter: (value, row) => `${row.progress || '0'}%`,
    //   },
    //   {
    //     field: "responsible",
    //     headerName: "Responsible",
    //     flex: 1,
    //     valueGetter: (value, row) => `${row.responsible?.firstName || ''} ${row.responsible.lastName || ''}`,
    //   },
    //   {
    //     field: "statusId",
    //     headerName: "Participants",
    //     flex: 1,
    //     renderCell: (params) => {
    //       return (
    //         <Stack direction="row" spacing={"3px"} paddingTop={"10px"}>
    //           {params.row.participants.map((part) => 
    //             <Avatar sx={{ width: 30, height: 30 }} alt="value" src={"../../assets/" + part.image} />)}
    //         </Stack>);
    //     },
    //   },
    //   {
    //     field: "tags",
    //     headerName: "Tags",
    //     flex: 2,
    //     renderCell: (params) => {
    //       return (
    //         <Stack direction="row" spacing={"3px"} paddingTop={"10px"}>
    //           {params.row.tags.map((tag) => 
    //             <Chip label={tag.title} variant="outlined" color="success"/>)}
    //         </Stack>);
    //     },
    //   },
    // ];

    // return (
    //   <Box padding={2} paddingTop={8} width={"100%"}>
    //     <Paper
    //       sx={{
    //         position: "relative",
    //         borderRadius: "4px",
    //       }}
    //     >
    //       <Box
    //         m="0px 0 0 0"
    //         height="36vh"
    //         sx={{
    //           "& .MuiDataGrid-root": {
    //             border: "none",
    //           },
    //           "& .MuiDataGrid-cell": {
    //           },
    //           "& .name.column--cell": {
    //             color: colors.greenAccent[300],
    //           },
    //           "& .MuiDataGrid-cellContent": {
    //             fontSize: "13px",
    //           },
    //           "& .MuiDataGrid-columnHeaders": {
    //             backgroundColor: colors.primary[600],
    //             borderBottom: "none",
    //           },
    //           "& .MuiDataGrid-virtualScroller": {
    //             backgroundColor: colors.primary[400],
    //           },
    //           "& .MuiDataGrid-footerContainer": {
    //             borderTop: "none",
    //             backgroundColor: colors.primary[600],
    //           },
    //           "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
    //             color: `${colors.gray[100]} !important`,
    //           },
    //           "& .super-app-theme--11": {
    //             bgcolor: `${colors.redAccent[500]} !important`,
    //           },
    //           "& .super-app-theme--undefined-stop": {
    //             bgcolor: `${colors.redAccent[500]} !important`,
    //           },
    //           "& .MuiDataGrid-columnHeader": {
    //             fontSize: "15px",
    //           },
    //         }}
    //       >
    //         {(!taskQuery?.isFetching) && <DataGrid
    //           rows={taskQuery?.data}
    //           sortModel={sortModelStorage}
    //           onSortModelChange={(newModel) => setSortModelStorage(newModel)}
    //           columns={columns}
    //           columnVisibilityModel={columnVisibilityModel}
    //           onColumnVisibilityModelChange={(newModel) =>
    //             setColumnVisibilityModel(newModel)
    //           }
    //         />}
    //       </Box>
    //     </Paper>
    //   </Box>
    // );

}
export default ImprovementsListLanes;
