import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { inputLabelClasses } from "@mui/material/InputLabel";
import { Autocomplete, Button } from "@mui/material";



export default function AxionAutoComplete(props) {

    const { text, value, options, color, variant, onChange,label,sx } = props
    return (

        <Autocomplete
            id="test"
            disablePortal 
            size="small"
            slotProps={{
              paper: {
                sx: {
                  '& .MuiAutocomplete-listbox': {
                    '& .MuiAutocomplete-option': {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    },
                  },
                },
              },
            }}
            value={value}
            renderOption={(props, option) => (
                <li {...props}>
                  {option.label}
                </li>
              )}
            sx={{
              '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {          
                backgroundColor: '#ffffff',
                color: '#000000',
                "&:hover": {
                  backgroundColor: '#f0f0f0',
                },
              },
              "& .base-Popper-root": 
              {
                backgroundColor: "#ffffff !important",
              },
          "& .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
              {
                backgroundColor: "#ffffff",
              },
          "& .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
              {
                backgroundColor: "#3878b4",
              },
            }}
            
            renderInput={(params) => (
              <TextField {...params} 
                label={label}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      size: "small",
                      bgcolor: 'white',
                      color: 'black',
                      '& .MuiMenuItem-root': {
                        padding: 1,
                      },
                    },
                  },
                }}
                sx={{
                  borderColor: '#799c4a !important', 
                  border: '1.5px solid', 
                  borderRadius:'4px',
                  '& .MuiInputBase-root': { 
                    bgcolor: 'white',
                    color: 'black',
                  },
                  '& .MuiInputBase-input': {
                    color: 'black'     // Ensures the input text is also black
                  },
                  '& .MuiInputLabel-root': {
                    color: 'black'     // Ensures the input text is also black
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'black',    // Changing the dropdown arrow to black
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'black',  
                },
               
                }} />)}
            options={options}
            onChange={onChange}
    
           />


    )
}


