import { Box, Typography, useTheme, Stack } from "@mui/material";
import { tokens } from "../../assets/theme";
import { useNavigate } from "react-router-dom";
// import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, subtitle, icon, progress, increase, url }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  return (

    <Box width="100%" m="0 20px" display="flex" alignContent="flex-start" height="100%" paddingTop="20px" onClick={() => navigate(url)} >
      <Stack direction="column" display="flex" width="100%" >
        
        <Stack direction="row" display="flex" alignContent="flex-start" width="100%">
          <Box display="flex" justifyContent="flex-start" alignItems="center" width="100%">
            <Typography
              variant="h2"
              fontWeight="bold"
              sx={{ color: colors.gray[100] }}
            >
              {title}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.gray[100] }}
            >
              {icon}
            </Typography>
          </Box>
        </Stack>

        <Box  display="flex" justifyContent="flex-start" alignContent="flex-start" width="100%">
          <Typography variant="h5" sx={{ color: colors.gray[300] }}>
            {subtitle}
          </Typography>
        </Box>
      </Stack>
    </Box>



  );
};

export default StatBox;
