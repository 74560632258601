import React from 'react'
import ShowUsers from  '../features/Users/showUsers'


console.log("ImprovementsPage.jsx");

export default function UsersPage() {
  return (
    <ShowUsers/>
  )
}
