import React from 'react'
import ImprovementsDashboard from '../features/dashboard/improvementsDashboard'


console.log("ImprovementsDashboardPage.jsx");

export default function ImprovementsDashboardPage() {
  return (
    <ImprovementsDashboard/>
  )
}
