import React, { useState, useEffect, useMemo } from "react";
import { Button, useTheme, Box, IconButton, Stack, Select, OutlinedInput, Chip, Avatar, MenuItem, Tooltip, Typography, InputLabel, FormControl, CircularProgress, ListSubheader } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import AxionControls from "../../../components/controls/AxionControls";
import GridDatePicker from "../../../components/controls/GridDatePicker";
import Controls from "../../../components/controls/Contols";

import { tokens } from "../../../assets/theme";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { postReportedTime } from "../../../fetchers/reportedTime";
import SaveIcon from '@mui/icons-material/Save';
import { pushErrorMessage } from "../../../utils/notifications";
import { getPersons } from "../../../fetchers/person";
import { getDepartments } from "../../../fetchers/departments";
import { getCategories } from "../../../fetchers/incidentCategory";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
//Language
import messages from "../messages";
import { FormattedMessage, useIntl } from "react-intl";

export default function IncidentsAdd(props) {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    //    console.log("props.editdate", props.editDate);
    //console.log ("IncidentsAdd", props.taskObj);
    //console.log ("IncidentsAdd", props.categoryId);

    const personQuery = useQuery(
        ["persons"],
        () => getPersons(),
        {}
    );

    const departmentsQuery = useQuery(
        ["departments"],
        () => getDepartments(companyKey),
        {}
    );
    const categoriesQuery = useQuery(
        ["categories"],
        () => getCategories(companyKey),
        {}
    );

    const handleResponsibleChange = (event) => {
        const {
            target: { value },
        } = event;
        let task = { ...props.taskObj };
        task.responsibleId = value;
        props.setTaskObj(task);
    };

    const handleDescriptionChange = (event) => {
        let task = { ...props.taskObj };
        task.description = event.target.value;
        props.setTaskObj(task);
    };

    const handleTitleChange = (event) => {
        let task = { ...props.taskObj };
        task.title = event.target.value;
        props.setTaskObj(task);
    };
    const handleTargetDateChange = (event) => {
        let task = { ...props.taskObj };
        if (isDateValid(event)) {
            task.targetDate = formatFullDateTime(event);
            props.setTaskObj(task);
        }
    };
    const handleIncidentDateChange = (event) => {
        let task = { ...props.taskObj };
        if (isDateValid(event)) {
            task.incidentDate = formatFullDateTime(event);
            props.setTaskObj(task);
        }
    };
    const handleDepartmentChange = (event) => {
        let task = { ...props.taskObj };
        task.departmentId = event.target.value;
        props.setTaskObj(task);
    };
    const handleCategoryChange = (event) => {
        let task = { ...props.taskObj };
        task.incidentCategoryId = props.categoryId;
        task.incidentCategoryValueId = event.target.value;
        props.setTaskObj(task);
    };


    function isDateValid(dateStr) {
        return !isNaN(new Date(dateStr));
    }
    function formatDate(date) {
        return date ? new Date(date).toLocaleDateString('sv-SE') : "";
    }
    function formatFullTime(date) {
        return date ? new Date(date).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit', second: '2-digit' }) : "";
    }
    function formatFullDateTime(date) {
        let formattedDate = formatDate(date) + "T" + formatFullTime(date);
        return formattedDate;
    }
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name, boxSize) {
        if (name === undefined || name === null || name === "" || name.split(' ').length < 2) {
            return ["N", "N"];
        }
        return {
            sx: {
                bgcolor: stringToColor(name),
                border: 1,
                borderColor: '#000000',
                height: boxSize,
                width: boxSize,
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    function getPersonImg(personId) {
        //console.log("getPersonImg:" + personId);
        let person = personQuery.data.find(p => p.id === personId);
        return `data:;base64,${person?.imageBase64}`;
    }
    function getPersonFullName(personId) {
        let person = personQuery.data.find(p => p.id === personId);
        if (person === undefined) {
            return "";
        }
        return person?.firstName;// + " " + person?.lastName;
    }

    let spinner = <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        }}
    >
        <CircularProgress color="secondary" />
    </div>

    if (departmentsQuery.isLoading) {
        return (spinner);
    }
    if (personQuery.isLoading) {
        return (spinner);
    }
    if (categoriesQuery.isLoading) {
        return (spinner);
    }

    //console.log("categoriesQuery", categoriesQuery.data);

    return (
        <Box>

            <Stack direction="row" width="100%" spacing="15px">
                <Stack direction="Column" width="60%" spacing="auto">
                    <Box>
                        <AxionControls.AxionTextBox
                            label={formatMessage(messages.title)}
                            onChange={handleTitleChange}
                        />
                    </Box>
                    <Box>
                        <AxionControls.AxionTextBoxMultiline
                            name="Description"
                            label={formatMessage(messages.description)}
                            onChange={handleDescriptionChange}
                        />
                    </Box>
                    <Box padding="7px"> </Box>
                    <Stack direction="row" spacing="15px" sx={{ marginTop: "50px" }}>
                        <Box>
                            <GridDatePicker
                                label={formatMessage(messages.incident_date)}
                                locale="sv"
                                maxDate=""
                                minDate=""
                                onChange={handleIncidentDateChange}
                                date={props.editDate}
                            />
                        </Box>
                        <Box>
                            <GridDatePicker
                                label={formatMessage(messages.target_date)}
                                locale="sv"
                                maxDate=""
                                minDate={Date().toLocaleString()}
                                onChange={handleTargetDateChange}
                            //date={taskObj.targetDate}
                            />
                        </Box>
                    </Stack>
                </Stack>

                <Stack direction="Column" width="40%">
                    <Box>
                        <FormControl sx={{ mt: '12px', width: '100%' }}>
                            <InputLabel id="category-label"><FormattedMessage {...messages.category} /></InputLabel>
                            <Select
                                labelId="category-label"
                                id="category-select"
                                //value={taskObj?.departmentId}
                                label={formatMessage(messages.category)}
                                onChange={handleCategoryChange}
                            // MenuProps={MenuProps}
                            >
                                {categoriesQuery?.data?.find((a) => a.id === props.categoryId)?.values
                                .map((x) => {
                                        return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                                 
                                })}
                                {/* {categoriesQuery?.data?.map((x) => {
                                    var cat = x.values.map((y) => (
                                        <MenuItem key={y.id} value={y.id}>{y.name}</MenuItem>
                                    ));
                                    cat.push(<ListSubheader>{x.name}</ListSubheader>);
                                    return cat;
                                })} */}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <InputLabel id="department-label"><FormattedMessage {...messages.department} /></InputLabel>
                            <Select
                                labelId="department-label"
                                id="department-select"
                                //value={taskObj?.departmentId}
                                label="Department"
                                onChange={handleDepartmentChange}
                            // MenuProps={MenuProps}
                            >
                                {departmentsQuery?.data?.map((d) => (
                                    <MenuItem
                                        key={d.id}
                                        value={d.id}
                                    >
                                        {d.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <InputLabel id="responsible-multiple-chip-label"><FormattedMessage {...messages.responsible} /></InputLabel>
                            <Select
                                labelId="responsible-multiple-chip-label"
                                id="responsible-multiple-chip"
                                //value={taskObj?.responsibleId}
                                onChange={handleResponsibleChange}
                                input={<OutlinedInput id="responsible-select-multiple-chip" label="Select responsible" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        <Chip key={props.taskObj.responsibleId} label={getPersonFullName(props.taskObj.responsibleId)}
                                            //avatar={<Avatar alt="value" src={"../../assets/" + getPersonImg(taskObj.responsibleId)} />}
                                            avatar={<Avatar alt="value" src={getPersonImg(props.taskObj.responsibleId)}  {...stringAvatar(getPersonFullName(props.taskObj.responsibleId), 30)} />}
                                            variant="outlined"
                                        //onDelete={handleParticipantsDelete} 
                                        />
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {personQuery?.data?.filter(f => f.hide !== true).map((p) => (
                                    <MenuItem
                                        key={p.id}
                                        value={p.id}
                                    //   style={getStyles(name, personName, theme)}
                                    >
                                        <Avatar alt="value" src={`data:;base64,${p.imageBase64}`}  {...stringAvatar(p.firstName, 30)} /><Box paddingLeft="7px">{p.firstName}</Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    )

}