import React from 'react';
import { useEffect, useState } from "react";
import Axios from 'axios';
import { getPersonByEmail, setLastLogin} from '../fetchers/person';
import { useMsal, useAccount } from "@azure/msal-react";

const ProfilePicture = () => {

    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState(null);

    const [base64Photo, setBase64Photo] = useState(null);

    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);

    function getImgSrc(imageBase64) {
        var result = "../../../assets/dot_transparent.png";
        if (imageBase64 !== null && imageBase64 !== undefined && imageBase64 !== "") {
            result = `data:;base64,${imageBase64}`
        }
        return result;
    }


    // useEffect(() => {
    //     Axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
    //         responseType: 'blob'
    //     }).then(response => {
    //         if (response.status === 200) {
    //             if (response !== null) {
    //                 const url = window.URL || window.webkitURL;
    //                 setLoading(false)
    //                 setImage(url.createObjectURL(response.data));

    //                 console.log("Profile image:", url.createObjectURL(response.data));
                   
    //                 //let data = 'data:image/jpeg;base64,' + Buffer.from(response.data, 'binary').toString('base64');

    //                 console.log("Profile image b64:", response.data);



    //             } else {
    //                 setLoading(false);
    //                 setImage("../../assets/user.png");

    //             }
    //         } else {
    //             setImage(`../../assets/user.png`);
    //         }
    //     })
    //         .catch((error) => {
    //             //console.log(error);
    //             setImage(`../../assets/user.png`);
    //         });

    // }, []);

    

    useEffect(() => {
        getPersonByEmail(account.username).then((person) => {
            setBase64Photo(person.imageBase64);
        }).catch((error) => {
            console.log(error);
        });

    }, []);

    useEffect(() => {

        console.log("Last login:");

        setLastLogin(account.username).then((person) => {
        }).catch((error) => {
            console.log(error);
        });

    }, []);


    return (<img src={getImgSrc(base64Photo)} alt="profile-user" widht="80px" height="80px" style={{ cursor: "pointer", borderRadius: "50%" }} />)

}
export default ProfilePicture;
