import axios from "axios";

export const getAttachmentsForTask = async (taskId) => {
    const response = await axios.get(`api/tasks/${taskId}/taskattachments`);
    const result = response.data;
    return result;
}

export const postAttachment = async (attachment) => {
    console.log ("Attachment:", attachment);
    const response = await axios.post("api/taskattachments/", attachment);
    const result = response.data;
    return result;
}

export const deleteAttachment = async (attachmentId, attachmentUrl) => {
    const response = await axios.delete(`api/taskattachments/${attachmentId}?imageurl=${attachmentUrl}`);
    const result = response.data;
    return result;
}