
import React, { useState, useEffect } from 'react';
import { useQuery } from "@tanstack/react-query";
import { Box, IconButton, Paper } from "@mui/material";
import AxionControls from "../components/controls/AxionControls";
// import messages from "../components/messages";
// import { FormattedMessage } from "react-intl";

import { styled } from "@mui/system";
import FormLabel from "@mui/material/FormLabel";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import { getTask, postTask } from "../fetchers/task";
import { getPersons } from "../fetchers/person";
import { getTags } from "../fetchers/taskTags";
import { getTypes } from "../fetchers/taskTypes";
import { tokens } from "../assets/theme";

import { Create } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
// import { pushErrorMessage } from "../../../utils/notifications";

import CircularProgress from '@mui/material/CircularProgress';
// import CircularProgress from '@mui/joy/CircularProgress';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions();




const Improvements = () => {

  //************************************ */
  const companyId = 1;
  //************************************ */


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { id } = useParams();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [taskObj, setTaskObj] = useState({
    title: "",
    description: "",
    typeId: 1,
    responsibleId: null,
    participantsList: [],
    tagsList: [],
    // Default values
    progress: 0,
    statusId: 2004,
    companyId: companyId,
    createdBy: 1,
    createdDate: new Date(),
  });
  const [value, setValue] = React.useState([]);

  // const top100Films = [
  //   { title: 'The Shawshank Redemption', year: 1994 },
  //   { title: 'The Godfather', year: 1972 },
  //   { title: 'The Godfather: Part II', year: 1974 }
  // ];

  console.log("Improvements.jsx")


  function formatDate(date) {
    return date ? new Date(date).toLocaleDateString('sv-SE') : "";
  }

  function formatTime(date) {
    return date ? new Date(date).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' }) : "";
  }

  function formatFullTime(date) {
    return date ? new Date(date).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit', second: '2-digit' }) : "";
  }


  //API FETCHERS 
  const taskQuery = useQuery(
    ["task", id],
    () => getTask(id),
    {
      // staleTime: 60000,
      // refetchInterval: 60000
      enabled: id !== undefined && id !== null
    }
  );

  const personQuery = useQuery(
    ["persons"],
    () => getPersons(),
    {
      // staleTime: 60000,
      // refetchInterval: 60000
    }
  );

  const tagsQuery = useQuery(
    ["tags"],
    () => getTags(),
    {
      // staleTime: 60000,
      // refetchInterval: 60000
    }
  );

  const typesQuery = useQuery(
    ["types"],
    () => getTypes(),
    {
      // staleTime: 60000,
      // refetchInterval: 60000
    }
  );

  function getPersonImg(personId) {
    //console.log("getPersonImg:" + personId);
    let person = personQuery.data.find(p => p.id === personId);
    return person?.image;
  }
  function getPersonFullName(personId) {
    let person = personQuery.data.find(p => p.id === personId);
    return person?.firstName;// + " " + person?.lastName;
  }
  function getTagTitle(tagId) {
    let tag = tagsQuery.data.find(t => t.id === tagId);
    return tag?.title;
  }
  function getTypeTitle(typeId) {
    let type = typesQuery.data.find(t => t.id === typeId);
    return type?.title;
  }
  function getTypePriority(typeId) {
    let type = typesQuery.data.find(t => t.id === typeId);
    return type?.priority;
  }

  useEffect(() => {
    if (id !== undefined) {
      console.log("taskQuery.useEffect:" + id);
      let task = { ...taskObj };
      task.description = taskQuery.data?.description;
      task.title = taskQuery.data?.title;
      task.typeId = taskQuery.data?.typeId;
      task.responsibleId = taskQuery.data?.responsibleId;
      task.participantsList = taskQuery.data?.participants.map(p => p.id);
      task.tagsList = taskQuery.data?.tags.map(t => t.id);

      setTaskObj(task);
    }
  }, [taskQuery.isSuccess]);


  const handleSaveClick = () => {
    console.log("handleSaveClick:");
    console.log(taskObj);

    if (id === undefined || id === null) {
      postTask(taskObj).then(() => {
        console.log("Task updated");

      })
        .catch((error) => {
          console.log(error);
          //props.setShowBackdrop(false);
          // pushErrorMessage(`Could not save the task.`);
        });
    } else {
      console.log("Update task - Not implemented yet!");
    }


  }

  const handleTagsChange = (event) => {

    console.log("Tags:");
    console.log(event.target.value);
    let task = { ...taskObj };
    const {
      target: { value },
    } = event;
    let tags = typeof value === 'string' ? value.split(',') : value
    task.tagsList = tags;
    setTaskObj(task);
    // setTagTitle(
    //   // On autofill we get a stringified value.
    //   ,
    // );
  };


  const handleResponsibleChange = (event) => {
    console.log("handleResponsibleChange:" + event.target.value);
    const {
      target: { value },
    } = event;
    let task = { ...taskObj };
    task.responsibleId = value;
    setTaskObj(task);
  };

  const handleParticipantsChange = (event) => {
    console.info('handleParticipantsChange:');
    console.log(event);
    const {
      target: { value },
    } = event;
    let task = { ...taskObj };
    let participants = typeof value === 'string' ? value.split(',') : value
    task.participantsList = participants;
    setTaskObj(task);
    // setParticipantsTitle(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
  };


  const handleDescriptionChange = (event) => {
    let task = { ...taskObj };
    task.description = event.target.value;
    setTaskObj(task);
  };

  const handleTitleChange = (event) => {
    let task = { ...taskObj };
    task.title = event.target.value;
    setTaskObj(task);
  };

  const handleTypeChange = (event) => {
    let task = { ...taskObj };
    task.typeId = event.target.value;
    setTaskObj(task);
  };


  let spinner = <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        }}
    ></div>

  if (taskQuery.isFetching) {
    //return <Box color="white">Loading Tasks...</Box>;
    return spinner;
  }
  if (personQuery.isFetching) {
    //return <Box color="white">Loading Persons...</Box>;
    return spinner;
  }
  if (tagsQuery.isFetching) {
    //return <Box color="white">Loading Tags...</Box>;
    return spinner;
  }
  if (typesQuery.isFetching) {
    ///return <Box color="white">Loading Types...</Box>;
    return spinner;
  }



  console.log("tagsQuery.data:");
  console.log(tagsQuery.data);


  // const typsOptions = taskQuery.data.tags.map((t) => ({ id: t.id, title: t.title }));
  // const typsOptions = data.tags.map((t) => (
  //     <MenuItem
  //       key={t.id}
  //       value={t.title}
  //     //   style={getStyles(name, personName, theme)}
  //     >
  //       {t.title}
  //     </MenuItem>
  //   ));


  // {selected.map((value) => (
  //     <Chip key={value} label={value} onDelete={handleTagsDelete} />
  //   ))}

  // console.log("typsOptions");
  // console.log(typsOptions);

  // setParticipantsTitle([
  //   "Pär Bergström",
  //   "Henrik Ryden"
  // ])

  // console.log("participantsTitle:");
  // console.log(participantsTitle);



  // let content = <Box color="white">{JSON.stringify(taskQuery.data, null, 2)}</Box>;
  let content =
    <>
      {/* <ImprovementsList/> */}

      {/* 
      <Paper
                    sx={{
                        marginTop: "20px",
                        position: "relative",
                        borderRadius: "4px",
                        backgroundColor: "#333333",//colors.primary[700]
                    }}> */}
      <Box color="white" m="20px" width={800} >
        {/* <h3>Improvement task</h3> */}

        {/* <Box height="120px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="100px"
          gap="20px"
        > */}

        <Box>
          <AxionControls.AxionTextBox
            label="Titel" //{<FormattedMessage {...messages.timezone} />}
            value={taskObj.title}
            onChange={handleTitleChange}
          />
        </Box>

        <Box>
          <AxionControls.AxionTextBoxMultiline
            name="Description"
            label="Description" //{<FormattedMessage {...messages.timezone} /> }
            value={taskObj.description}
            onChange={handleDescriptionChange}
          />
        </Box>

        <Box>

          <FormControl sx={{ mt: 2, width: '100%' }}>
            <InputLabel id="tags-multiple-chip-label">Tags</InputLabel>
            <Select
              labelId="tags-multiple-chip-label"
              id="tags-multiple-chip"
              multiple
              value={taskObj.tagsList}
              onChange={handleTagsChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={getTagTitle(value)} /> //onDelete={handleTagsDelete} 
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {tagsQuery.data?.map((t) => (
                <MenuItem
                  key={t.id}
                  value={t.id}
                //   style={getStyles(name, personName, theme)}
                >
                  {t.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* <Box gridColumn="span 4" paddingTop={3}>
          <AxionControls.AxionSelect
            size="small"
            name="responsibleId"
            value={responsibleId}
            label="Ansvarig"
            onChange={handleResponsibleChange1}
            options=//{[{ id: 1, title: 'Kalle' }, { id: 2, title: 'Pelle' }]}
            {personQuery.data.map((p) => (
              {id: p.id, title: p.firstName + " " + p.lastName}
            ))}
          />
        </Box> */}

        <Box gridColumn="span 4">

          <FormControl sx={{ mt: 2, width: '100%' }}>
            <InputLabel id="responsible-multiple-chip-label">Responsible</InputLabel>
            <Select
              labelId="responsible-multiple-chip-label"
              id="responsible-multiple-chip"
              value={taskObj.responsibleId}
              onChange={handleResponsibleChange}
              input={<OutlinedInput id="responsible-select-multiple-chip" label="Select responsible" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  <Chip key={taskObj.responsibleId} label={getPersonFullName(taskObj.responsibleId)}
                    avatar={<Avatar alt="value" src={"../../assets/" + getPersonImg(taskObj.responsibleId)} />}
                    variant="outlined"
                  //onDelete={handleParticipantsDelete} 
                  />
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {personQuery.data.map((p) => (
                <MenuItem
                  key={p.id}
                  value={p.id}
                //   style={getStyles(name, personName, theme)}
                >
                  <Avatar alt="value" src={"../../assets/" + p.image} /><Box paddingLeft="7px">{p.firstName}</Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>


          <FormControl sx={{ mt: 2, width: '100%' }}>
            <InputLabel id="participants-multiple-chip-label">Participants</InputLabel>
            <Select
              labelId="participants-multiple-chip-label"
              id="participants-multiple-chip"
              multiple
              value={taskObj.participantsList}
              onChange={handleParticipantsChange}
              input={<OutlinedInput id="participants-select-multiple-chip" label="Select participants" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((item) => (
                    <Chip key={item} label={getPersonFullName(item)}
                      avatar={<Avatar alt="value" src={"../../assets/" + getPersonImg(item)} />}
                      variant="outlined"
                    //onDelete={handleParticipantsDelete} 
                    />

                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {/* Problemos???? */}
              {personQuery.data?.map((p) => (
                <MenuItem
                  key={p.id}
                  value={p.id}
                //   style={getStyles(name, personName, theme)}
                >
                  <Avatar alt="value" src={"../../assets/" + p.image} /><Box paddingLeft="7px">{p.firstName}</Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>



          {/* <Box paddingTop={3}>

            <AxionControls.AxionSelect
              size="small"
              name="type"
              value={taskObj.typeId}
              label="Type"
              onChange={handleTypeChange}
              options={typesQuery.data.map((t) => ({ id: t.id, title: t.title }))}
            />
          </Box> */}

          {/* </Box> */}

          {/* <Box marginTop={2}>
            <Autocomplete
              value={taskObj.tagsList}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setValue({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setValue({
                    title: newValue.inputValue,
                  });
                } else {
                  setValue(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              multiple
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={taskObj.tagsList}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(props, option) => <li {...props}>{option.title}</li>}
              sx={{ width: "100%" }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Tags" />
              )}
            />
          </Box> */}


          <IconButton onClick={handleSaveClick}><SaveOutlinedIcon /></IconButton>

        </Box>


















        {/* 
      <Box>Created by: {data.createdBy} on {formatFullTime(data.createdDate)}</Box>
      <Box>Updated by: {data.lastUpdatedBy} on {formatDate(data.lastUpdatedDate)}</Box>

     */}




        {/* <Box paddingTop={5}>Förbättring</Box>


        <Box>{data.id} - {data.title}</Box>
        <Box>{data.description}</Box>

        <Box>Progress: {data.progress}%</Box>
        <Box>Status: {data.status.description}</Box>
        <Box>Responsible: {data.responsible.firstName} {data.responsible.lastName}</Box>
        <Box>Target date: {formatDate(data.targetDate)}</Box>
        <Box>Start date: {formatDate(data.startDate)}</Box>
        <Box>End date: </Box>
        <Box>CompanyId: {data.companyId}</Box>
        <Box>Department: {data.department.name}</Box>
        <Box>Group: {data.group.title}</Box> */}
        {/* <Box>Type: {data.type.title}</Box> */}
        {/* <Box>Priority: {data.priority}</Box>
        <Box>Effort: {data.effort}</Box>
        <Box>ParentTask: {data.taskId}</Box> */}


        {/* <Box>Created by: {data.createdBy} on {formatFullTime(data.createdDate)}</Box>
        <Box>Updated by: {data.lastUpdatedBy} on {formatDate(data.lastUpdatedDate)}</Box> */}
        {/* 
        <Box>
            Comments:<br />
            {data.comments.map(c => (<Box>* {c.commentText} by {c.createdBy} on {formatFullTime(c.createdDate)}</Box>))}
        </Box>
        <Box>
            Tags:<br />
            {data.tags.map(c => (<Box> * {c.title} </Box>))}
        </Box>
        <Box>
            Attachments:<br />
            {data.attachments.map(c => (<Box>* {c.file} </Box>))}
        </Box>
        <Box>
            Participants:<br />
            {data.participants.map(c => (<Box>* {c.firstName} {c.lastName}</Box>))}
        </Box> */}






      </Box>
      {/* </Paper> */}
    </>
    ;
  {/*     
    "id": 5,
    "title": "Improve!",
    "description": "Time for some improvements",
    "progress": 10,
    "statusId": 1,
    "responsibleId": 1,
    "targetDate": "2024-07-01T00:00:00",
    "startDate": "2024-06-01T00:00:00",
    "endDate": "2024-07-01T00:00:00",
    "companyId": 1,
    "departmentId": 1,
    "groupId": 1,
    "typeId": 1,
    "priority": 1,
    "effort": 123,
    "taskId": null,
    "createdBy": 1,
    "createdDate": "2024-05-15T00:00:00",
    "lastUpdatedBy": null,
    "lastUpdatedDate": null, */}



  // {JSON.stringify(taskQuery.data, null, 2)}

  //let content = <Box color="white">Hello!</Box>;

  console.log(content);






  return (
    <>
      {content}

    </>
  )

}


export default Improvements