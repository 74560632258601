// Example of how to use the component:
// <AxionConfirmation open={openConfirmation} title={confirmationTitle} message={confirmationMessage} onConfirm={doDelete} onCancel={cancelDelete} cancelText="No" confirmText="Yes"/>

import React, { useState, useEffect, useMemo } from "react";
import { Button, useTheme, Box, IconButton, Stack } from "@mui/material";
import { tokens } from "../../assets/theme";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AxionControls from "./AxionControls";
import { deleteLink, postLink } from "../../fetchers/taskLink";
import Controls from "./Contols";
import SaveIcon from '@mui/icons-material/Save';
import { pushErrorMessage } from "../../utils/notifications";

import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

export default function AxionConfirmation(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    //console.log("AxionConfirmation: open: ", props.open);

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            //TransitionProps={{ onEntering: handleEntering }}
            open={props.open}
        //{...other}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent dividers>
                {props.message}
            </DialogContent>
            <DialogActions >
                <Stack direction="row" spacing={1} display="flex" padding="10px">
                    <Controls.Button
                        type="submit"
                        text={props.confirmText}
                        onClick={props.onConfirm}
                        startIcon={<CheckBoxOutlinedIcon />}
                        sx={{
                            "&:hover": {
                                backgroundColor: colors.dashbordGreen[700],
                            },
                            backgroundColor: colors.dashbordGreen[500],
                            color: "#000000",
                        }}
                    />
                    <Controls.Button
                        type="submit"
                        text={props.cancelText}
                        onClick={props.onCancel}
                        startIcon={<CancelOutlinedIcon />}
                        sx={{
                            "&:hover": {
                                backgroundColor: colors.redAccent[700],
                            },
                            backgroundColor: colors.redAccent[500],
                            color: "#000000",
                        }}
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    );


}