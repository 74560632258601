import AxionMultiLineText from "./AxionMultiLineText";
import AxionTextBox from "./AxionTextBox";
import AxionTextBoxMultiline from "./AxionTextBoxMultiline";
import AxionSelect from "./AxionSelect";
import AxionTextBoxReadOnly from "./AxionTextBoxReadOnly";
import AxionAutoComplete from "./AxionAutocomplete";
 
const AxionControls = {
  AxionMultiLineText,
  AxionTextBox,
  AxionTextBoxMultiline,
  AxionSelect,
  AxionTextBoxReadOnly,
  AxionAutoComplete,
  
};
export default AxionControls;