import React, { useState, useEffect, useMemo } from "react";
import { Button, useTheme, Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { tokens } from "../../../assets/theme";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AxionControls from "../../../components/controls/AxionControls";
import { deleteLink, postLink } from "../../../fetchers/taskLink";
import Controls from "../../../components/controls/Contols";
import SaveIcon from '@mui/icons-material/Save';
import { pushErrorMessage } from "../../../utils/notifications";
import AxionConfirmation from '../../../components/controls/AxionConfirmation';
//Language
import messages from "../messages";
import { FormattedMessage, useIntl } from "react-intl";

export default function ImprovementAddLink(props) {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [newLink, setNewLink] = useState({ taskId: props.taskId, description: "", linkUrl: "" });
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [linkId, setLinkId] = useState(null);


    const doDeleteLink = () => {
        setOpenConfirmation(false);
        deleteLink(linkId).then(() => {
            props.task.refetch();
            //setOpenPopup(false);
            //data.refetch();
            //setShowBackdrop(false);
        }).catch((error) => {
            console.log(error);
            //setShowBackdrop(false);
            pushErrorMessage(`Could not delete Link.`);
        })
    }

    const handleDeleteLink = (id) => {
        setLinkId(id);
        setConfirmationTitle("Delete Link");
        setConfirmationMessage("Are you sure you want to delete this link?");
        setOpenConfirmation(true);
    }

    const cancelDeleteLink = () => {
        setOpenConfirmation(false);
        setConfirmationTitle("");
        setConfirmationMessage("");
    }

    const handleAddLink = () => {
        postLink(newLink).then(() => {
            props.task.refetch();
            //setOpenPopup(false);
            //data.refetch();
            //setShowBackdrop(false);
        }).catch((error) => {
            console.log(error);
            //setShowBackdrop(false);
            pushErrorMessage(`Could not create Link.`);
        });
    }

    const handleLinkUrlChange = (event) => {
        let link = { ...newLink };
        link.linkUrl = event.target.value;

        setNewLink(link);
    }

    const handleLinkDescriptionChange = (event) => {
        let link = { ...newLink };
        link.description = event.target.value;

        setNewLink(link);
    }

    return (
        <Box>
            <Stack direction="column" width="100%" overflow="hidden" >
                <Box><AxionControls.AxionTextBox
                    label={<FormattedMessage {...messages.link_description} />}
                    //value={}
                    autoComplete="new-password"
                    inputProps={{ autoComplete: 'new-password' }}
                    onChange={(event) =>
                        handleLinkDescriptionChange(event)
                    }
                /></Box>
                <Box><AxionControls.AxionTextBox
                    label={<FormattedMessage {...messages.link_url} />}
                    autoComplete="new-password"
                    inputProps={{ autoComplete: 'new-password' }}
                    onChange={(event) =>
                        handleLinkUrlChange(event)
                    }
                /></Box>
                <Box width="100%" justifyContent="flex-end" alignItems="center" display="flex" marginTop="10px">
                    <Controls.Button
                        text={<FormattedMessage {...messages.add_link} />}
                        onClick={handleAddLink}
                        startIcon={<SaveIcon />}
                        sx={{
                            "&:hover": {
                                backgroundColor: colors.dashbordGreen[700],
                            },
                            backgroundColor: colors.dashbordGreen[500],
                            color: "#000000",
                        }}
                    />
                </Box>
            </Stack>

            {(props?.links?.length > 0) && <Box marginTop="20px"><FormattedMessage {...messages.current_links} /></Box>}
            <Stack direction="column" width="100%" overflow="hidden" backgroundColor={colors.primary[600]} marginTop="5px">
                {props?.links?.map((a) => {
                    return (
                        <Stack direction="row" width="100%" marginTop="1px" backgroundColor={colors.primary[400]}>
                            <Box width="70%" alignItems="center" display="flex" marginLeft="10px">{a.description}</Box>
                            <Box width="30%" justifyContent="flex-end" alignItems="center" display="flex">
                                <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.delete_link} /></Typography>}>
                                    <IconButton onClick={() => { handleDeleteLink(a.id) }} ><DeleteOutlinedIcon /></IconButton>
                                </Tooltip>
                            </Box>
                        </Stack>
                    )
                })}
            </Stack>

            <AxionConfirmation open={openConfirmation} title={confirmationTitle} message={confirmationMessage} onConfirm={doDeleteLink} onCancel={cancelDeleteLink} cancelText="No" confirmText="Yes" />
        </Box>
    )

}