import axios from "axios";

export const getTags = async (companyId) => {
    const response = await axios.get(`api/tasktags?companyId=${companyId}`);
    const tags = response.data;
    return tags;
}

export const postTag = async (data) => {
    const response = await axios.post("api/tasktags", data);
    const tags = response.data;
    return tags;
}