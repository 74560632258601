/*
 * Home Messages
 *
 * This contains all the text for the Home Feature.
 */
import { defineMessages } from 'react-intl';

export const scope = 'axioninsight.features.home.index';

export default defineMessages({
  pagesLabel: {
    id: `${scope}.sidebar.label.pages`,
    defaultMessage: 'Pages',
  },
  homeMenuItem: {
    id: `${scope}.sidebar.home`,
    defaultMessage: 'Home',
  },
  forecastDataMenuItem: {
    id: `${scope}.sidebar.forecastData`,
    defaultMessage: 'Forcast data',
  },
  settingsLabel: {
    id: `${scope}.sidebar.label.settings`,
    defaultMessage: 'Settings',
  },
  usersMenuItem: {
    id: `${scope}.sidebar.users`,
    defaultMessage: 'Users',
  },
  systemSettingsMenuItem: {
    id: `${scope}.sidebar.systemSettings`,
    defaultMessage: 'System Settings',
  },
  rolesMenuItem: {
    id: `${scope}.sidebar.roles`,
    defaultMessage: 'Roles',
  },
  dashboardSalesPersonMenuItem: {
    id: `${scope}.sidebar.dashboardsalesperson`,
    defaultMessage: 'Dashboard salesperson',
  },
  newMenuItem: {
    id: `${scope}.sidebar.newMenuItem`,
    defaultMessage: 'New',
  },
  listMenuItem: {
    id: `${scope}.sidebar.listMenuItem`,
    defaultMessage: 'List',
  },
  boardMenuItem: {
    id: `${scope}.sidebar.boardMenuItem`,
    defaultMessage: 'Board',
  },
  dashboardMenuItem: {
    id: `${scope}.sidebar.dashboardMenuItem`,
    defaultMessage: 'Dashboard',
  },
  incidentsMenuItem: {
    id: `${scope}.sidebar.incidentsMenuItem`,
    defaultMessage: 'Incidents',
  },
  dimensionsMenuItem: {
    id: `${scope}.sidebar.dimensionsMenuItem`,
    defaultMessage: 'Dimensions',
  },
  usersMenuItem: {
    id: `${scope}.sidebar.usersMenuItem`,
    defaultMessage: 'Users',
  },
  settingsMenuItem: {
    id: `${scope}.sidebar.settingsMenuItem`,
    defaultMessage: 'Settings',
  },
  signoutMenuItem: {
    id: `${scope}.sidebar.signoutMenuItem`,
    defaultMessage: 'Sign out',
  },

  //FILTERS
  manualFilter: {
    id: `${scope}.grid.filters.manual`,
    defaultMessage: 'Manual',
  },

});
