import React, { useEffect, useState } from "react";
import { useTheme, Box, Stack, Tooltip, Checkbox, CircularProgress, Avatar, Chip, Badge, Link, IconButton, Typography, Button, Divider, Select, MenuItem } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { tokens } from "../../assets/theme";

import Controls from "../../components/controls/Contols";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from "react-router-dom";
import { useMsal, useAccount } from "@azure/msal-react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
//Components
import { pushErrorMessage, pushNotification } from "../../utils/notifications";
import AxionConfirmation from '../../components/controls/AxionConfirmation';
import ImprovementAddTime2 from './components/IncidentsAdd';

//Hooks
import { getAllTaskIncidents, } from "../../fetchers/taskIncident";
import { patchTask } from "../../fetchers/task";
import { getUsers, syncUsers, hideUser } from "../../fetchers/users";
import { getPersons } from "../../fetchers/person";
import { postTask, copyTask } from "../../fetchers/task";
import { getCategories } from "../../fetchers/incidentCategory";
//Icons
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import StarRateOutlinedIcon from '@mui/icons-material/StarRateOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import LinkIcon from '@mui/icons-material/Link';
import AddLinkIcon from '@mui/icons-material/AddLink';

//Language
import messages from "./messages";
import { FormattedMessage, useIntl } from "react-intl";


export default function IncidentsCalendar(props) {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [userToHide, setUserToHide] = useState(null);
    const [openProgress, setOpenProgress] = useState(false);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [category, setCategory] = useState(0);
    const [matrix, setMatrix] = useState([]);
    const [list, setList] = useState([]);
    const [openIncident, toggleOpenIncident] = React.useState(false);
    const [editDate, setEditDate] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [markDate, setMarkDate] = useState({date: null, category: null});
    let initTask = {
        id: null,
        title: "",
        description: "",
        progress: null,
        statusId: 0,
        priority: 1,
        statusDescription: null,
        responsibleId: null,
        targetDate: null,
        startDate: null,
        endDate: null,
        companyId: companyKey,
        departmentId: null,
        incidentCategoryId: null,
        incidentCategoryValueId: null,
        groupId: null,
        typeId: 1, //Incident
        effort: null,
        estimatedEffort: null,
        savingsValue: null,
        savingsTime: null,
        quickFix: null,
        incidentDate: null,
        taskId: null,
        createdBy: 13,
        createdDate: null,
        lastUpdatedBy: null,
        lastUpdatedDate: null,
        //commentsList: [],
        tagsList: [],
        //attachmentsList: [],
        participantsList: [],
        dimensionValuesList: [],

    };
    const [taskObj, setTaskObj] = useState(initTask);
    let matrixAll = [];
    let currentIncidents = [];

    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);

    const taskQuery = useQuery(
        ["task"],
        () => getAllTaskIncidents(companyKey, year),
        {
            // staleTime: 60000,
            //refetchInterval: 60000
        }
    );
    const personQuery = useQuery(
        ["persons"],
        () => getPersons(),
        {}
    );
    const categoryQuery = useQuery(
        ["categories"],
        () => getCategories(companyKey),
        {}
    );

    useEffect(() => {
        //console.log("IncidentsCalendar useEffect");
        setCategory(0);
        setMatrix(matrixAll);
        setList(currentIncidents);
    }, [month]);

    let spinner = <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            //height: "100vh",
        }}
    >
        <CircularProgress color="secondary" />
    </div>

    const handleOpenDialog = (date, categoryId) => {
        setTaskObj(initTask);
        setEditDate(date);
        setCategoryId(categoryId);
        if (date < new Date()) {

            toggleOpenIncident(true);
        } else {
            pushNotification(formatMessage(messages.future_message));
        }
    };

    const handleCloseIncidentsDialog = () => {
        toggleOpenIncident(false);
    };

    const handleCloseIncident = (taskId, status) => {
        let newStatusId = status === 5 ? 0 : 5;
        const moveObj = { taskId: taskId, statusId: newStatusId };

        patchTask(moveObj).then(() => {
            taskQuery.refetch();
        })
            .catch((error) => {
                console.log(error);
                pushErrorMessage(formatMessage(messages.error_close_message));
            });
    }

    const handleCopyTask = (taskId) => {
        copyTask(taskId).then((result) => {
            //console.log("copyTask", result);
            navigate("../improvementFullPage/" + result)
            ///taskQuery.refetch();
        })
            .catch((error) => {
                console.log(error);
                pushErrorMessage(formatMessage(messages.error_copy_message));
            });
    }


    const handleSaveIncidents = (event) => {
        //Validation
        if (taskObj.title === "" || taskObj.title === null || taskObj.title === undefined) {
            pushErrorMessage(formatMessage(messages.verify_title));
            return;
        }
        if (taskObj.description === "" || taskObj.description === null || taskObj.description === undefined) {
            pushErrorMessage(formatMessage(messages.verify_description));
            return;
        }
        if (taskObj.incidentCategoryId === null || taskObj.incidentCategoryId === undefined) {
            pushErrorMessage(formatMessage(messages.verify_category));
            return;
        }
        if (taskObj.departmentId === null || taskObj.departmentId === undefined) {
            pushErrorMessage(formatMessage(messages.verify_department));
            return;
        }
        if (taskObj.typeId === null || taskObj.typeId === undefined) {
            pushErrorMessage(formatMessage(messages.verify_type));
            return;
        }
        if (taskObj.responsibleId === null || taskObj.responsibleId === undefined) {
            pushErrorMessage(formatMessage(messages.verify_responsible));
            return;
        }

        let userId = getPersonId(account.username);
        taskObj.createdBy = userId;
        taskObj.incidentDate = formatDate(editDate);
        taskObj.companyId = companyKey;

        postTask(taskObj)
            .then(response => {
                toggleOpenIncident(false);
                taskQuery.refetch();
                setCategory(category);
            })
            .catch((error) => {
                console.log(error);
                //props.setShowBackdrop(false);
                pushErrorMessage(formatMessage(messages.error_create_message));
            });
    };

    const handleMarkDate = (date, category) => {    
        setMarkDate({date: date, category: category});
    }
    const handleUnmarkDate = () => {
        setMarkDate({date: null, category: null});
    }

    if (taskQuery.isLoading) {
        return spinner;
    }
    if (personQuery.isLoading) {
        return spinner;
    }
    if (categoryQuery.isLoading) {
        return spinner;
    }

    let firstDayOfMonth = getFirstDayOfMonth(year, month);
    let lastDayOfMonth = new Date(year, month, 0).getDate();

    function getFirstDayOfMonth(year, month) {
        let date = new Date(year, month - 1, 1);
        return date.getDay();
    }

    let months = [];
    let incidents = taskQuery.data?.filter(f => f.type?.id === 1);
    currentIncidents = incidents?.filter(f => f?.incidentDate !== null);
    currentIncidents = orderListByDate(currentIncidents?.filter(f => new Date(f?.incidentDate).getFullYear() === year && new Date(f?.incidentDate).getMonth() + 1 === month));

    let weekDays = [formatMessage(messages.mon),
    formatMessage(messages.tue),
    formatMessage(messages.wed),
    formatMessage(messages.thu),
    formatMessage(messages.fri),
    formatMessage(messages.sat),
    formatMessage(messages.sun)
    ];

    let weekShortDays = [weekDays[0].charAt(0),
    weekDays[1].charAt(0),
    weekDays[2].charAt(0),
    weekDays[3].charAt(0),
    weekDays[4].charAt(0),
    weekDays[5].charAt(0),
    weekDays[6].charAt(0),
    ];

    let allmatrix = [];

    categoryQuery.data?.filter(x => x.id !== 1).sort((a, b) => a.sortorder - b.sortorder).map((c) => {
        allmatrix.push({ id: c.id, name: c.name, matrix: generateMatrix(currentIncidents.filter(i => i.incidentCategoryId === c.id), c.id, null, false) });
    });

    generateMonths(incidents.filter(f => new Date(f.incidentDate).getFullYear() === year));

    function getPersonId(email) {
        let person = personQuery.data.find(p => p.email.toLowerCase() === email.toLowerCase());
        return person?.id;
    }
    function orderListByDate(list) {
        return list.sort((a, b) => new Date(a.incidentDate) - new Date(b.incidentDate));
    }
    function orderListBySortorder(list) {
        return list.sort((a, b) => new Date(a.sortorder) - new Date(b.sortorder));
    }

    const handleSetCategory = (cat) => {
        if (cat === category) {
            setCategory(0);
            setMatrix(matrixAll);
            setList(currentIncidents);
            return;
        }
        setCategory(cat);
    }


    function formatDate(date) {
        return date ? new Date(date).toLocaleDateString('sv-SE') : "";
    }

    function formatDateLocalized(date) {
        let locale = companyKey === 2 ? 'en-US' : 'sv-SE';
        return date ? new Date(date).toLocaleDateString(locale) : "";
    }

    function getIncidentsCount(date, inc, cat) {

        //console.log("getIncidentsCount", inc);
        if (cat === 0 || cat === null) {
            return inc.filter(f => new Date(f.incidentDate).getDate() === date.getDate()).length;
        }
        let result = { count: 0, color: "", colors: "" };
        let data = inc.filter(f => new Date(f.incidentDate).getDate() === date.getDate() && f.incidentCategoryId === cat);
        result.count = data.length;
        if (data.length > 0) {
            let sorted = data.sort((a, b) => a.categoryValue.severityLevel - b.categoryValue.severityLevel);
            result.color = sorted[0].categoryValue?.color || "";
            if (data.length > 1) {
                let colors = sorted.map(s => s.categoryValue.color);
                let distinctColors = Array.from(new Set(colors));
                distinctColors.shift();
                result.colors = distinctColors;
            }
        }
        return result;
    }

    function getIncidentsCountMonth(year, month, inc) {
        let count = inc.filter(f => new Date(f.incidentDate).getFullYear() === year && new Date(f.incidentDate).getMonth() + 1 === month).length;
        //console.log("month...", month, count);
        return count;
    }

    function isDateAfterToday(y, m, d) {
        let date = new Date(y, m - 1, d);
        let today = new Date();
        return date > today;
    }

    function getMonthColor(m) {
        if (m === month) {
            return colors.primary[900];
        } else {
            return colors.primary[100];
        }
    }

    function getMonthBGColor(m) {
        if (m === month) {
            return colors.primary[100];
        } else {
            return colors.primary[400];
        }
    }


    function getFirstWeekDayOfMonth(date) {
        let firstDay = new Date(date.getFullYear(), 10, 1);


        return firstDay.getDay();
    }

    function getPersonImg(personId) {
        let person = personQuery.data.find(p => p.id === personId);
        return `data:;base64,${person?.imageBase64}`;
    }
    function getPersonFullName(personId) {
        let person = personQuery.data.find(p => p.id === personId);
        if (person === undefined) {
            return "";
        }
        return person?.firstName;// + " " + person?.lastName;
    }

    //let firstDayOfMonth = getFirstDayOfMonth(new Date().getFullYear(), new Date().getMonth());

    function generateMatrix(inc, cat1, cat2, bigMatrix = false) {
        let matrix = [];
        let row = [];
        let day = { color: "", colorBig: "", incidents: 0, incidents2: null, today: false, nr: "", border: 0, borderColor: "", category: "", colors: [] };
        let tempDay = { ...day };
        let dayCount = 1;
        let calDay = 0;
        for (let j = 0; j < 6; j++) {
            row = [];
            for (let i = 0; i < 7; i++) {
                tempDay = { ...day };
                let valueColor = getIncidentsCount(new Date(year, month - 1, dayCount), inc, cat1);

                if (firstDayOfMonth === 0 && calDay < 6) {
                    tempDay.color = "";
                    tempDay.nr = "";
                }
                // First day is tuesday
                else if (calDay < firstDayOfMonth - 1) {
                    tempDay.color = "";
                    tempDay.nr = "";
                }
                else {
                    let count = valueColor.count; // + (count2 || 0);

                    if (count > 0) {
                        tempDay.color = valueColor.color;
                        tempDay.colors = valueColor.colors;
                    } else {
                        // If selected, If none selected or the bix matrix: bright green else dark green
                        if (category === 0 || category === cat1 || bigMatrix) {
                            tempDay.color = colors.dashbordGreen[600];
                        } else {
                            tempDay.color = colors.dashbordGreen[900];
                        }
                    }
                    tempDay.nr = dayCount;
                    tempDay.incidents = count;
                    dayCount++;
                }
                if (isDateAfterToday(year, month, dayCount - 1)) {
                    tempDay.color = colors.primary[300];
                }

                calDay++;
                row.push(tempDay);
                if (dayCount > lastDayOfMonth) {
                    let lastDays = { ...day };
                    for (let k = i; k < 6; k++) {
                        row.push(lastDays);
                    }
                    break;
                }
            }
            matrix.push(row);
            if (dayCount > lastDayOfMonth) {
                break;
            }
        }
        return matrix;
    }

    function generateMonths(inc) {
        for (let i = 0; i < 12; i++) {
            let month = { id: i + 1, name: getMonthName(i + 1), incidents: getIncidentsCountMonth(year, i + 1, inc) };
            months.push(month);
        }
    }

    function getMonthName(id) {
        switch (id) {
            case 1:
                return formatMessage(messages.jan);
            case 2:
                return formatMessage(messages.feb);
            case 3:
                return formatMessage(messages.mar);
            case 4:
                return formatMessage(messages.apr);
            case 5:
                return formatMessage(messages.may);
            case 6:
                return formatMessage(messages.jun);
            case 7:
                return formatMessage(messages.jul);
            case 8:
                return formatMessage(messages.aug);
            case 9:
                return formatMessage(messages.sep);
            case 10:
                return formatMessage(messages.oct);
            case 11:
                return formatMessage(messages.nov);
            case 12:
                return formatMessage(messages.dec);
            default:
                return "";
        }
    }

    function getCategoryName(id) {
        let cat = categoryQuery.data?.find(c => c.id === id);
        return cat?.name;
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name, boxSize) {
        if (name === undefined || name === null || name === "" || name.split(' ').length < 2) {
            name = "N N";
        }
        return {
            sx: {
                bgcolor: stringToColor(name),
                border: 1,
                height: boxSize,
                width: boxSize,
                borderColor: "#000000"
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    function getTextColor(backgroundcolor) {
        let r = parseInt(backgroundcolor.substring(1, 3), 16);
        let g = parseInt(backgroundcolor.substring(3, 5), 16);
        let b = parseInt(backgroundcolor.substring(5, 7), 16);
        let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        //return (yiq >= 128) ? 'black' : 'white';
        return (yiq >= 200) ? 'black' : 'white';
    }

    function getCategoryValuesForCategory(catId) {
        //console.log ("categoryQuery", categoryQuery.data, catId);

        return categoryQuery.data?.find(c => c.id === catId)?.values;
    }

    // Initialize data
    matrixAll = generateMatrix(currentIncidents, null, null, true); // Tillbud & Olyckor
    let years = [];
    let currentYear = new Date().getFullYear();
    for (let i = 2024; i < currentYear + 2; i++) {
        years.push(i);
    }

    //console.log ("currentIncidents", currentIncidents);
    // console.log("matrix:", matrix);
    // console.log("month:", month);
    // console.log("months:", months);
    //console.log("displayList", displayList);
    // console.log("taskQuery.data", taskQuery.data);
    // console.log("Categories", categoryQuery.data);
    // console.log("allmatrix", allmatrix);

    return (<>

        <Stack marginLeft="1px" direction="row" spacing="1px" width="" marginBottom="5px" alignItems="center" marginTop="30px">
            {months.map((m) => (
                <Stack width="100px" direction="row" justifyContent="center" display="flex" padding="3px" paddingLeft="10px" spacing="1px">
                    <Box>
                        <Badge badgeContent={m.incidents} color="success" sx={{ top: '-3px', right: '-2px', color: colors.primary[100] }}>
                            <Chip key={m.id} size="medium" label={m.name} variant="outlined" sx={{ backgroundColor: getMonthBGColor(m.id), color: getMonthColor(m.id) }} onClick={() => setMonth(m.id)} />
                        </Badge>
                    </Box>

                </Stack>
            ))}
            <Box padding="10px"> </Box>
            <Select value={year} onChange={(e) => setYear(e.target.value)} >
                {years.map((y) => (
                    <MenuItem key={y} value={y} >{y}</MenuItem>
                ))}
            </Select>
        </Stack>

        <Box height="500px"
            display="grid"
            gridTemplateColumns="repeat(10, 1fr)"
            gridAutoRows="100px"
            gap="15px"
            marginLeft="15px"
            marginRight="15px"
        >
            {allmatrix.map((c) => (
                <Box gridColumn="span 2">
                    <Stack direction="column" marginTop="40px" display="flex" justifyContent="center" spacing="2px" alignItems="center" >
                        <Stack display="flex" justifyContent="flex-end" backgroundColor={colors.primary[400]} width="100%" direction="column">
                            <Box paddingTop="3px" paddingRight="3px" marginBottom="-10px" display="flex" justifyContent="flex-end" width="100%" >
                                <Tooltip title={<Typography fontSize="12px">
                                    <Stack direction="column" spacing="5px">
                                    {getCategoryValuesForCategory(c.id).sort((a, b) => a.severityLevel - b.severityLevel).map((v) => (
                                        <Box><CircleIcon fontSize="small" sx={{color: v.color, marginRight: '10px'}}/>{v.name}</Box>
                                    ))}
                                    </Stack>
                                    </Typography>}>
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            </Box>
                            <Box paddingBottom="10px" display="flex" justifyContent="center" width="100%" >
                                <Typography fontSize="16pt">{c.name}</Typography>
                            </Box>

                        </Stack>
                        <Stack direction="column" spacing="1px" backgroundColor={colors.primary[400]} width="100%" padding="20px">

                            <Stack direction="row" spacing="1px" width="100%" display="flex" justifyContent="center" >
                                {weekDays.map((d) => (
                                    <Box width="45px" justifyContent="center" display="flex" fontSize={12}>{d}</Box>
                                ))}
                            </Stack>
                            {c.matrix.map((a) => (
                                <Stack direction="row" spacing="1px" width="100%" display="flex" justifyContent="center">
                                    {a.map((b) => (
                                        <Box sx={{ backgroundColor: b.color, border: b.border, borderColor: b.borderColor }} width="45px" height="40px" display="flex" fontSize={9} fontFamily="sans-serif" onClick={() => { handleOpenDialog(new Date(year, month - 1, b.nr), c.id) }}  >
                                            {/* onMouseEnter={() => handleMarkDate(formatDate(new Date(year, month - 1, b.nr)), c.id)} onMouseLeave={handleUnmarkDate} */}
                                            <Stack direction="column" display="flex" alignItems="center" justifyItems="center" width="100%">
                                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start" width="100%" height="50%" padding="2px" color={getTextColor(b.color)}>{b.nr}</Box>
                                                <Badge badgeContent={b.incidents} color="primary" overlap="circular" sx={{ right: '-2px' }}>
                                                    <Box width="20%"> </Box>
                                                </Badge>
                                                <Box display="flex" justifyContent="center" alignItems="flex-end" width="100%" height="50%">
                                                    {b.colors.length > 0 ?
                                                        b.colors.map((c) => (
                                                            <Box sx={{ border: 1, borderColor: '#000000' }} backgroundColor={c} width="45%" height="7px" margin="1px"> </Box>
                                                        )) : ""}
                                                </Box>
                                            </Stack>
                                        </Box>
                                    ))}
                                </Stack>
                            ))}
                        </Stack>
                        <Stack direction="column" spacing="2px" width="100%" marginTop="10px" >
                            {currentIncidents.filter(i => i.incidentCategoryId === c.id).sort((a, b) => new Date(b.incidentDate) - new Date(a.incidentDate)).sort((a, b) => a.status.id - b.status.id).map((i) => (
                                <>
                                    <Stack direction="row" display="flex" spacing="1px" width="100%" justifyItems="center" justifyContent="center" backgroundColor={colors.primary[400] }>
                                    {/*Hantera markering av datum (markDate.date === formatDate(i.incidentDate) && markDate.category === c.id) ? colors.primary[300] : colors.primary[400] */}
                                        <Box width="4px" backgroundColor={i.categoryValue?.color}></Box>
                                        <Box width="10px" ></Box>
                                        <Stack direction="column" width="100%">
                                            <Box marginTop="5px">{i.title}</Box>
                                            <Stack direction="row" spacing="auto" display="flex" alignItems="center">
                                                <Stack direction="row" spacing="10px" display="flex" alignItems="center" >
                                                    <Box fontSize={10}>{formatDateLocalized(i.incidentDate)}</Box>
                                                    <Box fontSize={10}>{i.department?.name}</Box>
                                                </Stack>
                                                <Stack direction="row" spacing="-10px" display="flex" alignItems="center" justifyItems="flex-end" >
                                                    <Box paddingRight="10px" >
                                                        <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.responsible} />: {getPersonFullName(i.responsibleId)}</Typography>}>
                                                            <Avatar alt="value" src={getPersonImg(i.responsibleId)} {...stringAvatar(getPersonFullName(i.responsibleId), 17)} />
                                                        </Tooltip>
                                                    </Box>
                                                    {(i.improvementId === null || i.improvementId === undefined) ? //if a linkled improvement does not exists
                                                        <IconButton onClick={() => handleCopyTask(i.id)} underline="hover">
                                                            <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.create_linked_improvement} /></Typography>}>
                                                                <AddLinkIcon fontSize="small" sx={{ color: colors.primary[900] }} />
                                                            </Tooltip>
                                                        </IconButton>
                                                        :
                                                        <IconButton onClick={() => navigate("../improvementFullPage/" + i.improvementId)} underline="hover">
                                                            <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.show_linked_improvement} /></Typography>}>
                                                                <LinkIcon fontSize="small" />
                                                            </Tooltip>
                                                        </IconButton>
                                                    }
                                                    <IconButton onClick={() => navigate("../improvementFullPage/" + i.id)} underline="hover" color="#ffffff">
                                                        <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.edit_message} /></Typography>}>
                                                            <ModeEditOutlineOutlinedIcon fontSize="small" />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <IconButton onClick={() => handleCloseIncident(i.id, i.status.id)} underline="hover" color="#ffffff">
                                                        <Tooltip title={<Typography fontSize="12px">{i.status.id === 5 ? formatMessage(messages.open_message) : formatMessage(messages.close_message)}</Typography>}>
                                                            {i.status.id === 5 ? <CircleIcon fontSize="small" color="error" /> : <CircleOutlinedIcon fontSize="small" color="success" />}
                                                        </Tooltip>
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </>
                            ))}
                        </Stack>
                    </Stack>
                </Box>
            ))}
        </Box>

        <Box marginTop="50px"> </Box>

        <Dialog open={openIncident} onClose={handleCloseIncidentsDialog} maxWidth="md" >
            <Box m={0} sx={{ backgroundColor: colors.primary[400], borderRadius: "4px" }} width="700px" >

                <DialogTitle><FormattedMessage {...messages.create_incident} /></DialogTitle>
                <DialogContent>
                    <ImprovementAddTime2 taskObj={taskObj} setTaskObj={setTaskObj} editDate={editDate} categoryId={categoryId} />
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleCloseIncidentsDialog}
                        sx={{
                            "&:hover": {
                                backgroundColor: "#535560",
                            },
                            backgroundColor: colors.primary[300],
                            color: "#000000",
                            marginBottom: "15px",
                            marginRight: "15px"
                        }}>Close</Button>
                    <Button onClick={handleSaveIncidents}
                        sx={{
                            "&:hover": {
                                backgroundColor: "#535560",
                            },
                            backgroundColor: colors.primary[300],
                            color: "#000000",
                            marginBottom: "15px",
                            marginRight: "15px"
                        }}>Save</Button>
                </DialogActions>
            </Box>
        </Dialog>







    </>)
}