import { defineMessages } from 'react-intl';

export const scope = 'axionimprovements.features.dashboard';

export default defineMessages({

    open_improvements: {
        id: `${scope}.labels.open_improvements`,
        defaultMessage: 'Open improvements',
    },
    days_sice_last_incident: {
        id: `${scope}.labels.days_sice_last_incident`,
        defaultMessage: 'Days since last incident',
    },
    closed_on_budget: {
        id: `${scope}.labels.closed_on_budget`,
        defaultMessage: 'Closed on budget',
    },
    closed_on_time: {
        id: `${scope}.labels.closed_on_time`,
        defaultMessage: 'Closed on time',
    },
    over_due: {
        id: `${scope}.labels.over_due`,
        defaultMessage: 'Over due',
    },
    over_estimate: {
        id: `${scope}.labels.over_estimate`,
        defaultMessage: 'Over estimate',
    },
    in_status_per_department: {
        id: `${scope}.labels.in_status_per_department`,
        defaultMessage: 'In status per department',
    },
    top_most_over_estimated_effort: {
        id: `${scope}.labels.top_most_over_estimated_effort`,
        defaultMessage: 'Top most over estimated effort',
    },
    top_most_over_due: {
        id: `${scope}.labels.top_most_over_due`,
        defaultMessage: 'Top most over due',
    },
    incidents_per_department: {
        id: `${scope}.labels.incidents_per_department`,
        defaultMessage: 'Incidents per department',
    },

    department: {
        id: `${scope}.labels.department`,
        defaultMessage: 'Department',
    },
    improvement: {
        id: `${scope}.labels.improvement`,
        defaultMessage: 'Improvement',
    },
    time: {
        id: `${scope}.labels.time`,
        defaultMessage: 'Time',
    },
    estimated: {
        id: `${scope}.labels.estimated`,
        defaultMessage: 'Estimated',
    },
    diff: {
        id: `${scope}.labels.diff`,
        defaultMessage: 'Diff',
    },
    target: {
        id: `${scope}.labels.target`,
        defaultMessage: 'Target',
    },
    days_over_due: {
        id: `${scope}.labels.days_over_due`,
        defaultMessage: 'Days over due',
    },
    total: {
        id: `${scope}.labels.total`,
        defaultMessage: 'Total',
    },
    status_new: {
        id: `${scope}.labels.status_new`,
        defaultMessage: 'New',
    },
    status_plan: {
        id: `${scope}.labels.status_plan`,
        defaultMessage: 'Plan',
    },
    status_do: {
        id: `${scope}.labels.status_do`,
        defaultMessage: 'Do',
    },
    status_check: {
        id: `${scope}.labels.status_check`,
        defaultMessage: 'Check',
    },
    status_act: {
        id: `${scope}.labels.status_act`,
        defaultMessage: 'Act',
    },
    status_archive: {
        id: `${scope}.labels.status_archive`,
        defaultMessage: 'Archive',
    },

});