import ImprovementsPage from './pages/ImprovementsPage'
import ImprovementFullPage from './pages/ImprovementFullPage'
import ImprovementsListPage from './pages/ImprovementsListPage'
import ImprovementsListLanesPage from './pages/ImprovementsListLanesPage'
import ImprovementAddAttachmentPage from './pages/ImprovementAddAttachmentPage'
import ImprovementsDashboardPage from './pages/ImprovementsDashboardPage'
import ImprovementAddDimensionsPage from './pages/ImprovementAddDimensionsPage'
import IncidentsCalendarPage from './pages/IncidentsCalendarPage'
import UsersPage from './pages/UsersPage'
import  Home  from "./pages/Home";


const AppRoutes = [
  {
    
    element: <Home />
  },
  {
    path:"/improvementsPage",
    element: <ImprovementsPage />
  },
  {
    path:"/improvementFullPage/:id",
    element: <ImprovementFullPage />
  },
  {
    path:"/improvementFullPage/:id/:taskId",
    element: <ImprovementFullPage />
  },
  {
    path:"/improvementsPage/:id",
    element: <ImprovementsPage />
  },
  {
    path:"/improvementsListPage",
    element: <ImprovementsListPage />
  },
  {
    path:"/improvementsListLanePage",
    element: <ImprovementsListLanesPage />
  },
  {
    path:"/improvementAddAttachmentPage/:taskId",
    element: <ImprovementAddAttachmentPage />
  },
  {
    path:"/improvementsDashboardPage",
    element: <ImprovementsDashboardPage />
  },
  {
    path:"/improvementAddDimensionsPage",
    element: <ImprovementAddDimensionsPage />
  },
  {
    path:"/usersPage",
    element: <UsersPage />
  },
  {
    path:"/incidentsCalendarPage",
    element: <IncidentsCalendarPage />
  },
  

];

export default AppRoutes;
