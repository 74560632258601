
import React, { useState, useEffect, useMemo } from "react";
import Dropzone from "react-dropzone";
import { Button, Grid, Typography, useTheme, Box, Chip, IconButton, Icon, Tooltip, Stack } from "@mui/material";
import { postAttachment, getAttachmentsForTask, deleteAttachment } from "../../../fetchers/attachment";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { tokens } from "../../../assets/theme";
import { useQuery } from "@tanstack/react-query";
import { useParams } from 'react-router-dom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { pushErrorMessage } from "../../../utils/notifications";
import AxionConfirmation from '../../../components/controls/AxionConfirmation';


export default function ImprovementAddAttachment(props) {
    //   export default function ImprovementAddAttachment(improvement) {


    //#####################
    //taskId = 2013;

    const { taskId } = useState(props.taskId);
    console.log("props", props);

    console.log("taskId:", taskId);


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [attachment, setAttachment] = useState([]);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [attachmentUrl, setAttachmentUrl] = useState("");
    const [attachmentId, setAttachmentId] = useState([]);

    const formData = (attachment) => {
        const formData = new FormData();

        formData.append("taskId", props.taskId);
        formData.append("fileName", attachment.fileName);
        formData.append("fileType", attachment.fileType);
        formData.append("file", attachment.file);

        console.log("formdata returns:", formData.values[0]);

        return formData;
    }

    const attachmentsQuery = useQuery(
        ["attachments", props.taskId],
        () => getAttachmentsForTask(props.taskId),
        {}
    );

    const onDropCallback = (acceptedFiles) => {

        console.log("onDropCallback", acceptedFiles);
        //console.log("onDropCallback file", file);

        if (acceptedFiles === undefined) {
            return;
        }

        const file = acceptedFiles[0]; // only one file per step right now

        const newAttachement = {
            fileName: file.name,
            fileType: file.type,
            //filePath: file.name,
            file: file,
            thumbnail: URL.createObjectURL(file), // Generate thumbnail as a data URL
        };

        console.log("onDropCallback mediafiles", newAttachement);

        setAttachment(newAttachement);

        postAttachment(formData(newAttachement)).then(() => {
            attachmentsQuery.refetch();
            setAttachment({});
            //setOpenPopup(false);
            //data.refetch();
            //setShowBackdrop(false);
        }).catch((error) => {
            console.log(error);
            //setShowBackdrop(false);
            pushErrorMessage(`Could not save Attachment.`);
        })
    };


    const handleDeleteAttachment = (id, url) => {
        setAttachmentId(id);
        setAttachmentUrl(url);
        setOpenConfirmation(true);
        setConfirmationTitle("Delete Attachment");
        setConfirmationMessage("Are you sure you want to delete this attachment?");
    }

    const doDeleteAttachment = () => {
        setOpenConfirmation(false);

        deleteAttachment(attachmentId, attachmentUrl).then(() => {
            setAttachmentUrl("");
            attachmentsQuery.refetch();
            //setOpenPopup(false);
            //data.refetch();
            //setShowBackdrop(false);
        }).catch((error) => {
            console.log(error);
            //setShowBackdrop(false);
            pushErrorMessage(`Could not delete Attachment.`);
        })
    }

    const cancelDeleteAttachment = () => {
        setOpenConfirmation(false);
        setConfirmationTitle("");
        setConfirmationMessage("");

    }

    const handleSubmit = () => {

        console.log("Submit");

        postAttachment(formData(attachment)).then(() => {
            //setOpenPopup(false);
            //data.refetch();
            //setShowBackdrop(false);
        }).catch((error) => {
            console.log(error);
            //setShowBackdrop(false);
            pushErrorMessage(`Could not save attachment.`);
        })
    };

    function isImageType(fileType) {
        return fileType.startsWith("image/png") || fileType.startsWith("image/gif") || fileType.startsWith("image/jpeg");
    }

    function getFileName(url) {
        return url.slice(url.lastIndexOf('/') + 1)
    }

    function getReadableFileName(fileName) {
        return fileName.slice(fileName.indexOf('_') + 1)
    }

    function getDocIcon(fileName) {
        fileName = fileName.toLowerCase();
        if (fileName.substr(fileName.length - 3) === 'pdf') {
            return '../../assets/pdf.png';
        }
        if (fileName.substr(fileName.length - 3) === 'doc') {
            return '../../assets/doc.png';
        }
        if (fileName.substr(fileName.length - 4) === 'docx') {
            return '../../assets/doc.png';
        }
        if (fileName.substr(fileName.length - 4) === 'xlsx') {
            return '../../assets/excel.png';
        }
        if (fileName.substr(fileName.length - 3) === 'xls') {
            return '../../assets/excel.png';
        }
        if (fileName.substr(fileName.length - 3) === 'ppt') {
            return '../../assets/ppt.png';
        }
        if (fileName.substr(fileName.length - 4) === 'pptx') {
            return '../../assets/ppt.png';
        }
        return '../../assets/unknown.png'
    }

    console.log("Attachmentdata", attachmentsQuery?.data);

    return (
        <Box width="500px" overflow="hidden">
            <Box width="145px" height="120px" marginBottom="4px" backgroundColor={colors.primary[500]} justifyContent="center" alignItems="center" display="flex">
                <Dropzone onDrop={onDropCallback}
                    name="heroImage" multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                        <div height="100%"
                            {...getRootProps()}
                            style={{
                                border: "1px dashed #ccc",
                                borderColor: "#799c4a",
                                padding: "3px",
                                margin: "3px",
                                //marginLeft: "2px",
                                // height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                // flexDirection: "column",
                                height: "95%",
                                width: "95%"

                            }}
                        >
                            <input {...getInputProps()} />
                            {/* <Typography
                                display="flex"
                                sx={{ textAlign: "center", top: "auto" }}
                                justifyContent={"center"}
                            > */}

                            {attachment.thumbnail ? (
                                <img
                                    src={attachment.thumbnail}
                                    alt={`Thumbnail`}
                                    style={{ maxWidth: "100%", maxHeight: "100%" }}

                                />
                            ) :
                                (
                                    <Box><Typography variant="h1">+</Typography></Box>
                                )}
                            {/* </Typography> */}
                        </div>

                    )}
                </Dropzone>
            </Box>
            {/* <Grid container spacing={0.5}> */}
            {/* <Grid item xs={3} backgroundColor={colors.primary[400]} height="120px" marginRight="5px" marginTop="5px" justifyContent="center" alignItems="center" display="flex"> */}

            {/* </Grid> */}
            <Stack spacing={{ xs: 0.5 }} direction="row" useFlexGap flexWrap="wrap" width="500px">
                {attachmentsQuery?.data?.map((a) => {
                    let url = props.taskId + '/' + getFileName(a.fileName);
                    console.log("url:", url)
                    return (
                        // <Grid item xs={4} height="120px" justifyContent="center" alignItems="center" display="flex">
                        <Box justifyContent="center" alignItems="center" display="flex">
                            <Box height="115px" width="115px" backgroundColor={colors.primary[500]} justifyContent="center" alignItems="center" display="flex" >
                                <Tooltip title={<Typography fontSize="12px">{getReadableFileName(a.fileName)}</Typography>}>
                                    <img
                                        src={isImageType(a.fileType) ? a.fileName : getDocIcon(a.fileName)}
                                        alt={`Attached document`}
                                        // height="40px"
                                        style={{ maxWidth: "100px", maxHeight: "100px", display: "block" }}
                                    />
                                </Tooltip>
                            </Box>
                            <Box width="30px" backgroundColor={colors.primary[500]} justifyContent="flex-end" alignItems="flex-start" display="flex" height="100%" >
                                <IconButton onClick={() => { handleDeleteAttachment(a.id, url) }} ><DeleteOutlinedIcon /></IconButton>
                            </Box>
                        </Box>
                        // </Grid>
                    )
                })}

            </Stack>
            {/* </Grid> */}
            {/* <Box marginTop="20px">
                <Button

                    variant="contained"
                    color="primary"
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        "&:hover": {
                            backgroundColor: colors.blueAccent[800],
                            borderColor: colors.blueAccent[800],
                            boxShadow: "none",
                        },
                        marginRight: "10px",
                    }}
                    onClick={handleSubmit}
                >
                    <SaveOutlinedIcon /> Save
                </Button>
            </Box> */}
            <AxionConfirmation open={openConfirmation} title={confirmationTitle} message={confirmationMessage} onConfirm={doDeleteAttachment} onCancel={cancelDeleteAttachment} cancelText="No" confirmText="Yes" />
        </Box>


    );


}