import axios from "axios";

// Dimensions
export const getDimensions = async (comapanyId) => {
    const response = await axios.get(`api/taskdimensions?companyId=${comapanyId}`);
    const result = response.data;
    return result;
}

export const postDimension = async (data) => {
    const response = await axios.post("api/taskdimensions", data);
    const result = response.data;
    return result;
}

export const deleteDimension = async (dimensionId) => {
    console.log("delete ", dimensionId)
    const response = await axios.delete(`api/taskdimensions/${dimensionId}`);
    const result = response.data;
    return result;
}

export const patchDimension = async (data) => {
    const response = await axios.patch("api/taskdimensions", data);
    const result = response.data;
    return result;
}

// DimensionValues
export const postDimensionValue = async (data) => {
    const response = await axios.post("api/taskdimensions/values", data);
    const result = response.data;
    return result;
}

export const deleteDimensionValue = async (dimensionValueId) => {
    console.log("delete ", dimensionValueId)
    const response = await axios.delete(`api/taskdimensions/values/${dimensionValueId}`);
    const result = response.data;
    return result;
}

export const patchDimensionValue = async (data) => {
    const response = await axios.patch("api/taskdimensions/values", data);
    const result = response.data;
    return result;
}


