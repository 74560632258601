import React, { useState, useEffect, act } from 'react';
import { useQuery } from "@tanstack/react-query";
import { Button, useTheme, Box, IconButton, Stack, Select, OutlinedInput, Chip, Avatar, MenuItem, Tooltip, Typography } from "@mui/material";
import { tokens } from "../../../assets/theme";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AxionControls from "../../../components/controls/AxionControls";
import { postReportedTime } from "../../../fetchers/reportedTime";
import Controls from "../../../components/controls/Contols";
import SaveIcon from '@mui/icons-material/Save';
import { getTask, putTask, getSubtasks, postTask, deleteTask } from "../../../fetchers/task";
//Language
import messages from "../messages";
import { FormattedMessage, useIntl } from "react-intl";


export default function ImprovementAddTimeWithoutData(props) {

    // console.log("ImprovementAddTimeWithoutData", props);
    

    //<ImprovementAddTime taskId={id} reportedTimes={taskQuery.data?.reportedTimes} refetchQuery={taskQuery.refetch()} task={taskQuery.data} personQuery={personQuery} />
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { formatMessage } = useIntl();

    //API FETCHERS 
    const taskQuery = useQuery(
        ["task", props.taskId],
        () => getTask(props.taskId),
        // () => {if (id !== 0) {getTask(id)}},
        {}
    );

    let personId = taskQuery.data?.responsibleId;
    const [newTime, setNewTime] = useState({ taskId: props.taskId, reportedTime: 0, personId: personId, comment: "" });

    const handleAddTime = () => {
        // console.log("newTime", newTime);

        postReportedTime(newTime).then(() => {
            taskQuery.refetch();
        }).catch((error) => {
            console.log(error);
        });
    }

    const handleTimeChange = (event) => {
        let time = { ...newTime };
        time.reportedTime = event.target.value;

        setNewTime(time);
    }

    const handlePersonChange = (event) => {
        let time = { ...newTime };
        time.personId = event.target.value;

        personId = event.target.value;

        setNewTime(time);
    }
    const handleCommentChange = (event) => {
        let time = { ...newTime };
        time.comment = event.target.value;

        setNewTime(time);
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    // function getPersonImg(personId) {
    //     let person = props.personQuery?.data?.find(p => p.id === personId);
    //     return person?.image;
    // }
    function getPersonImg(personId) {
        let person = props.personQuery?.data.find(p => p.id === personId);
        return `data:;base64,${person?.imageBase64}`;
    }
    function getPersonFullName(personId) {
        let person = props.personQuery?.data?.find(p => p.id === personId);
        if (person === undefined) {
            return "";
        }
        return person?.firstName; // + " " + person?.lastName;
    }
    function getTotalTime() {
        let total = 0;
        taskQuery.data?.reportedTimes?.forEach((a) => {
            total += a.reportedTime;
        });
        return total;
    }

    function formatDate(date) {
        return date ? new Date(date).toLocaleDateString('sv-SE') : "";
    }

    function formatTime(date) {
        return date ? new Date(date).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' }) : "";
    }

    let spinner = <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        }}
    ></div>

    if (taskQuery.isLoading) {
        //return <Box color="white">Loading Tasks...</Box>;
        return spinner;
    }

    return (
        <Box>
            <Stack direction="column" width="100%" >
                <Stack direction="row" width="100%" >
                    <Box width="50%">
                        <AxionControls.AxionTextBox
                            label={<FormattedMessage {...messages.report_time} />}
                            //value={}
                            autoComplete="new-password"
                            inputProps={{ autoComplete: 'new-password' }}
                            onChange={(event) =>
                                handleTimeChange(event)
                            }
                        />
                    </Box>

                    <Box display="flex" justifyContent="flex-end" width="50%">
                        <Select
                            labelId="responsible-multiple-chip-label"
                            id="responsible-multiple-chip"
                            value={newTime.personId}
                            onChange={handlePersonChange}
                            input={<OutlinedInput id="responsible-select-multiple-chip" label="Select responsible" />}
                            renderValue={() => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    <Chip key={personId} label={getPersonFullName(newTime.personId)}
                                        avatar={<Avatar alt="value" src={getPersonImg(newTime.personId)} sx={{ width: '35px', height: '35px', borderRadius: '50%', border: 1, borderColor: '#000000' }}/>}
                                        variant="outlined"
                                    //onDelete={handleParticipantsDelete} 
                                    />
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {props.personQuery?.data?.filter(h => h.hide !== true).map((p) => (
                                <MenuItem
                                    key={p.id}
                                    value={p.id}
                                //   style={getStyles(name, personName, theme)}
                                >
                                    <Avatar alt="value" src={`data:;base64,${p?.imageBase64}`} sx={{ width: '30px', height: '30px', borderRadius: '50%', border: 1, borderColor: '#000000' }} /><Box paddingLeft="7px">{p.firstName}</Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Stack>

                <Box><AxionControls.AxionTextBox
                    label={<FormattedMessage {...messages.comment} />}
                    //value={}
                    autoComplete="new-password"
                    inputProps={{ autoComplete: 'new-password' }}
                    onChange={(event) =>
                        handleCommentChange(event)
                    }
                /></Box>

                <Box width="100%" justifyContent="flex-end" alignItems="center" display="flex" marginTop="10px">
                    <Controls.Button
                        text={<FormattedMessage {...messages.report_time} />}
                        onClick={handleAddTime}
                        startIcon={<SaveIcon />}
                        sx={{
                            "&:hover": {
                                backgroundColor: colors.dashbordGreen[700],
                            },
                            backgroundColor: colors.dashbordGreen[500],
                            color: "#000000",
                        }}
                    />
                </Box>
            </Stack>

            {(taskQuery.data?.reportedTimes?.length > 0) && <Box marginTop="20px"><FormattedMessage {...messages.reported_time} /></Box>}
            <Stack direction="column" width="100%" overflow="hidden" backgroundColor={colors.primary[600]} marginTop="5px">
                {taskQuery.data?.reportedTimes?.map((a) => {
                    return (
                        <Stack direction="row" width="100%" marginTop="1px" backgroundColor={colors.primary[400]} paddingTop="4px" paddingBottom="4px">
                            <Box width="10%" alignItems="center" display="flex" marginLeft="10px">
                                <Tooltip title={<Typography fontSize="12px">{getPersonFullName(a.personId)}</Typography>}>
                                    <Avatar alt="value" sx={{ width: '25px', height: '25px', borderRadius: '50%', border: 1, borderColor: '#000000' }} src={getPersonImg(a.personId)} />
                                </Tooltip>
                            </Box>
                            <Box width="10%" alignItems="center" display="flex" marginLeft="10px" justifyContent="flex-end">{a.reportedTime} h</Box>

                            <Box width="60%" alignItems="center" display="flex" marginLeft="10px">{a.comment}</Box>
                            <Box width="20%" alignItems="center" justifyContent="flex-end" display="flex" marginLeft="10px">{formatDate(a.created) + ' ' + formatTime(a.created)}</Box>
                            {/* <Box width="20%" justifyContent="flex-end" alignItems="center" display="flex">
                                <IconButton onClick={() => { handleDeleteLink(a.id) }} ><DeleteOutlinedIcon /></IconButton>
                            </Box> */}
                        </Stack>

                    )
                })}
                <Stack direction="row" width="100%" marginTop="2px" backgroundColor={colors.primary[400]} paddingTop="4px" paddingBottom="4px">
                    <Box width="10%" alignItems="center" display="flex" marginLeft="10px"></Box>
                    <Box width="10%" alignItems="center" display="flex" marginLeft="10px" justifyContent="flex-end">{getTotalTime()} h</Box>
                    <Box width="75%" alignItems="center" display="flex" marginLeft="10px"> </Box>
                </Stack>
            </Stack>
        </Box >
    )

}