import React, { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Paper, useTheme, Stack, Chip, IconButton, Tooltip, Button, Input, Typography } from '@mui/material';
import { tokens } from "../../assets/theme";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate } from "react-router-dom";
//import { DataGrid } from "@mui/x-data-grid";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
// import messages from "../components/messages";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Backdrop, CircularProgress } from "@mui/material";
//Fetchers
import { getAllTasks } from "../../fetchers/task";
import { getPersons } from "../../fetchers/person";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { getDepartments } from "../../fetchers/departments";
import { getTags } from "../../fetchers/taskTags";
import { getDimensions } from "../../fetchers/taskDimension";
//Icons
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import StarRateOutlinedIcon from '@mui/icons-material/StarRateOutlined';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

import { SearchOffOutlined } from "@mui/icons-material";
import Controls from "../../components/controls/Contols";
import { useMsal, useAccount } from "@azure/msal-react";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import dayjs from "dayjs";
//Language
import messages from "./messages";
import { FormattedMessage, useIntl } from "react-intl";




// function CircularProgressWithLabelUS(props) {
//   //import CircularProgress from '@mui/material/CircularProgress';
//   //<CircularProgressWithLabelUS text="OEE" value={props.total} /> 
//   return (
//     <Box sx={{ position: 'relative', display: 'inline-flex' }}>
//       <CircularProgress  thickness={5} size={60} color="secondary"  variant="determinate" {...props} sx={{marginTop: 1, marginLeft: 1,}} />

//       <Box
//         sx={{
//           top: 10,
//           left: 10,
//           bottom: 2,
//           right: 0,
//           position: 'absolute',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//       >
//         <Typography variant="caption" component="div" color="text.secondary">
//           {`${Math.round(props.value)}% ${props.text}`  }
//         </Typography>
//       </Box>
//     </Box>
//   );
// }




const ImprovementsList = () => {

    console.log("ImprovementsList.jsx!!");

    const { formatMessage } = useIntl();
    const [tagId, setTagId] = useLocalStorage("ImprovementsTagId", 0);
    const [personId, setPersonId] = useLocalStorage("ImprovementsPersonId", 0);
    const [departmentId, setDepartmentId] = useLocalStorage("ImprovementsDepartmentId", 0);
    const [showChildrenFor, setShowChildrenFor] = useLocalStorage("ImprovementsShowChildrenFor", 0);
    const [dimensionIds, setDimensionIds] = useLocalStorage("ImprovementsDimensionIds", []);
    const [typeId, setTypeId] = useLocalStorage("ImprovementsTypeId", 0);
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
    const apiRef = useGridApiRef();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(document.location.search);
    const filterUser = searchParams.get('filteruser');
    const filterDepartment = searchParams.get('filterdepartment');
    const filterType = searchParams.get('filtertype');
    const closedThisYear = searchParams.get('closedthisyear');
    const closedOnBudget = searchParams.get('closedonbudget');
    const closedOnTime = searchParams.get('closedontime');
    const overDue = searchParams.get('overdue');
    const overEstimate = searchParams.get('overestimate');

    const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage("ImprovementsList-columnVisibility", []);//= useState({});



    const [sortModelStorage, setSortModelStorage] = useState([
        { field: "id", sort: "asc" }
    ]);
    const [filterMessage, setFilterMessage] = useState("");

    const personQuery = useQuery(
        ["persons"],
        () => getPersons(),
        {}
    );

    const taskQuery = useQuery(
        ["task", companyKey],
        () => getAllTasks(companyKey),
        {
            staleTime: 120000,
            refetchInterval: 60000,
            enabled: !!personQuery.data
        }
    );
    const departmentsQuery = useQuery(
        ["departments", companyKey],
        () => getDepartments(companyKey),
        {}
    );
    const tagsQuery = useQuery(
        ["tags"],
        () => getTags(companyKey),
        {}
    );
    const dimensionsQuery = useQuery(
        ["dimensions"],
        () => getDimensions(companyKey),
        {}
    );
    const [filteredData, setFilteredData] = useState([]);
    const [initialData, setInitialdData] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    const columnOrderLocalStorageName = 'ImprovementsList-columnOrder';
    const columnWidthsLocalStorageName = 'ImprovementsList-columnWidths';

    function fixColumns(columns) {
        let columnsToFix = columns;
        // Load column order
        const savedColumnOrder = localStorage.getItem(columnOrderLocalStorageName);
        if (savedColumnOrder) {
            const orderArray = JSON.parse(savedColumnOrder);
            columnsToFix = reorderColumns(orderArray, columnsToFix);
        }

        // Load column widths
        const savedWidths = localStorage.getItem(columnWidthsLocalStorageName);
        if (savedWidths) {
            const widthMap = JSON.parse(savedWidths);
            columnsToFix = applyColumnWidths(widthMap, columnsToFix);
        }
        return columnsToFix;
    };

    // Reorder columns based on saved order
    const reorderColumns = (orderArray, columnsToFix) => {
        const reorderedColumns = orderArray.map((field) =>
            columnsToFix.find((col) => col.field === field)
        );
        reorderedColumns.push(...columnsToFix.filter((col) => !reorderedColumns.includes(col)));
        return reorderedColumns;
    };

    // Apply saved column widths
    const applyColumnWidths = (widthMap, columnsToFix) => {
        const updatedColumns = columnsToFix.map((col) => ({
            ...col,
            width: widthMap[col.field] || col.width,
            flex: widthMap[col.field] !== undefined ? undefined : col.flex,
        }));
        return updatedColumns;
    };

    // Handle column order change
    const handleColumnOrderChange = () => {
        if (!apiRef.current) {
            return;
        }
        const orderedColumns = apiRef.current.getVisibleColumns();
        const newColumnOrder = orderedColumns.map((col) => col.field);
        localStorage.setItem(columnOrderLocalStorageName, JSON.stringify(newColumnOrder));
    };

    // Handle column resize
    const handleColumnResize = (params) => {
        const { colDef, width } = params;
        const newWidths = JSON.parse(localStorage.getItem(columnWidthsLocalStorageName)) || {};
        newWidths[colDef.field] = width;
        localStorage.setItem(columnWidthsLocalStorageName, JSON.stringify(newWidths));
    };

    useEffect(() => {
        if (filterUser) {
            if (personId !== filterUser) {
                clearAllFilters();
                setPersonId(parseInt(filterUser));
            }
        }
    }, [filterUser]);

    useEffect(() => {
        if (filterDepartment) {
            setDepartmentId(parseInt(filterDepartment));
        }
        if (filterType) {
            setTypeId(parseInt(filterType));
        }
    }, []);

    useEffect(() => {
        if (dimensionsQuery.data) {
            if (dimensionIds === null) {
                let init = dimensionsQuery.data.map(x => { return { id: 0, dimensionId: x.id } });
                setDimensionIds(init);
            }
        }
    }, [dimensionsQuery.data]);


    useEffect(() => {

       // console.log("stora effekten!!!")
        let data = [];
        if (taskQuery.data) {
            if (closedThisYear === 'true') {
                clearAllFilters();
                data = taskQuery.data?.filter(dataRow => dataRow.taskId === null && new Date(dataRow.endDate).getFullYear() === new Date().getFullYear());
                setInitialdData(data);
                setFilteredData(data);
                setFilterMessage(formatMessage(messages.filter_showing_improvements_closed_this_year));
            } else if (closedOnBudget === 'true') {
                clearAllFilters()
                data = getClosedOnBudget();
                setInitialdData(data);
                setFilteredData(data);
                setFilterMessage(formatMessage(messages.filter_showing_improvements_closed_on_budget));
            } else if (closedOnTime === 'true') {
                clearAllFilters()
                data = taskQuery.data?.filter(t => t.taskId === null && t.endDate !== null && t.targetDate >= t.endDate);
                setInitialdData(data);
                setFilteredData(data);
                setFilterMessage(formatMessage(messages.filter_showing_improvements_closed_on_time));
            } else if (overDue === 'true') {
                clearAllFilters()
                data = taskQuery.data?.filter(t => getDaysDue(t.targetDate) < 0);
                setInitialdData(data);
                setFilteredData(data);
                setFilterMessage(formatMessage(messages.filter_showing_improvements_over_due));
            } else if (overEstimate === 'true') {
                clearAllFilters()
                data = getOverEstimate();
                setInitialdData(data);
                setFilteredData(data);
                setFilterMessage(formatMessage(messages.filter_showing_improvements_over_budget));
            } else if (filterDepartment !== null || filterType !== null) {
                data = taskQuery.data?.filter(dataRow => dataRow.taskId === null)
                setInitialdData(data);
                setFilteredData(data)
                setFilterMessage(formatMessage(messages.filter_department_and_type_filter_active));
                //clearAllFilters();
                doFilterAll(parseInt(filterDepartment), tagId, personId, searchValue, showChildrenFor, dimensionIds, parseInt(filterType), true, false);
            }
            else {
                data = taskQuery.data?.filter(dataRow => dataRow.taskId === null)
                setInitialdData(data);
                setFilteredData(data)
                setFilterMessage(null);
            }
        }
    }, [taskQuery.isSuccess]);

    const handleTagChange = (event) => {
        //console.log("handleTagChange", event.target.value);
        setTagId(event.target.value);
        doFilterAll(departmentId, event.target.value, personId, searchValue, showChildrenFor, dimensionIds, typeId, false, true);
    };
    const handleDimensionChange = (event) => {

        let valueId = event.target.value;
        let dimensionId = event.target.name;

        // Build current selected value obj
        let dimValue = { id: valueId, dimensionId: dimensionId };

        //Remove search value for current dimension
        let valFiltered = dimensionIds?.filter(x => x.dimensionId !== dimensionId)

        valFiltered.push(dimValue);

        setDimensionIds(valFiltered);
        doFilterAll(departmentId, tagId, personId, searchValue, showChildrenFor, valFiltered, typeId, false, true);
    };

    const handlePersonChange = (event) => {
        //console.log("handlePersonChange", event.target.value);
        setPersonId(event.target.value);
        doFilterAll(departmentId, tagId, event.target.value, searchValue, showChildrenFor, dimensionIds, typeId, false, true);
    };
    const handleDepartmentChange = (event) => {
        //console.log("handleDepartmentChange", event.target.value);
        setDepartmentId(event.target.value);
        doFilterAll(event.target.value, tagId, personId, searchValue, showChildrenFor, dimensionIds, typeId, false, true);
    };
    const handleTypeChange = (event) => {
        console.log("handleTypeChange", event.target.value);
        setTypeId(event.target.value);
        doFilterAll(departmentId, tagId, personId, searchValue, showChildrenFor, dimensionIds, event.target.value, false, true);
    };

    const handleSearchChange = (event) => {
        doSearch(event.target.value);
    }
    const doSearch = (value) => {
        setSearchValue(value);
        if (value?.length > 2) {
            setFilteredData(searchFilterList(taskQuery.data, value));
        } else {
            if (taskQuery.data?.length > filteredData?.length) {
                setFilteredData(taskQuery.data);
            }
        }
    }
    const handleSearchMe = () => {
        //doSearch(account.username);
        let id = 0;
        if (personId === 0) {
            id = getPersonIdByEmail(account.username);
        }
        setPersonId(id);
        doFilterAll(departmentId, tagId, id, searchValue, showChildrenFor, dimensionIds, typeId, false, true);
    }
    const clearSearchValue = () => {
        //doSearch("");
        setSearchValue("");
        doFilterAll(departmentId, tagId, personId, "", showChildrenFor, dimensionIds, typeId, false, true);
    }

    const handleShowChildrenClick = (id) => {
        if (id === showChildrenFor) {
            id = 0;
        }
        setShowChildrenFor(id);
        doFilterAll(departmentId, tagId, personId, searchValue, id, dimensionIds, typeId, false, false);
    }

    function getDimensionInitFilter() {
        return dimensionsQuery.data?.map(x => { return { id: 0, dimensionId: x.id } });
    }

    function clearAllFilters() {
        setDepartmentId(0);
        setTagId(0);
        setPersonId(0);
        setTypeId(0);
        setSearchValue("");
        setShowChildrenFor(0);
        setDimensionIds(getDimensionInitFilter());
    }

    function handleClearSpecialSearch() {
        //clearAllFilters();
        doFilterAll(departmentId, tagId, personId, searchValue, showChildrenFor, dimensionIds, typeId, true, true);
    }

    function handleResetFiltering() {
        clearAllFilters();
        let dimensionInitFilter = getDimensionInitFilter()
        doFilterAll(0, 0, 0, '', 0, dimensionInitFilter, 0, false, true);
    }

    function getDaysDue(date) {
        let d = dayjs(date);
        let diff = d.diff(dayjs(), "day");
        return isNaN(diff) ? 0 : diff;
    }

    function getClosedOnBudget() {
        let task = taskQuery.data?.filter(t => t.taskId === null && t.endDate !== null);
        let result = [];

        task.forEach((t) => {
            let totalTime = 0;
            t.reportedTimes.forEach((rt) => {
                totalTime += rt.reportedTime;
            });
            let diff = totalTime - t.estimatedEffort;
            if (diff <= 0) {
                // if (t.targetDate >= t.endDate) {
                result.push(t);
                // }
            }
        })
        return result;
    }

    function getOverEstimate() {
        let task = taskQuery.data.filter(t => t.taskId === null);
        //let subtask = taskQuery.data.filter(t => t.taskId !== null);
        let result = [];

        task.forEach((t) => {
            let totalTime = 0;
            t.reportedTimes.forEach((rt) => {
                totalTime += rt.reportedTime;
            });
            let diff = totalTime - t.estimatedEffort;
            if (diff > 0) {
                result.push(t);
            }
        })
        return result;
    }

    function getSubTasks(taskId) {
        return taskQuery.data?.filter(task => {
            return task.taskId === taskId;
        });
    }

    function getTitleForTask(taskId) {
        var result = taskQuery.data?.filter(task => {
            return task.id === taskId;
        })[0].title;

        //console.log ("Title:", result);

        return result;
    }

    function getChildCount(taskId) {
        var result = taskQuery.data?.filter(task => {
            return task.taskId === taskId;
        }).length;
        return " " + result;
    }

    function showChildren(id, tempData) {

        // var tempData = [...filteredData];
        if (id === 0) return tempData;

        var subtasks = getSubTasks(id);

        //console.log("tempdata:", tempData);

        let data = [...tempData];
        //var data = []

        //console.log("subtasks:", subtasks);
        subtasks.forEach(subtask => {
            insertTasksAfterParent(id, subtask, data);
        });
        return data;
    }

    function insertTasksAfterParent(id, task, tasks) {

        console.log("insertTasksAfterParent:", id, task, tasks);

        //let arr = taskQuery.data?.map((t) => t.id);
        let arr = tasks?.map((t) => t.id);
        let index = arr.findIndex((t) => t === id);

        console.log("Index:", index);

        if (index === -1) {
            tasks.push(task);
        } else {
            tasks.splice(index + 1, 0, { ...task });
        }
        //console.log("t:", tasks);
        return tasks;
    }

    function doFilterAll(dId, tId, pId, sValue, parentId, dimensionIds, typeId, clearData, clearMessage) {
        // Main filtering function
        // department, tagIds, personId, searchValue, parentId

        console.log("doFilterAll:", dId, tId, pId, sValue, parentId, dimensionIds, typeId, clearData, clearMessage);
        console.log("doFilterAll initialData:", initialData);
        let result = [];
        if (clearData === true) {
            result = taskQuery.data.filter(dataRow => dataRow.taskId === null);
            setFilteredData(result);
            setInitialdData(result);
            //setFilterMessage('');
        } else {
            result = initialData;
        }

        if (clearMessage === true) {
            setFilterMessage('');
        }

        result = showChildren(parentId, result);

        if (dId !== 0) {
            result = result.filter(dataRow => {
                return dataRow.departmentId === dId;
            });
        }
        if (pId !== 0) {
            result = result.filter(dataRow => {
                //missing participants
                return dataRow.participants.some(p => p.id === pId) || dataRow.responsibleId === pId;

            });
        }
        if (tId !== 0) {
            result = result.filter(dataRow => {
                return dataRow.tags.some(tag => tag.id === tId);
            });
        }

        if (typeId !== 0) {
            result = result.filter(dataRow => {
                //console.log ("type:", dataRow);    
                return (dataRow.type?.id === typeId);
            });
        }

        //check if any dimension selected
        if (dimensionIds?.some(x => x.id !== 0)) {
            let valueIds = dimensionIds.map(x => x.id);

            result = result.filter(dataRow => {
                return dataRow.dimensionValues.some(x => valueIds.includes(x.id));// valueIds.includes(x.id)
            });
        }

        if (sValue?.length > 2) {
            result = result.filter(dataRow => {
                return searchObjectsWithValue(dataRow, sValue);
            });
        }
        setFilteredData(result);
    }

    function searchFilterList(data, value) {
        let result = [];
        if (data === null || data === undefined) {
            return result;
        }
        result = taskQuery.data.filter(dataRow => {
            return searchObjectsWithValue(dataRow, value);
        });
        return result;
    }

    function formatDate(date) {
        return date ? new Date(date).toLocaleDateString('sv-SE') : "";
    }
    function formatDateLocalized(date) {
        let locale = companyKey === 2 ? 'en-US' : 'sv-SE';
        return date ? new Date(date).toLocaleDateString(locale) : "";
    }
    function formatType(type) {
        switch (type) {
            case 1:
                return <BoltOutlinedIcon fontSize='small' />;
            case 2:
                return <StarRateOutlinedIcon fontSize='small' />;
            default:
                return "";
        }
    }
    function formatTypeText(type) {
        switch (type) {
            case 1:
                return "Incident";
            case 2:
                return "Improvement";
            default:
                return "";
        }
    }
    
    function formatTrueYes(value) {
        return value ? "Yes" : "";
    }

    function searchObjectsWithValue(dataRow, value) {

        if (dataRow === null || dataRow === undefined) {
            return false;
        }

        let done = false;
        value = value.toLowerCase();

        if (dataRow.title?.toLowerCase().includes(value)) { return true; }
        if (dataRow.description?.toLowerCase().includes(value)) { return true; }
        if (formatDate(dataRow.targetDate).toLowerCase().includes(value)) { return true; }
        if (formatDate(dataRow.startDate).toLowerCase().includes(value)) { return true; }
        if (formatDate(dataRow.endDate).toLowerCase().includes(value)) { return true; }
        if (formatDate(dataRow.effort).toLowerCase().includes(value)) { return true; }
        if (dataRow.responsible?.firstName?.toLowerCase().includes(value)) { return true; }
        //if (dataRow.responsible?.lastName?.toLowerCase().includes(value)) { return true; }
        if (dataRow.responsible?.email?.toLowerCase().includes(value)) { return true; }
        // if (dataRow.responsible?.capacity.toLowerCase().includes(value)) { return true; }
        if (dataRow.department?.name?.toLowerCase().includes(value)) { return true; }
        if (dataRow.group?.title?.toLowerCase().includes(value)) { return true; }
        if (dataRow.type?.title?.toLowerCase().includes(value)) { return true; }
        if (dataRow.status?.description?.toLowerCase().includes(value)) { return true; }
        // if (dataRow.status?.color.includes(value)) { return dataRow; }
        dataRow.comments?.forEach(comment => {
            if (comment.commentText?.toLowerCase().includes(value)) { done = true; }
        });
        if (done === true) { return true; }
        dataRow.tags?.forEach(tag => {
            if (tag.title?.toLowerCase().includes(value)) { done = true; }
        });
        if (done === true) { return true; }
        dataRow.participants?.forEach(p => {
            if (p.employeeNumber?.toLowerCase().includes(value)) { done = true; }
            if (p.firstName?.toLowerCase().includes(value)) { done = true; }
            //if (p.lastName?.toLowerCase().includes(value)) { done = true; }
            if (p.email?.toLowerCase().includes(value)) { done = true; }
        });
        if (done === true) { return true; }

        return false;
    }

    function getPersonFullNameById(personId) {
        let person = personQuery.data?.find(p => p.id === personId);
        if (person === undefined) {
            return "";
        }
        return person?.firstName;// + " " + person?.lastName;
    }
    function getPersonImageByEmail(email) {
        let person = personQuery.data?.find(p => p.email.toLowerCase() === email.toLowerCase());
        return person?.image;
    }
    function getPersonIdByEmail(email) {
        let person = personQuery.data.find(p => p.email.toLowerCase() === email.toLowerCase());
        return person?.id;
    }
    function getPersonFullNameByEmail(email) {
        let person = personQuery.data.find(p => p.email.toLowerCase() === email.toLowerCase());
        return person?.firstName;
    }
    function getPersonImg(personId) {
        let person = personQuery.data?.find(p => p.id === personId);
        let img = person?.imageBase64;
        if (img === null || img === undefined || img === "") {
            return "";
        }
        return `data:;base64,${img}`;
    }
    function getPersonFullName(personId) {
        let person = personQuery.data?.find(p => p.id === personId);
        if (person === undefined) {
            return "";
        }
        return person?.firstName;// + " " + person?.lastName;
    }

    function getPDCAImageByStatus(status) {
        let image = "../../assets/";
        switch (status) {
            case 1:
                return image += "P.png";
            case 2:
                return image += "PD.png";
            case 3:
                return image += "PDC.png";
            case 4:
                return image += "PDCA.png";
            default:
                return "";
        }
    }

    function isNull0(value) {
        return value === null ? 0 : value;
    }
    function getEffortColor(effort, estimated) {
        return isNull0(effort) > isNull0(estimated) ? '#e8032e' : ''
    }
    function getTotalTime(obj) {
        let total = 0;
        obj.reportedTimes?.forEach((a) => {
            total += a.reportedTime;
        });
        return Math.round(total);
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name, boxSize) {
        if (name === undefined || name === null || name === "" || name.split(' ').length < 2) {
            name = "N N";
        }
        return {
            sx: {
                bgcolor: stringToColor(name),
                border: 1,
                height: boxSize,
                width: boxSize,
                borderColor: "#000000"
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    function stringAvatarBorder(name, boxSize) {
        if (name === undefined || name === null || name === "" || name.split(' ').length < 2) {
            name = "N N";
        }
        return {
            sx: {
                bgcolor: stringToColor(name),
                border: 1,
                height: boxSize,
                width: boxSize,
                borderColor: (personId === 0) ? "#000000" : "#aaaaaa"
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    let columns = ([

        {
            field: "taskId",
            headerName: formatMessage(messages.sub),
            flex: 0.5,
            align: "left",
            renderCell: (params) => {
                return (

                    <Box display="flex" justifyContent="center" justifyItems="center" alignContent="center" >
                        {(params.row.taskId === null && getChildCount(params.row.id) > 0) ?
                            <Box onClick={() => { handleShowChildrenClick(params.row.id) }}> <RemoveRedEyeOutlinedIcon color={colors.primary[500]} /> {getChildCount(params.row.id)}</Box> :
                            <Box width="100%" justifyContent="center" display="flex">  </Box>}
                    </Box>
                )
            }
        },
        {
            field: "type",
            headerName: formatMessage(messages.type),
            flex: 0.5,
            align: "left",
            renderCell: (params) => {
                return (
                    <Box display="flex" height="" alignItems="flex-start">
                        <Tooltip title={<Typography fontSize="12px" >{formatTypeText(params.row.type?.id)}</Typography>}>
                            {formatType(params.row.type?.id)}
                        </Tooltip>
                    </Box>
                )
            }
        },
        {
            field: "title",
            headerName: formatMessage(messages.title),
            flex: 2,
        },
        {
            field: "description",
            headerName: formatMessage(messages.description),
            flex: 3,
        },
        {
            field: "department",
            headerName: formatMessage(messages.department),
            flex: 1,
            align: "left",
            valueGetter: (value, row) => `${row.department?.name || ''}`,
        },
        {
            field: "quickfix",
            headerName: formatMessage(messages.quickfix),
            flex: 0.5,
            align: "left",
            valueGetter: (value, row) => `${formatTrueYes(row.quickFix) || ''}`,
        },
        {
            field: "effort",
            headerName: formatMessage(messages.effort),
            flex: 1,
            align: "center",
            valueGetter: (value, row) => `${getTotalTime(row) + " / " + isNull0(row.estimatedEffort) || ''}`,
        },
        {
            field: "targetDate",
            headerName: formatMessage(messages.target),
            flex: 0,
            align: "left",
            valueGetter: (value, row) => `${formatDateLocalized(row.targetDate) || ''}`,
        },
        {
            field: "startDate",
            headerName: formatMessage(messages.started),
            flex: 0,
            align: "left",
            valueGetter: (value, row) => `${formatDateLocalized(row.startDate) || ''}`,
        },
        {
            field: "endDate",
            headerName: formatMessage(messages.ended),
            flex: 0,
            align: "left",
            valueGetter: (value, row) => `${formatDateLocalized(row.endDate) || ''}`,
        },
        {
            field: "progress",
            headerName: formatMessage(messages.progress),
            flex: 0,
            align: "left",
            renderCell: (params) => {
                return (
                    <Box display="flex" height="100%" alignItems="center">
                        <Tooltip title={<Typography fontSize="12px" >{params.row.status.description}</Typography>}>
                            <img src={getPDCAImageByStatus(params.row.status.id)} alt="" width="30px" sx={{ width: 30, height: 30, borderRadius: '50%', border: 1, borderColor: '#000000' }} />
                        </Tooltip>
                    </Box>
                )
            }
        },
        {
            field: "responsible",
            headerName: formatMessage(messages.responsible),
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction="row" display="flex" height="100%" alignItems="center">
                        {/* {params.row.responsible.map((part) =>  */}
                        <Tooltip title={<Typography fontSize="12px">{getPersonFullName(params.row.responsibleId)}</Typography>}>
                            <Avatar alt="value" src={getPersonImg(params.row.responsibleId)} {...stringAvatar(getPersonFullName(params.row.responsibleId), 30)} />
                        </Tooltip>
                        {/* )} */}
                    </Stack>);
            }
        },
        {
            field: "participants",
            headerName: formatMessage(messages.participants),
            flex: 1,
            renderCell: (params, index) => {
                return (
                    <Stack direction="row" spacing={"-3px"} useflexgap flexwrap="wrap" display="flex" height="100%" alignItems="center">
                        <AvatarGroup flexwrap="wrap" useflexgap>
                            {params.row.participants.map((part) =>
                                <Tooltip title={<Typography fontSize="12px">{getPersonFullName(part.id)}</Typography>}>
                                    <Avatar alt="value" src={getPersonImg(part.id)} {...stringAvatar(getPersonFullNameById(part.id), 20)} />
                                </Tooltip>)}
                        </AvatarGroup>
                    </Stack>);
            },
        },
        {
            field: "tags",
            headerName: "Tags",
            flex: 2,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={"3px"} alignItems="center" useflexgap flexwrap="wrap" display="flex" height="100%">
                        {params.row.tags.map((tag) =>
                            <Chip label={tag.title} variant="outlined" color="default" size="small" />)}
                    </Stack>);
            },
        },
        {
            field: "comments",
            headerName: "",
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Box marginTop="10px">
                        <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.comments} /></Typography>}>
                            {params.row.comments.length > 0 ? <ChatOutlinedIcon /> : <></>}
                        </Tooltip>
                    </Box>);
            },
        },
        {
            field: "edit",
            headerName: "",
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Box>
                        <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.edit} /></Typography>}>
                            <IconButton color="#ff00ff" onClick={() => navigate('/improvementFullPage/' + params.row.id)}> <EditOutlinedIcon /> </IconButton>
                        </Tooltip>
                    </Box>);
            },
        },

    ]);

    let spinner = <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        }}
    >
        <CircularProgress color="secondary" />
    </div>

    if (taskQuery.isLoading) {
        //return <Box color="gray">Loading Tasks...</Box>;
        return spinner;
    }
    if (tagsQuery.isLoading) {
        //return <Box color="gray">Loading Tags...</Box>;
        return spinner;
    }
    if (personQuery.isLoading) {
        //return <Box color="gray">Loading Persons...</Box>;
        return spinner;
    }
    if (departmentsQuery.isLoading) {
        //return <Box color="gray">Loading Departments...</Box>;
        return spinner;
    }
    if (dimensionsQuery.isLoading) {
        //return <Box color="gray">Loading Dimensions...</Box>;
        return spinner;
    }

    columns = fixColumns(columns);

    return (
        <>
            <Stack direction="row" paddingRight={2} spacing={"auto"} alignItems="center" width="100%">
                <Stack direction="row" paddingTop={0} spacing={"auto"} alignItems="center" justifyItems="center">
                    <Box width="50px" p={1}>
                        <IconButton onClick={() => navigate("/improvementFullPage/0")}> <AddCircleOutlinedIcon /></IconButton>
                    </Box>


                    <Box p={1} marginLeft={"30px"}>
                        <FormControl sx={{ minWidth: '100px' }}>
                            <InputLabel id="department-label"><FormattedMessage {...messages.department} /></InputLabel>
                            <Select
                                labelId="department-label"
                                id="department-select"
                                value={departmentId}
                                label="Department"
                                onChange={handleDepartmentChange}
                            // MenuProps={MenuProps}
                            >
                                {/* <MenuItem value={1}>Department 1</MenuItem>
                            <MenuItem value={2}>Department 2</MenuItem> */}
                                <MenuItem key={0} value={0}>Alla</MenuItem>
                                {departmentsQuery.data?.sort((a, b) => a.name.localeCompare(b.name)).map((d) => (
                                    <MenuItem
                                        key={d.id}
                                        value={d.id}
                                    //   style={getStyles(name, personName, theme)}
                                    >
                                        {d.name}
                                        {/* <Avatar alt="value" src={"../../assets/" + p.image} /><Box paddingLeft="7px">{p.firstName + " " + p.lastName}</Box> */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box p={1} marginLeft={"30px"}>
                        <FormControl sx={{ minWidth: '100px' }}>
                            <InputLabel id="type-label"><FormattedMessage {...messages.type} /></InputLabel>
                            <Select
                                labelId="type-label"
                                id="type-select"
                                value={typeId}
                                label="Type"
                                onChange={handleTypeChange}
                            // MenuProps={MenuProps}
                            >
                                {/* <MenuItem value={1}>Department 1</MenuItem>
                            <MenuItem value={2}>Department 2</MenuItem> */}
                                <MenuItem key={0} value={0}>Alla</MenuItem>
                                <MenuItem key={1} value={1}><BoltOutlinedIcon fontSize="small" /> Incident</MenuItem>
                                <MenuItem key={2} value={2}><StarRateOutlinedIcon fontSize="small" />Improvement</MenuItem>
                                {/* {typesQuery.data.map((d) => (
                                    <MenuItem
                                        key={d.id}
                                        value={d.id}
                                    >
                                        {d.name}
                                    </MenuItem>
                                ))} */}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box p={1}>
                        <FormControl sx={{ minWidth: '100px' }}>
                            <InputLabel id="tags-label">Tag</InputLabel>
                            <Select
                                labelId="tags-label"
                                id="tags-select"
                                value={tagId}
                                label="olle"
                                onChange={handleTagChange}
                            // MenuProps={MenuProps}
                            >
                                {/* <MenuItem value={1}>Department 1</MenuItem>
                            <MenuItem value={2}>Department 2</MenuItem> */}
                                <MenuItem key={0} value={0}>Alla</MenuItem>
                                {tagsQuery.data?.sort((a, b) => a.title.localeCompare(b.title)).map((d) => (
                                    <MenuItem
                                        key={d.id}
                                        value={d.id}
                                    //   style={getStyles(name, personName, theme)}
                                    >
                                        {d.title}
                                        {/* <Avatar alt="value" src={"../../assets/" + p.image} /><Box paddingLeft="7px">{p.firstName + " " + p.lastName}</Box> */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box p={1}>
                        <FormControl sx={{ minWidth: '100px' }}>
                            <InputLabel id="person-label">Person</InputLabel>
                            <Select
                                labelId="person-label"
                                id="person-select"
                                value={personId}
                                label="Person"
                                onChange={handlePersonChange}
                            // MenuProps={MenuProps}
                            >
                                {/* <MenuItem value={1}>Department 1</MenuItem>
                            <MenuItem value={2}>Department 2</MenuItem> */}
                                <MenuItem key={0} value={0}>Alla</MenuItem>
                                {personQuery.data?.filter(p => p.hide !== true).sort((a, b) => a.firstName.localeCompare(b.firstName)).map((d) => (
                                    <MenuItem
                                        key={d.id}
                                        value={d.id}
                                    //   style={getStyles(name, personName, theme)}
                                    >
                                        {d.firstName}
                                        {/* <Avatar alt="value" src={"../../assets/" + p.image} /><Box paddingLeft="7px">{p.firstName + " " + p.lastName}</Box> */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {dimensionsQuery.data?.map((dim) => (
                        <Box p={1}>
                            <FormControl sx={{ minWidth: '100px' }}>
                                <InputLabel id="tags-label">{dim.name}</InputLabel>
                                <Select
                                    labelId="tags-label"
                                    id="tags-select"
                                    value={dimensionIds?.filter(x => x.dimensionId === dim.id).map(y => { return y.id }) || ''}
                                    label="Dimension"
                                    name={dim.id}
                                    onChange={handleDimensionChange}
                                // MenuProps={MenuProps}
                                >
                                    <MenuItem key={0} value={0}>Alla</MenuItem>
                                    {dim?.values.map(v => {
                                        return (
                                            <MenuItem
                                                key={v.id}
                                                value={v.id}
                                            //   style={getStyles(name, personName, theme)}
                                            >
                                                {v.name}
                                            </MenuItem>
                                        )
                                    })}

                                </Select>
                            </FormControl>
                        </Box>
                    ))}

                    <Box>
                        <Controls.Input
                            name="searchList"
                            label={formatMessage(messages.quick_search)}
                            value={searchValue}
                            onChange={handleSearchChange}
                            //error={errors.name}
                            sx={{ marginLeft: '10px' }}
                            fullWidth={true}
                            autoComplete="new-password"
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={() => clearSearchValue("")}>
                                        {searchValue.length > 0 ? <ClearOutlinedIcon /> : ''}
                                    </IconButton>
                                )
                            }}
                        />
                    </Box>
                    <Box width="60px" p={1}>
                        <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.my_tasks} /></Typography>}>
                            <Button onClick={handleSearchMe} size="sm">
                                <Avatar alt="value" src={getPersonImg(getPersonIdByEmail(account.username))} {...stringAvatarBorder(getPersonFullNameByEmail(account.username), 30)} />
                            </Button>
                        </Tooltip>
                    </Box>
                    <Box width="50px" p={1}>
                        <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.clear_all_filters} /></Typography>}>
                            <IconButton onClick={() => handleResetFiltering()}> <FilterAltOffIcon /></IconButton>
                        </Tooltip>
                    </Box>
                </Stack>
                {/* ... */}
                <Stack direction="row" padding={2} spacing={"auto"} alignItems="center">
                    <Box width="30px" p={1}>
                        <IconButton disabled onClick={() => navigate("/improvementsListPage")}> <ReorderOutlinedIcon /></IconButton>
                    </Box>
                    <Box width="30px" p={1}>
                        <IconButton onClick={() => navigate("/improvementsListLanePage")}> <AppsOutlinedIcon /></IconButton>
                    </Box>
                    <Box width="30px" p={1}>
                        <IconButton disabled onClick={() => navigate("/improvementsListLanePage")}> <SortOutlinedIcon /></IconButton>
                    </Box>
                </Stack>
            </Stack>

            {/* Filter message */}
            {filterMessage &&
                <Box width="98%" color="#ffffff" backgroundColor={colors.dashbordGreen[800]} margin="10px" marginRight="50px" padding="5px" display="flex" justifyContent="center" alignItems="center">
                    <Box width="90%" display="flex" justifyContent="center" alignContent="center">{filterMessage}</Box>
                    <Box width="10%" display="flex" justifyContent="flex-end">
                        <IconButton onClick={() => handleClearSpecialSearch()}> <HighlightOffIcon /></IconButton>
                    </Box>
                </Box>
            }

            <Box padding={2} paddingTop={0} width={"100%"}>
                <Paper
                    sx={{
                        position: "relative",
                        borderRadius: "4px",
                    }}
                >

                    <Box
                        m="0px 0 0 0"
                        height="85vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                            },
                            "& .name.column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-cellContent": {
                                fontSize: "13px",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.primary[600],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.primary[600],
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.gray[100]} !important`,
                            },
                            "& .super-app-theme--11": {
                                bgcolor: `${colors.redAccent[500]} !important`,
                            },
                            "& .super-app-theme--undefined-stop": {
                                bgcolor: `${colors.redAccent[500]} !important`,
                            },
                            "& .MuiDataGrid-columnHeader": {
                                fontSize: "15px",
                            },
                        }}
                    >
                        {(!taskQuery?.isFetching) && <DataGridPro
                            apiRef={apiRef}
                            onColumnResize={handleColumnResize}
                            onColumnOrderChange={handleColumnOrderChange}
                            columnorder
                            rows={filteredData}
                            getRowHeight={() => 'auto'}
                            sortModel={sortModelStorage}
                            onSortModelChange={(newModel) => setSortModelStorage(newModel)}
                            columns={columns}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            getRowClassName={(params) => {
                                return params.row.taskId !== null ? "highlight" : "";
                            }}
                            sx={{
                                ".highlight": {
                                    bgcolor: colors.gray[800],
                                    "&:hover": {
                                        bgcolor: colors.gray[700],
                                    },
                                },
                            }}
                        />}
                    </Box>
                </Paper>
            </Box>
        </>
    );

}
export default ImprovementsList;

