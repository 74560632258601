import React from 'react'
import ImprovementsList from '../features/list/improvementsList'


console.log("ImprovementsPage.jsx");

export default function ImprovementsListPage() {
  return (
    <ImprovementsList/>
  )
}
