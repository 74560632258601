import axios from "axios";

export const getComments = async (id) => {
const response = await axios.get(`api/tasks/${id}/comments`);	
    const result = response.data;
    return result;
}

export const postComment = async (data) => {
    const response = await axios.post("api/tasks/comments",data);
    const result = response.data;
    return result;
}