import React from 'react'
import Improvements from '../components/improvements'


console.log("ImprovementsPage.jsx");

export default function ImprovementsPage() {
  return (
    <Improvements/>
  )
}
