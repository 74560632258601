import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Divider } from "@mui/material";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../auth/SignInButton";
import { SignOutButton } from "../auth/SignOutButton";
import { useMsal, useAccount } from "@azure/msal-react";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../assets/theme";
import ProfilePicture from "../auth/ProfilePicture";
//LANGUAGE
import messages from "./messages";
import { FormattedMessage, useIntl } from "react-intl";
//ICONS
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useLocalStorage } from "../hooks/useLocalStorage";


const Item = ({ title, to, icon, selected, setSelected }) => {
  
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.gray[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isAuthenticated = useIsAuthenticated();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [isSettingsCollapsed, setIsSettingsCollapsed] =   useLocalStorage("ImprovementsSidebarisSettingsCollapsed", true);;


  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#a0cb66 !important",
        },
        "& .pro-menu-item.active": {
          color: "#799c4a !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.gray[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="px"
              >
                <img
                  alt="profile-user"
                  width="160px"
                  height="45px"
                  src={`../../assets/Axion_solutions_white.png`}
                />

                <Typography
                  variant="H3"
                  color={colors.gray[100]}
                  sx={{ m: "0px 0px 0px 0px", flex: 1 }}
                >
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <ProfilePicture />
              </Box>
              <Box textAlign="center">
                <Typography
                  color={colors.gray[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0", fontSize: "1.0rem" }}
                >
                  {account.name}
                </Typography>

              </Box>
            </Box>
          )}
          <Divider />

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Typography
              variant="h6"
              color={colors.gray[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              <FormattedMessage {...messages.pagesLabel} />
            </Typography>
            {/* <Item
              title={<FormattedMessage {...messages.homeMenuItem}/>}
              to="/improvements"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title={<FormattedMessage {...messages.newMenuItem} />}
              to="/improvementFullPage/0"
              icon={<AddCircleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={<FormattedMessage {...messages.listMenuItem} />}
              to="/improvementsListPage"
              icon={<ListOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={<FormattedMessage {...messages.boardMenuItem} />}
              to="/improvementsListLanePage"
              icon={<DashboardCustomizeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={<FormattedMessage {...messages.dashboardMenuItem} />}
              to="/improvementsDashboardPage"
              icon={<DashboardOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={<FormattedMessage {...messages.incidentsMenuItem} />}
              to="/incidentsCalendarPage"
              icon={<CalendarMonthIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.gray[300]}
              sx={{ m: "15px 0 5px 20px", cursor: "pointer" }}
              onClick={() => setIsSettingsCollapsed(!isSettingsCollapsed)}
            >
              <FormattedMessage {...messages.settingsMenuItem} />
              {isSettingsCollapsed &&
                <IconButton arial-label="Expand Settings" size="small">
                  <ExpandMore fontSize="inherit" />
                </IconButton>
              }
              {!isSettingsCollapsed &&
                <IconButton arial-label="Collapse Settings" size="small">
                  <ExpandLess fontSize="inherit" />
                </IconButton>
              }
            </Typography>
            {!isSettingsCollapsed &&
            <Item
              title={<FormattedMessage {...messages.dimensionsMenuItem} />}
              to="/improvementAddDimensionsPage"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />}
            {!isSettingsCollapsed &&
            <Item
              title={<FormattedMessage {...messages.usersMenuItem} />}
              to="/usersPage"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
}
          </Box>
          <Divider />
          <Box paddingLeft={isCollapsed ? undefined : "10%"} >
            <Typography variant="h5" >
              {isAuthenticated ? <SignOutButton /> : <SignInButton />}
            </Typography>
          </Box>
          <Divider />
          <Box marginTop="100px" width="100%" display="flex" justifyContent="center" color="#aaaaaa">Version: 1.2.2</Box>
        </Menu>
      </ProSidebar>
      
    </Box>
  );
};

export default Sidebar;
