import React from 'react'
import Dashboard from '../features/home'
import Improvements from '../components/improvements'


console.log("Home.jsx");

export default function Home() {
  return (
    <Improvements/>
  )
}
