import { defineMessages } from 'react-intl';

export const scope = 'axionimprovements.features.users';

export default defineMessages({

    could_not_hide_user: {
        id: `${scope}.labels.could_not_hide_user`,
        defaultMessage: 'Could not hide user',
    },
    users_are_synchronized: {
        id: `${scope}.labels.users_are_synchronized`,
        defaultMessage: 'Users are synchronized',
    },
    hide: {
        id: `${scope}.labels.hide`,
        defaultMessage: 'Hide',
    },
    image: {
        id: `${scope}.labels.image`,
        defaultMessage: 'Image',
    },
    display_name: {
        id: `${scope}.labels.display_name`,
        defaultMessage: 'Display name',
    },
    email: {
        id: `${scope}.labels.email`,
        defaultMessage: 'Email',
    },
    info: {
        id: `${scope}.labels.info`,
        defaultMessage: 'Info',
    },
    hidden_users: {
        id: `${scope}.labels.hidden_users`,
        defaultMessage: 'Hidden users',
    },
    sync_users_from_aad: {
        id: `${scope}.labels.sync_users_from_aad`,
        defaultMessage: 'Sync users from AAD',
    },
    sync_users: {
        id: `${scope}.labels.sync_users`,
        defaultMessage: 'Syncronize users',
    },
    sure_sync_users: {
        id: `${scope}.labels.sure_sync_users`,
        defaultMessage: 'Are you sure you want to syncronize all users from AAD? (You will be notified when the syncronization is done)',
    },
    no: {
        id: `${scope}.labels.no`,
        defaultMessage: 'No',
    },
    yes: {
        id: `${scope}.labels.yes`,
        defaultMessage: 'Yes',
    },
    syncing_users: {
        id: `${scope}.labels.syncing_users`,
        defaultMessage: 'Syncing users',
    },


});