import axios from "axios";

export const getUsers = async () => {
    const response = await axios.get("api/users/");
    const result = response.data;
    return result;
}

export const getUserImage = async (userId) => {
    const response = axios.get(`api/users/${userId}/image`)
    const result = response.data;
    return result;
}

export const getUserImageFile = async (id) => {
    const response = axios.get(`api/users/${id}/imagefile`, {
        responseType: "arraybuffer"
      })
      .then((res) => {
      const base64 = btoa(
        new Uint8Array(res.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      )
      return base64;
    })

    return response;
}

export const syncUsers = async () => {
    const response = await axios.get(`api/users/syncusers?force=true&syncimages=true`);
    const result = response.data;
    return result;
}

export const hideUser = async (id) => {
    console.log("hideUser", id);
    const response = await axios.patch(`api/persons/${id}/togglehide`);
    const result = response.data;
    return result;
}


