import { defineMessages } from 'react-intl';

export const scope = 'axionimprovements.features.incidents';

export default defineMessages({
    incident: {
        id: `${scope}.table.labels.incident`,
        defaultMessage: 'Incident',
    },
    category: {
        id: `${scope}.table.labels.category`,
        defaultMessage: 'Category',
    },
    date: {
        id: `${scope}.table.labels.date`,
        defaultMessage: 'Date',
    },
    department: {
        id: `${scope}.table.labels.department`,
        defaultMessage: 'Department',
    },
    responsible: {
        id: `${scope}.table.labels.responsible`,
        defaultMessage: 'Responsible',
    },
    status: {
        id: `${scope}.table.labels.status`,
        defaultMessage: 'Status',
    },
    open: {
        id: `${scope}.table.labels.open`,
        defaultMessage: 'Open',
    },
    closed: {
        id: `${scope}.table.labels.closed`,
        defaultMessage: 'Closed',
    },
    title: {
        id: `${scope}.table.labels.title`,
        defaultMessage: 'Title',
    },
    description: {
        id: `${scope}.table.labels.description`,
        defaultMessage: 'Description',
    },
    incident_date: {
        id: `${scope}.table.labels.incident_date`,
        defaultMessage: 'Incident date',
    },
    target_date: {
        id: `${scope}.table.labels.target_date`,
        defaultMessage: 'Target date',
    },
    create_incident: {
        id: `${scope}.table.labels.create_incident`,
        defaultMessage: 'Create incident',
    },


    close_message: {
        id: `${scope}.table.labels.close_message`,
        defaultMessage: 'Click to close the incident',
    },
    open_message: {
        id: `${scope}.table.labels.open_message`,
        defaultMessage: 'Click to open the incident',
    },
    edit_message: {
        id: `${scope}.table.labels.edit_message`,
        defaultMessage: 'Click to edit the incident',
    },
    show_linked_improvement: {
        id: `${scope}.table.labels.show_linked_improvement`,
        defaultMessage: 'Click to show the linked improvement',
    },
    create_linked_improvement: {
        id: `${scope}.table.labels.create_linked_improvement`,
        defaultMessage: 'Click to create a linked improvement',
    },
    future_message: {
        id: `${scope}.table.labels.future_message`,
        defaultMessage: "An incident can't be created in the future",
    },
    error_close_message: {
        id: `${scope}.table.labels.error_close_message`,
        defaultMessage: "Could not close the incident",
    },
    error_create_message: {
        id: `${scope}.table.labels.error_create_message`,
        defaultMessage: "Could not create the incident",
    },
    verify_title: {
        id: `${scope}.verify.failure.title`,
        defaultMessage: "Title is required",
    },
    verify_description: {
        id: `${scope}.verify.failure.description`,
        defaultMessage: "Description is required",
    },
    verify_category: {
        id: `${scope}.verify.failure.category`,
        defaultMessage: "Category is required",
    },
    verify_department: {
        id: `${scope}.verify.failure.department`,
        defaultMessage: "Department is required",
    },
    verify_type: {
        id: `${scope}.verify.failure.type`,
        defaultMessage: "Type is required",
    },
    verify_responsible: {
        id: `${scope}.verify.failure.responsible`,
        defaultMessage: "Responsible is required",
    },
//Months
    jan: {
        id: `${scope}.month.labels.jan`,
        defaultMessage: 'January',
    },
    feb: {
        id: `${scope}.month.labels.feb`,
        defaultMessage: 'February',
    },
    mar: {
        id: `${scope}.month.labels.mar`,
        defaultMessage: 'March',
    },
    apr: {
        id: `${scope}.month.labels.apr`,
        defaultMessage: 'April',
    },
    may: {
        id: `${scope}.month.labels.may`,
        defaultMessage: 'May',
    },
    jun: {
        id: `${scope}.month.labels.jun`,
        defaultMessage: 'June',
    },
    jul: {
        id: `${scope}.month.labels.jul`,
        defaultMessage: 'July',
    },
    aug: {
        id: `${scope}.month.labels.aug`,
        defaultMessage: 'August',
    },
    sep: {
        id: `${scope}.month.labels.sep`,
        defaultMessage: 'September',
    },
    oct: {
        id: `${scope}.month.labels.oct`,
        defaultMessage: 'October',
    },
    nov: {
        id: `${scope}.month.labels.nov`,
        defaultMessage: 'November',
    },
    dec: {
        id: `${scope}.month.labels.dec`,
        defaultMessage: 'December',
    },
//Weekdays
    mon: {
        id: `${scope}.day.labels.mon`,
        defaultMessage: 'Mon',
    },
    tue: {
        id: `${scope}.day.labels.tue`,
        defaultMessage: 'Tue',
    },
    wed: {
        id: `${scope}.day.labels.wed`,
        defaultMessage: 'Wed',
    },
    thu: {
        id: `${scope}.day.labels.thu`,
        defaultMessage: 'Thu',
    },
    fri: {
        id: `${scope}.day.labels.fri`,
        defaultMessage: 'Fri',
    },
    sat: {
        id: `${scope}.day.labels.sat`,
        defaultMessage: 'Sat',
    },
    sun: {
        id: `${scope}.day.labels.sun`,
        defaultMessage: 'Sun',
    },





});