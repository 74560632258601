import { defineMessages } from 'react-intl';

export const scope = 'axionimprovements.features.dimensions';

export default defineMessages({

    no: {
        id: `${scope}.labels.no`,
        defaultMessage: 'No',
    },
    yes: {
        id: `${scope}.labels.yes`,
        defaultMessage: 'Yes',
    },
    maximum: {
        id: `${scope}.labels.maximum`,
        defaultMessage: 'A maximum of four dimensions can be added',
    },
    add_dimension: {
        id: `${scope}.labels.add_dimension`,
        defaultMessage: 'Add dimension.',
    },
    update_dimension: {
        id: `${scope}.labels.add_dimension`,
        defaultMessage: 'Update dimension.',
    },
    delete_dimension: {
        id: `${scope}.labels.delete_dimension`,
        defaultMessage: 'Delete dimension.',
    },
    cannot_remove_dim: {
        id: `${scope}.labels.cannot_remove_dim`,
        defaultMessage: 'The dimension can not be removed as long as it has connected values',
    },
    remove_dim: {
        id: `${scope}.labels.remove_dim`,
        defaultMessage: 'Remove the dimension',
    },
    add_a_new_dim: {
        id: `${scope}.labels.add_a_new_dim`,
        defaultMessage: 'Add a new Dimension',
    },
    update_a_dim: {
        id: `${scope}.labels.update_a_dim`,
        defaultMessage: 'Update a dimension',
    },
    add_a_new_dim_value: {
        id: `${scope}.labels.add_a_new_dim_value`,
        defaultMessage: 'Add a new Dimension value',
    },
    update_a_dim_value: {
        id: `${scope}.labels.update_a_dim_value`,
        defaultMessage: 'Update a dimension value',
    },
    add_value: {
        id: `${scope}.labels.add_value`,
        defaultMessage: 'Add value',
    },
    update_value: {
        id: `${scope}.labels.update_value`,
        defaultMessage: 'Update value',
    },
    sure_delete_dimension: {
        id: `${scope}.labels.sure_delete_dimension`,
        defaultMessage: 'Are you sure you want to delete this dimension?',
    },
    could_not_delete_dim: {
        id: `${scope}.labels.could_not_delete_dim`,
        defaultMessage: 'Could not delete the Dimension',
    },
    could_not_update_dim: {
        id: `${scope}.labels.could_not_update_dim`,
        defaultMessage: 'Could not update the Dimension',
    },
    could_not_create_dim: {
        id: `${scope}.labels.could_not_create_dim`,
        defaultMessage: 'Could not create the Dimension',
    },
    delete_value: {
        id: `${scope}.labels.delete_value`,
        defaultMessage: 'Delete Dimension value',
    },
    sure_delete_value: {
        id: `${scope}.labels.sure_delete_value`,
        defaultMessage: 'Are you sure you want to delete this value?',
    },
    could_not_delete_value: {
        id: `${scope}.labels.could_not_delete_value`,
        defaultMessage: 'Could not delete the value',
    },
    could_not_update_value: {
        id: `${scope}.labels.could_not_update_value`,
        defaultMessage: 'Could not update the value',
    },
    could_not_create_value: {
        id: `${scope}.labels.could_not_create_value`,
        defaultMessage: 'Could not create the value',
    },

    add_new_value: {
        id: `${scope}.labels.add_new_value`,
        defaultMessage: 'Add new value for dimension',
    },
    edit_dimension: {
        id: `${scope}.labels.edit_dimension`,
        defaultMessage: 'Edit the dimension',
    },
    edit_value: {
        id: `${scope}.labels.edit_value`,
        defaultMessage: 'Edit the value',
    },
    remove_value: {
        id: `${scope}.labels.remove_value`,
        defaultMessage: 'Remove the value',
    },
    close: {
        id: `${scope}.labels.close`,
        defaultMessage: 'Close',
    },
    name: {
        id: `${scope}.labels.name`,
        defaultMessage: 'Name',
    },
    code: {
        id: `${scope}.labels.code`,
        defaultMessage: 'Code',
    },
    value: {
        id: `${scope}.labels.value`,
        defaultMessage: 'Value',
    },
    name_must_contain_value: {
        id: `${scope}.labels.name_must_contain_value`,
        defaultMessage: 'Name is a required field and must contain a value',
    },
    code_must_contain_value: {
        id: `${scope}.labels.code_must_contain_value`,
        defaultMessage: 'Code is a required field and must contain a value',
    },
 


});