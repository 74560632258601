import axios from "axios";

export const getTask = async (taskId, type) => {
    console.log("getTask with accepted id", taskId, type); 
    const response = await axios.get("api/tasks/"+ taskId);
    const task = response.data;
    console.log("getTask returning",type, taskId, task);
    return task;
}

export const getSubtasks = async (taskId) => {
    const response = await axios.get(`api/tasks/${taskId}/subtasks`);
    const task = response.data;
    return task;
}

export const getAllTasks = async (companyKey) => {
    console.log("getAllTasks");
    const response = await axios.get("api/tasks?companyId=" + companyKey);
    const tasks = response.data;
    return tasks;
}

export const postTask = async (data) => {
    const response = await axios.post("api/tasks",data);
    const result = response.data;
    return result;
}

export const patchTask = async (data) => {
    const response = await axios.patch("api/tasks",data);
    const tasks = response.data;
    return tasks;
}

export const putTask = async (data) => {
    const response = await axios.put("api/tasks",data);
    const tasks = response.data;
    return tasks;
}

export const deleteTask = async (taskId) => {
    const response = await axios.delete(`api/tasks/${taskId}`);
    const result = response.data;
    return result;
}

export const copyTask = async (taskId) => {
    const response = await axios.get(`api/tasks/copy/${taskId}`);
    const result = response.data;
    return result;
}